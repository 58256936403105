const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function taxesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TAXES_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_TAXES_SUCCESS":
      return { error: false, loading: false, data: action.taxes };
    case "ADD_TAXES_ERROR":
      return { error: true, loading: false, data: state.data };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
