const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function ordersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_ORDERS_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_ORDERS_SUCCESS":
      return { error: false, loading: false, data: action.orders };
    case "ADD_ORDERS": {
      const newState = { ...state, error: false, loading: false };
      action.data.forEach((order) => {
        const { uid, ...data } = order;
        newState.data[uid] = data;
      });
      return newState;
    }
    case "DELETE_ORDERS": {
      const newState = { ...state, error: false, loading: false };
      action.data.forEach((order) => {
        const { uid } = order;
        delete newState.data[uid];
      });
      return newState;
    }
    case "UPDATE_ORDERS": {
      const newState = { ...state, error: false, loading: false };
      action.data.forEach((order) => {
        const { uid, ...data } = order;
        newState.data[uid] = { ...newState.data[uid], ...data };
      });
      return newState;
    }
    case "ADD_ORDERS_ERROR":
      return { error: true, loading: false, data: state.data };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
