const INITIAL_STATE = {
  operands: {
    error: false,
    loading: false,
    data: [],
  },
  filters: {
    error: false,
    loading: false,
    data: [],
  },
};

export function marketingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_OPERANDS_LOADING": {
      return {
        ...state,
        operands: {
          error: false,
          loading: true,
          data: {},
        },
      };
    }
    case "ADD_OPERANDS_SUCCESS": {
      return {
        ...state,
        operands: {
          error: false,
          loading: true,
          data: action.operands,
        },
      };
    }
    case "ADD_OPERANDS_ERROR": {
      return {
        ...state,
        operands: {
          error: true,
          loading: false,
          data: {},
        },
      };
    }
    case "ADD_FILTERS_LOADING": {
      return {
        ...state,
        filters: {
          error: false,
          loading: true,
          data: {},
        },
      };
    }
    case "ADD_FILTERS_SUCCESS": {
      return {
        ...state,
        filters: {
          error: false,
          loading: true,
          data: action.filters,
        },
      };
    }
    case "ADD_FILTERS_ERROR": {
      return {
        ...state,
        filters: {
          error: true,
          loading: false,
          data: {},
        },
      };
    }
    default:
      return state;
  }
}
