const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function reservationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_RESERVATIONS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "SET_RESERVATIONS": {
      let newData = {};
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "ADD_RESERVATIONS": {
      const newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "DELETE_RESERVATIONS": {
      let newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid } = item;
        delete newData[uid];
      });
      return { data: newData, error: false, loading: false };
    }
    case "UPDATE_RESERVATIONS": {
      let newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "ADD_RESERVATION_LOADING": {
      const newData = { ...state.data };
      newData[action.uid] = { loading: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_RESERVATION_ERROR": {
      const newData = { ...state.data };
      newData[action.uid] = { error: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_RESERVATION_SUCCESS": {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { error: false, loading: false, data: newData };
    }
    case "ADD_RESERVATIONS_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    case "CLEAR_RESERVATIONS":
      return INITIAL_STATE;
    default:
      return state;
  }
}
