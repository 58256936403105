
const INITIAL_STATE = {
  params: {}
};

export function routerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_ROUTER_PARAMS": return { params: { ...action.params } };
    case "CLEAR_PARAMS": return INITIAL_STATE;
    default: return state;
  }
}
