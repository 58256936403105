import { REVIEW_SOURCE } from "util/constants";

const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function feedbackReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_FEEDBACK_SUCCESS": {
      const newState = Object.create(null);
      Object.entries(action.feedback).forEach((entry) => {
        const [uid, review] = entry;
        const { sent, overallRating, ...others } = entry[1];
        const item = {
          ...others,
          uid: entry[0],
          sent: sent?.toDate(),
          overallRating: isNaN(Number(overallRating))
            ? 0
            : Number(overallRating),
          source: review.source ?? REVIEW_SOURCE.RESTO_GURU,
        };
        newState[uid] = item;
      });
      return { error: false, loading: false, data: newState };
    }
    case "ADD_FEEDBACK_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_FEEDBACK_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
