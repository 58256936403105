const INITIAL_STATE = null;

export function newItemReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_NEW_ITEM": return action.item;
    case "CLEAR_NEW_ITEM": return INITIAL_STATE;
    case "SIGN_OUT_USER": return INITIAL_STATE;
    default:
      return state;
  }
}
