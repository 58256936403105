const INITIAL_STATE = { error: false, loading: false, data: [] };

export function campaignsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_CAMPAIGNS_LOADING": return { error: false, loading: true, data: [] };
    case "ADD_CAMPAIGNS_SUCCESS": return { error: false, loading: false, data: action.campaigns };
    case "ADD_CAMPAIGNS_ERROR": return { error: true, loading: false, data: [] };
    default:
      return state;
  }
}

export function getCampaign(state, uid) {
  return state.campaigns.data.filter(item => item.uid === uid)[0];
}