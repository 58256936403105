import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import range from "lodash/range";

import Toolbar from "./Toolbar";
import ListHeader from "./ListHeader";
import Row from "./Row";

const ListRoot = styled.div((props) => ({
  display: "grid",
  gridAutoRows: "min-content",
  ...(props.theme.bp.lessThan.md && { padding: 5 }),
}));
function List() {
  return (
    <ListRoot>
      {range(0, 8).map((idx) => (
        <Row key={idx} />
      ))}
    </ListRoot>
  );
}

const Root = styled.div({
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
  padding: "10px 7px 10px 10px",
});
export default function Bookings(props) {
  return (
    <Root {...props}>
      <Toolbar />
      <ListHeader />
      <List />
    </Root>
  );
}
