const INITIAL_STATE = { error: false, loading: false, data: {} };

export function restaurantsStatsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_RESTAURANTS_STATS_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_RESTAURANTS_STATS_SUCCESS":
      return { error: false, loading: false, data: action.restaurantsStats };
    case "ADD_RESTAURANTS_STATS_ERROR":
      return { error: true, loading: false, data: {} };
    default:
      return state;
  }
}

export function getRestaurantsStats(state) {
  return state.restaurantsStats.data;
}
