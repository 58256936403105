import buildReducer from "./buildReducer";

export const notificationTemplatesReducer = buildReducer(
  "NOTIFICATION_TEMPLATE"
);

export function selectNotificationTemplatesArr(state) {
  return flattenMap(state.notificationTemplates.data);
}

export function selectNotificationTemplates(state) {
  return state.notificationTemplates;
}

export function selectNofiticationTemplateByUid(state, uid) {
  return state.notificationTemplates.data[uid];
}
