import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx, css } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div((props) => ({
  height: 76,
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  backgroundColor: props.theme.palette.background[2],
  borderBottom: `1px solid black`,
}));
const Container = styled.div((props) => ({
  width: 43,
  padding: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: props.theme.palette.background[6],
}));
const RightContainer = styled.div((props) => ({
  height: "100%",
  width: 40,
  display: "grid",
  gridTemplateRows: "1fr auto",
  backgroundColor: props.theme.palette.background[6],
}));
const TopContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: props.theme.palette.background[12],
}));
const BottomContainer = styled.div((props) => ({
  height: 32,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px 8px",
  overflow: "hidden",
}));

export default function Row() {
  const w1 = Math.random() * 100 + 75;
  const w2 = Math.random() * 50 + 40;

  return (
    <Root>
      <Container>
        <Skeleton style={{ width: "100%", height: 18 }} animation="wave" />
        <Skeleton style={{ width: "100%", height: 18 }} animation="wave" />
      </Container>

      <div style={{ padding: "5px 9px" }}>
        <Skeleton
          style={{ width: w1, height: 20, paddingBottom: 3 }}
          animation="wave"
        />
        <Skeleton style={{ width: w2, height: 20 }} animation="wave" />
      </div>
      <RightContainer>
        <TopContainer>
          <Skeleton style={{ width: 10, height: 31 }} animation="wave" />
        </TopContainer>
        <BottomContainer>
          <Skeleton style={{ width: 22 }} animation="wave" />
        </BottomContainer>
      </RightContainer>
    </Root>
  );
}
