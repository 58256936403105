import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

import BookingsCondensed from "./bookings-condensed";
import TablesLoader from "./tables";

const Root = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr 2fr",
}));
const StyledBookings = styled(BookingsCondensed)({
  padding: "10px 7px 10px 10px",
});

export default function Home() {
  return (
    <Root>
      <StyledBookings />
      <TablesLoader />
    </Root>
  );
}
