import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div((props) => ({
  height: 28,
  backgroundColor: props.theme.palette.background[12],
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 16px",
  borderRadius: props.theme.shape.borderRadius,
  marginBottom: 4,
}));
export default function ListHeader() {
  return (
    <Root>
      <Skeleton style={{ width: 50, height: 18 }} animation="wave" />
      <Skeleton style={{ width: 40, height: 18 }} animation="wave" />
    </Root>
  );
}
