const INITIAL_STATE = { error: false, loading: false, data: [] };

export default function reviewHqReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_REVIEW_HQ_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_REVIEW_HQ_SUCCESS":
      return { error: false, loading: false, data: action.data };
    case "ADD_REVIEW_HQ_ERROR":
      return { error: true, loading: false, data: [] };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
