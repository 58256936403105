import isEqual from "lodash/isEqual";

const INITIAL_STATE = { error: false, loading: false, data: {} };

// You need to call this with the non-plural base name i.e buildReducer("CHECK") NOT buildReducer("CHECKS")
function actions(reducerName, initialState = INITIAL_STATE) {
  const name = reducerName.toUpperCase();
  const plural = `${reducerName}S`;
  return {
    [`ADD_${plural}_LOADING`]: (state, action) => {
      return { data: state.data, error: false, loading: true };
    },
    [`ADD_${plural}_ERROR`]: (state, action) => {
      return { data: state.data, error: true, loading: false };
    },
    [`ADD_${plural}_SUCCESS`]: (state, action) => {
      const newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    },
    // Same as above
    [`ADD_${plural}`]: (state, action) => {
      const newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    },
    [`SET_${plural}`]: (state, action) => {
      let newData = {};
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    },
    [`DELETE_${plural}`]: (state, action) => {
      let newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid } = item;
        delete newData[uid];
      });
      return { data: newData, error: false, loading: false };
    },
    [`UPDATE_${plural}`]: (state, action) => {
      let newData = { ...state.data };
      const hasUpdates = action.data.some((item) => {
        const { uid, ...data } = item;
        const orig = state.data[uid];
        const notEqual = !isEqual(data, orig);
        return notEqual;
      });
      if (!hasUpdates) return state;
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    },
    [`CLEAR_${plural}`]: () => initialState,
    [`ADD_${name}_LOADING`]: (state, action) => {
      const newData = { ...state.data };
      newData[action.uid] = { ...newData[action.uid], loading: true };
      return { data: newData, error: false, loading: false };
    },
    [`ADD_${name}_ERROR`]: (state, action) => {
      const newData = { ...state.data };
      newData[action.uid] = { ...newData[action.uid], error: true };
      return { data: newData, error: false, loading: false };
    },
    [`ADD_${name}_SUCCESS`]: (state, action) => {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { error: false, loading: false, data: newData };
    },
    [`DELETE_${name}`]: (state, action) => {
      let newData = { ...state.data };
      delete newData[action.payload];
      return { data: newData, error: false, loading: false };
    },
    SIGN_OUT: () => initialState,
    SIGN_OUT_USER: () => initialState,
    // [`UPDATE_${name}`]: {},
    // [`DELETE_${name}`]: () => {},
  };
}

// Pass in the singular form of the reducer name, i.e. reservation (not reservations)
const buildReducer = (reducerName, initialState = INITIAL_STATE) => (
  state,
  action
) => {
  const reducerActions = actions(reducerName, initialState);
  if (action.type in reducerActions) {
    return reducerActions[action.type](state, action);
  }
  return state ?? initialState;
};

export default buildReducer;

export function chainReducers(reducersArr) {
  return (state, action) => {
    return reducersArr.reduce((prev, current) => current(prev, action), state);
  };
}
