const INITIAL_STATE = { error: false, loading: false, data: {} };

export function businessPermissionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_BUSINESS_PERMISSIONS_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_BUSINESS_PERMISSIONS_SUCCESS":
      return { error: false, loading: false, data: action.businessPermissions };
    case "ADD_BUSINESS_PERMISSIONS_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function getbusinessPermissionArr(state) {
  return state.businessPermission.data;
}
