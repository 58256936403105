export const VIEW_BY = {
  BOOKINGS: "bookings",
  PARTY_SIZE: "partySize",
  STATUS: "status",
  DATE_TIME: "dateTime",
  SEATED_TIME: "seatedTime",
  CREATED_AT: "createdAt",
  PROCESSED_TIME: "processedTime",
  TABLE: "table",
  TIME: "time",
  toString(status) {
    switch (status) {
      case this.PARTY_SIZE:
        return "Party Size";
      case this.STATUS:
        return "Status";
      case this.TIME:
        return "Time";
      case this.DATE_TIME:
        return "Scheduled Time";
      case this.SEATED_TIME:
        return "Seated Time";
      case this.CREATED_AT:
        return "Added Time";
      case this.PROCESSED_TIME:
        return "Completed Time";
      case this.TABLE:
        return "Table Layout";
      case this.BOOKINGS:
        return "Bookings";
      default:
        return "";
    }
  },
  toValue(status) {
    switch (status) {
      case "Party Size":
        return this.PARTY_SIZE;
      case "Status":
        return this.STATUS;
      case "Scheduled Time":
        return this.DATE_TIME;
      case "Time":
        return this.TIME;
      case "Seated Time":
        return this.SEATED_TIME;
      case "Added Time":
        return this.CREATED_AT;
      case "Completed Time":
        return this.PROCESSED_TIME;
      case "Table Layout":
        return this.TABLE;
      case "Bookings":
        return this.BOOKINGS;
      default:
        return "";
    }
  },
  list: ["Bookings", "Party Size", "Status", "Time", "Table Layout"],
};

export const SORT_BY = {
  NAME: "name",
  PARTY_SIZE: "partySize",
  STATUS: "status",
  DATE_TIME: "dateTime",
  toString(status) {
    switch (status) {
      case this.NAME:
        return "Name";
      case this.PARTY_SIZE:
        return "Party Size";
      case this.STATUS:
        return "Status";
      case this.DATE_TIME:
        return "Time";
      default:
        return "";
    }
  },
  toValue(status) {
    switch (status) {
      case "Name":
        return this.NAME;
      case "Party Size":
        return this.PARTY_SIZE;
      case "Status":
        return this.STATUS;
      case "Time":
        return this.DATE_TIME;
      default:
        return "";
    }
  },
  list: ["Name", "Party Size", "Status", "Time"],
};
