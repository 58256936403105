const INITIAL_STATE = "";

export default function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "UPDATE_SEARCH":
      return action.value;
    case "CLEAR_SEARCH":
      return INITIAL_STATE;
    default:
      return state;
  }
}
