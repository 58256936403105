import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

const INITIAL_STATE = { error: false, loading: false, data: {} };

const isEqualUserData = (data, user) => {
  const newData = { ...data };
  //on update authed user we observing  `user/business/${businessUid}/${userUid}` 
  //which doesn't include businessUids
  //in order to have a proper comparisson we need to remove businessUids prop
  delete newData.businessUids;
  return isEqual(newData, user);
};
export function authedUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_AUTHED_USER_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_AUTHED_USER_SUCCESS":
      return { error: false, loading: false, data: action.user };
    case "UPDATE_AUTHED_USER_SUCCESS": {
      const newState = {
        error: false,
        loading: false,
        data: cloneDeep(state.data),
      };
      if (!isEqualUserData(newState.data, action.user)) {
        newState.data = { ...newState.data, ...action.user };
      }
      return newState;
    }
    case "ADD_AUTHED_USER_ERROR":
      return { error: true, loading: false, data: {} };
    case "LOG_OUT":
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function getAuthedUser(state) {
  return state.authedUser.data;
}
