const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function tabsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TABS_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_TABS_SUCCESS":
      return { error: false, loading: false, data: action.tabs };
    case "ADD_TABS_ERROR":
      return { error: true, loading: false, data: state.data };
    default:
      return state;
  }
}
