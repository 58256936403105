const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function coursesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_COURSES_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_COURSES_SUCCESS":
      return { error: false, loading: false, data: action.courses };
    case "ADD_COURSES_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
