const INITIAL_STATE = { error: false, loading: false, smsLog: [], callLog: [] };

export function billingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case `ADD_SMS_LOG_LOADING`:
    case `ADD_CALL_LOG_LOADING`:
      return { ...state, error: false, loading: true, smsLog: [], callLog: [] };
    case `ADD_SMS_LOG_SUCCESS`:
      return {
        ...state,
        error: false,
        loading: false,
        smsLog: action.payload,
      };
    case `ADD_CALL_LOG_SUCCESS`:
      return {
        ...state,
        error: false,
        loading: false,
        callLog: action.payload,
      };

    case `ADD_SMS_LOG_ERROR`:
    case `ADD_CALL_LOG_ERROR`:
      return { ...state, error: true, loading: false, smsLog: [], callLog: [] };
    default:
      return state;
  }
}

export const getCallLog = (state) => state.billings.callLog;
export const getSmsLog = (state) => state.billings.smsLog;
