const INITIAL_STATE = { error: false, loading: false, data: {} };

export function rafflesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_RAFFLES_LOADING": return { error: false, loading: true, data: {} };
    case "ADD_RAFFLES_SUCCESS": return { error: false, loading: false, data: action.raffles };
    case "ADD_RAFFLES_ERROR": return { error: true, loading: false, data: {} };
    default:
      return state;
  }
}
