const INITIAL_STATE = { error: false, loading: false, data: {} };

export function invoicesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_INVOICES_LOADING":
      return { data: state.data, error: false, loading: true };
    case "ADD_INVOICE_LOADING": {
      const newData = { ...state.data };
      newData[action.uid] = { loading: true };
      return { data: newData, loading: true, error: false };
    }
    case "ADD_INVOICE": {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { data: newData, loading: false, error: false };
    }
    case "SET_INVOICES": {
      let newData = { ...action.data };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_INVOICES_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function getInvoices(state) {
  return state.invoices;
}

export function getInvoicesArr(state) {
  // The invoice has a uid, which is the business uid. We want to override this with the doc id
  return Object.entries(state.invoices.data).map((entry) => ({
    ...entry[1],
    uid: entry[0],
  }));
}

// The invoice doc has a uid set to the business uid for some reason
export function getInvoice(state, uid) {
  return { ...state.invoices.data[uid], uid };
}
