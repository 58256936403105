import isEqual from "lodash/isEqual";

const INITIAL_STATE = { error: false, loading: false, data: null };

export default function productsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_PRODUCTS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "UPDATE_PRODUCTS": {
      const newState = {
        error: false,
        loading: false,
        data: { ...state.data },
      };
      if (!isEqual(newState.data[action.product.uid], action.product)) {
        newState.data[action.product.uid] = action.product;
      }
      return newState;
    }
    case "CLEAR_PRODUCTS":
      return INITIAL_STATE;
    case "ADD_PRODUCTS_ERROR":
      return { error: true, loading: false, data: null };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
