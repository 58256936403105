import React from "react";
import Loadable from "@loadable/component";

import Loader from "pages/LoaderPage";

const NewLandingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "NewLandingPage" */ "pages/new-landing/index.jsx"
    ),
  { fallback: <Loader /> }
);
const WebDelivery = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebDelivery" */ "pages/new-landing/solution-lp/WebDeliveryLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const TableManagement = Loadable(
  () =>
    import(
      /* webpackChunkName: "TableManagement" */ "pages/new-landing/solution-lp/TableManagementLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const AnalyticsLanding = Loadable(
  () =>
    import(
      /* webpackChunkName: "AnalyticsLanding" */ "pages/new-landing/solution-lp/AnalyticsLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const CRMLanding = Loadable(
  () =>
    import(
      /* webpackChunkName: "CRMLanding" */ "pages/new-landing/solution-lp/CRMLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const QueueManagementLanding = Loadable(
  () =>
    import(
      /* webpackChunkName: "QueueManagementLanding" */ "pages/new-landing/solution-lp/QueueManagementLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const ReservationsLanding = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationsLanding" */ "pages/new-landing/solution-lp/ReservationsLanding/index.jsx"
    ),
  { fallback: <Loader /> }
);
const FourOhFourPage = Loadable(
  () => import(/* webpackChunkName: "404" */ "pages/FourOhFourPage"),
  { fallback: <Loader /> }
);
const PrivacyPage = Loadable(
  () => import(/* webpackChunkName: "PrivacyPage" */ "pages/PrivacyPage"),
  { fallback: <Loader /> }
);
const TermsOfServicePage = Loadable(
  () =>
    import(
      /* webpackChunkName: "TermsOfServicePage" */ "pages/TermsOfServicePage"
    ),
  { fallback: <Loader /> }
);
const ContactUsPage = Loadable(
  () => import(/* webpackChunkName: "ContactUsPage" */ "pages/ContactUsPage"),
  { fallback: <Loader /> }
);
const LearnMorePage = Loadable(
  () =>
    import(
      /* webpackChunkName: "LearnMorePage" */ "pages/learn-more/index.jsx"
    ),
  { fallback: <Loader /> }
);
const AboutUsPage = Loadable(
  () => import(/* webpackChunkName: "AboutUsPage" */ "pages/AboutUsPage"),
  { fallback: <Loader /> }
);
const SubscriptionsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "SubscriptionsPage" */ "pages/subscriptions/index.jsx"
    ),
  { fallback: <Loader /> }
);

export {
  AboutUsPage,
  AnalyticsLanding,
  ContactUsPage,
  CRMLanding,
  FourOhFourPage,
  LearnMorePage,
  NewLandingPage,
  PrivacyPage,
  QueueManagementLanding,
  ReservationsLanding,
  SubscriptionsPage,
  TableManagement,
  TermsOfServicePage,
  WebDelivery,
};
