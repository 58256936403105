const INITIAL_STATE = { error: false, loading: false, businesses: [] };

export default function businessesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_BUSINESSES_LOADING":
      return { error: false, loading: true, businesses: [] };
    case "ADD_BUSINESSES_SUCCESS":
      return { error: false, loading: false, businesses: action.businesses };
    case "ADD_BUSINESSES_ERROR":
      return { error: true, loading: false, business: [] };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
