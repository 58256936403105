import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div((props) => ({
  height: 45,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: props.theme.palette.background[6],
}));
const Button = styled.div((props) => ({
  height: "100%",
  padding: "0px 15px",
  borderLeft: `1px solid ${props.theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
export default function Bar() {
  return (
    <Root>
      <div style={{ padding: "0px 15px" }}>
        <Skeleton style={{ width: 150, height: 25 }} animation="wave" />
      </div>
      <Button>
        <Skeleton style={{ width: 20, height: 30 }} animation="wave" />
      </Button>
    </Root>
  );
}
