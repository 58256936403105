import React from "react";

export function TwoTableCircle({ height = 90, width = 113, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 90"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M100.673 62.5C102.82 57.0844 104 51.18 104 45C104 38.82 102.82 32.9156 100.673 27.5H105C109.418 27.5 113 31.0817 113 35.5V54.5C113 58.9183 109.418 62.5 105 62.5H100.673Z"
        fill={fill}
      />
      <path
        d="M12.3277 62.5H8.00013C3.58185 62.5 0.00012207 58.9183 0.00012207 54.5V35.5C0.00012207 31.0817 3.58184 27.5 8.00012 27.5H12.3277C10.1803 32.9156 9.00012 38.82 9.00012 45C9.00012 51.18 10.1803 57.0844 12.3277 62.5Z"
        fill={fill}
      />
      <path
        d="M101.5 45C101.5 69.8528 81.3528 90 56.5 90C31.6472 90 11.5 69.8528 11.5 45C11.5 20.1472 31.6472 0 56.5 0C81.3528 0 101.5 20.1472 101.5 45Z"
        fill={fill}
      />
    </svg>
  );
}

export function FourTableCircle({ height = 113, width = 113, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 113"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M107.613 82.934C102.136 93.5306 93.461 102.206 82.8645 107.683L85.4911 110.309C88.6153 113.434 93.6806 113.434 96.8048 110.309L110.24 96.8744C113.364 93.7502 113.364 88.6848 110.24 85.5607L107.613 82.934Z"
        fill={fill}
      />
      <path
        d="M30.1355 107.683C19.539 102.206 10.8637 93.5306 5.38684 82.934L2.75973 85.5611C-0.364462 88.6853 -0.36447 93.7506 2.75973 96.8748L16.1948 110.31C19.3189 113.434 24.3843 113.434 27.5085 110.31L30.1355 107.683Z"
        fill={fill}
      />
      <path
        d="M107.613 30.2052L110.24 27.5783C113.364 24.4541 113.364 19.3888 110.24 16.2646L96.8049 2.8296C93.6807 -0.2946 88.6154 -0.2946 85.4912 2.8296L82.8643 5.45654C93.4609 10.9334 102.136 19.6087 107.613 30.2052Z"
        fill={fill}
      />
      <path
        d="M30.1357 5.45655L27.5083 2.82917C24.3841 -0.295027 19.3188 -0.295027 16.1946 2.82917L2.75959 16.2642C-0.364607 19.3884 -0.364607 24.4537 2.75959 27.5779L5.38693 30.2052C10.8638 19.6087 19.5391 10.9334 30.1357 5.45655Z"
        fill={fill}
      />
      <path
        d="M111.5 56.5697C111.5 86.9454 86.8757 111.57 56.5 111.57C26.1243 111.57 1.5 86.9454 1.5 56.5697C1.5 26.194 26.1243 1.5697 56.5 1.5697C86.8757 1.5697 111.5 26.194 111.5 56.5697Z"
        fill={fill}
      />
    </svg>
  );
}

export function SixTableCircle({ height = 159, width = 150, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 150 159"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M92.5 150.844C92.5 155.263 88.9183 158.844 84.5 158.844H65.5C61.0817 158.844 57.5 155.263 57.5 150.844V147.639C63.0934 149.079 68.9573 149.844 75 149.844C81.0428 149.844 86.9067 149.079 92.5 147.639V150.844Z"
        fill={fill}
      />
      <path
        d="M124.947 128.888L127.737 130.5C131.564 132.709 136.456 131.398 138.666 127.571L148.166 111.117C150.375 107.291 149.064 102.398 145.237 100.189L142.463 98.5868C139.251 110.173 133.134 120.551 124.947 128.888Z"
        fill={fill}
      />
      <path
        d="M142.463 61.1018L145.238 59.4997C149.064 57.2906 150.375 52.3978 148.166 48.5715L138.666 32.117C136.457 28.2907 131.564 26.9797 127.738 29.1888L124.947 30.8001C133.134 39.1375 139.251 49.5158 142.463 61.1018Z"
        fill={fill}
      />
      <path
        d="M92.5 12.0495C86.9067 10.6099 81.0428 9.84424 75 9.84424C68.9573 9.84424 63.0934 10.6099 57.5 12.0495V8.84424C57.5 4.42596 61.0817 0.844238 65.5 0.844238H84.5C88.9183 0.844238 92.5 4.42596 92.5 8.84424V12.0495Z"
        fill={fill}
      />
      <path
        d="M25.053 30.8003L22.2617 29.1888C18.4354 26.9796 13.5426 28.2906 11.3335 32.117L1.83351 48.5714C-0.375633 52.3978 0.935372 57.2905 4.76171 59.4996L7.53729 61.1021C10.7491 49.5161 16.8654 39.1378 25.053 30.8003Z"
        fill={fill}
      />
      <path
        d="M7.53732 98.5865L4.76196 100.189C0.935616 102.398 -0.375381 107.291 1.83376 111.117L11.3338 127.572C13.5429 131.398 18.4356 132.709 22.262 130.5L25.0531 128.888C16.8655 120.551 10.7491 110.172 7.53732 98.5865Z"
        fill={fill}
      />
      <path
        d="M142.5 79.8442C142.5 117.123 112.279 147.344 75 147.344C37.7208 147.344 7.5 117.123 7.5 79.8442C7.5 42.565 37.7208 12.3442 75 12.3442C112.279 12.3442 142.5 42.565 142.5 79.8442Z"
        fill={fill}
      />
    </svg>
  );
}

export function EightTableCircle({
  height = 174,
  width = 174,
  fill = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 174 174"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M162 86.8455C162 128.267 128.421 161.846 87 161.846C45.5786 161.846 12 128.267 12 86.8455C12 45.4242 45.5786 11.8455 87 11.8455C128.421 11.8455 162 45.4242 162 86.8455Z"
        fill={fill}
      />
      <path
        d="M77.5 0.34552C73.0818 0.34552 69.5 3.92724 69.5 8.34552V11.3295C75.1238 10.0315 80.9817 9.34552 86.9998 9.34552C93.0181 9.34552 98.8761 10.0315 104.5 11.3296V8.34552C104.5 3.92724 100.918 0.34552 96.5 0.34552H77.5Z"
        fill={fill}
      />
      <path
        d="M128.019 21.0783C138.03 27.3356 146.51 35.8155 152.767 45.8271L154.882 43.7121C158.006 40.5879 158.006 35.5226 154.882 32.3984L141.447 18.9634C138.323 15.8392 133.258 15.8392 130.133 18.9634L128.019 21.0783Z"
        fill={fill}
      />
      <path
        d="M162.516 69.3456C163.814 74.9694 164.5 80.8273 164.5 86.8455C164.5 92.8637 163.814 98.7217 162.516 104.346H165.5C169.918 104.346 173.5 100.764 173.5 96.3456V77.3456C173.5 72.9273 169.918 69.3456 165.5 69.3456H162.516Z"
        fill={fill}
      />
      <path
        d="M152.767 127.864C146.51 137.876 138.03 146.355 128.018 152.613L130.133 154.728C133.258 157.852 138.323 157.852 141.447 154.728L154.882 141.293C158.006 138.169 158.006 133.103 154.882 129.979L152.767 127.864Z"
        fill={fill}
      />
      <path
        d="M104.5 162.361C98.8761 163.66 93.0181 164.346 86.9998 164.346C80.9817 164.346 75.1238 163.66 69.5 162.362V165.346C69.5 169.764 73.0818 173.346 77.5 173.346H96.5C100.918 173.346 104.5 169.764 104.5 165.346V162.361Z"
        fill={fill}
      />
      <path
        d="M45.9814 152.613C35.9698 146.356 27.4899 137.876 21.2326 127.864L19.1177 129.979C15.9935 133.103 15.9935 138.169 19.1177 141.293L32.5527 154.728C35.6769 157.852 40.7423 157.852 43.8665 154.728L45.9814 152.613Z"
        fill={fill}
      />
      <path
        d="M11.4838 104.346C10.1858 98.7217 9.49982 92.8637 9.49982 86.8455C9.49982 80.8273 10.1858 74.9694 11.4838 69.3456H8.5C4.08172 69.3456 0.5 72.9273 0.5 77.3456V96.3456C0.5 100.764 4.08172 104.346 8.5 104.346H11.4838Z"
        fill={fill}
      />
      <path
        d="M21.2325 45.8269C27.4899 35.8153 35.9697 27.3355 45.9813 21.0782L43.8665 18.9633C40.7423 15.8391 35.677 15.8391 32.5528 18.9633L19.1177 32.3984C15.9935 35.5226 15.9935 40.5879 19.1177 43.7121L21.2325 45.8269Z"
        fill={fill}
      />
    </svg>
  );
}

export function TwoTable({ height = 90, width = 113, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 113 90"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M9 27.5H8C3.58172 27.5 0 31.0817 0 35.5V54.5C0 58.9183 3.58173 62.5 8 62.5H9V27.5Z"
        fill={fill}
      />
      <path
        d="M104 62.5H105C109.418 62.5 113 58.9183 113 54.5V35.5C113 31.0817 109.418 27.5 105 27.5H104V62.5Z"
        fill={fill}
      />
      <path
        d="M11.5 7C11.5 3.13401 14.634 0 18.5 0H94.5C98.366 0 101.5 3.13401 101.5 7V83C101.5 86.866 98.366 90 94.5 90H18.5C14.634 90 11.5 86.866 11.5 83V7Z"
        fill={fill}
      />
    </svg>
  );
}

export function FourTable({ height = 134, width = 133, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 133 134"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M57 0.5C52.5817 0.5 49 4.08172 49 8.49999V9.5H84V8.5C84 4.08172 80.4183 0.5 76 0.5H57Z"
        fill={fill}
      />
      <path
        d="M124 49.5V84.5H125C129.418 84.5 133 80.9183 133 76.5V57.5C133 53.0817 129.418 49.5 125 49.5H124Z"
        fill={fill}
      />
      <path
        d="M84 124.5H49V125.5C49 129.918 52.5817 133.5 57 133.5H76C80.4183 133.5 84 129.918 84 125.5V124.5Z"
        fill={fill}
      />
      <path
        d="M9 84.5V49.5H8C3.58172 49.5 0 53.0817 0 57.5V76.5C0 80.9183 3.58172 84.5 7.99999 84.5H9Z"
        fill={fill}
      />
      <path
        d="M11.5 21C11.5 16.0294 15.5294 12 20.5 12H112.5C117.471 12 121.5 16.0294 121.5 21V113C121.5 117.971 117.471 122 112.5 122H20.5C15.5294 122 11.5 117.971 11.5 113V21Z"
        fill={fill}
      />
    </svg>
  );
}

export function SixTable({ height = 149, width = 223, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 223 149"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M95.66 140.047C95.66 144.465 92.0783 148.047 87.66 148.047H68.66C64.2417 148.047 60.66 144.465 60.66 140.047V139.047H95.66V140.047Z"
        fill={fill}
      />
      <path
        d="M127.32 139.047V140.047C127.32 144.465 130.902 148.047 135.32 148.047H154.32C158.738 148.047 162.32 144.465 162.32 140.047V139.047H127.32Z"
        fill={fill}
      />
      <path
        d="M214 91.5473H215C219.418 91.5473 223 87.9656 223 83.5473V64.5473C223 60.1291 219.418 56.5473 215 56.5473H214V91.5473Z"
        fill={fill}
      />
      <path
        d="M162.32 9.04732V8.04721C162.32 3.62893 158.738 0.0472107 154.32 0.0472107H135.32C130.902 0.0472107 127.32 3.62894 127.32 8.04722V9.04732H162.32Z"
        fill={fill}
      />
      <path
        d="M95.66 9.04732H60.66V8.04722C60.66 3.62894 64.2417 0.0472107 68.66 0.0472107H87.66C92.0783 0.0472107 95.66 3.62893 95.66 8.04721V9.04732Z"
        fill={fill}
      />
      <path
        d="M9 56.5473H8C3.58172 56.5473 0 60.1291 0 64.5473V83.5473C0 87.9656 3.58172 91.5473 8 91.5473H9V56.5473Z"
        fill={fill}
      />
      <path
        d="M11.5 19.5473C11.5 15.129 15.0817 11.5473 19.5 11.5473H203.5C207.918 11.5473 211.5 15.129 211.5 19.5473V128.547C211.5 132.966 207.918 136.547 203.5 136.547H19.5C15.0817 136.547 11.5 132.966 11.5 128.547V19.5473Z"
        fill={fill}
      />
    </svg>
  );
}

export function EightTable({ height = 224, width = 223, fill = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 223 224"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.6602 215.921H60.6602C60.6604 220.339 64.242 223.92 68.6602 223.92H87.6602C92.0783 223.92 95.6599 220.339 95.6602 215.921Z"
        fill={fill}
      />
      <path
        d="M127.32 215.921H162.32C162.32 220.339 158.738 223.92 154.32 223.92H135.32C130.902 223.92 127.32 220.339 127.32 215.921Z"
        fill={fill}
      />
      <path
        d="M214 167.42H215C219.418 167.42 223 163.839 223 159.42V140.42C223 136.002 219.418 132.42 215 132.42H214V167.42Z"
        fill={fill}
      />
      <path
        d="M214 93.4203H215C219.418 93.4203 223 89.8386 223 85.4203V66.4203C223 62.002 219.418 58.4203 215 58.4203H214V93.4203Z"
        fill={fill}
      />
      <path
        d="M162.32 10.9207V8.92029C162.32 4.50201 158.738 0.920288 154.32 0.920288H135.32C130.902 0.920288 127.32 4.50201 127.32 8.92028V10.9207H162.32Z"
        fill={fill}
      />
      <path
        d="M95.6602 10.9207V8.92029C95.6602 4.50201 92.0784 0.920288 87.6602 0.920288H68.6602C64.2419 0.920288 60.6602 4.50201 60.6602 8.92028V10.9207H95.6602Z"
        fill={fill}
      />
      <path
        d="M9 58.4203H8C3.58172 58.4203 0 62.002 0 66.4203V85.4203C0 89.8386 3.58172 93.4203 8 93.4203H9V58.4203Z"
        fill={fill}
      />
      <path
        d="M9 132.42H8C3.58172 132.42 0 136.002 0 140.42V159.42C0 163.839 3.58172 167.42 8 167.42H9V132.42Z"
        fill={fill}
      />
      <path
        d="M11.5 23.4207C11.5 17.8979 15.9772 13.4207 21.5 13.4207H201.5C207.023 13.4207 211.5 17.8979 211.5 23.4207V203.421C211.5 208.944 207.023 213.421 201.5 213.421H21.5C15.9772 213.421 11.5 208.944 11.5 203.421V23.4207Z"
        fill={fill}
      />
    </svg>
  );
}

export function Analysis(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <g fill={props.color}>
        <path d="M509 94L418 3a10 10 0 0 0-7-3H114a30 30 0 0 0-30 30v19A132.3 132.3 0 0 0 0 172a10 10 0 0 0 10 10h122a10 10 0 0 0 10-10V50a10 10 0 0 0-10-10c-9.5 0-19 1-28 3V30a10 10 0 0 1 10-10h287v61a30 30 0 0 0 30 30h61v371a10 10 0 0 1-10 10H114a10 10 0 0 1-10-10v-85.7a10 10 0 0 0-20 0V482a30 30 0 0 0 30 30h368a30 30 0 0 0 30-30V101c0-2.7-1-5.2-3-7zM122 60.3v101.7H20.5A112.3 112.3 0 0 1 122.1 60.4zM431 91a10 10 0 0 1-10-10V34.1L477.9 91H431z" />
        <path d="M101 353a10 10 0 0 0-14 0 10 10 0 0 0 0 14 10 10 0 0 0 14 0 10 10 0 0 0 0-14zM168 75.8a10 10 0 0 0-10 10V198H45.7a10 10 0 0 0-10 10C35.8 280.7 95.1 340 168 340S300 280.7 300 208 240.7 75.7 168 75.7zm10 20.5c23.3 2 44.7 11.4 61.7 25.6L178 183.8V96.3zM168 320c-58.5 0-106.7-45-111.7-102h111.6a10 10 0 0 0 7-3l79-79a112.2 112.2 0 0 1-86 184zM438 315.6a30 30 0 0 0-27.6 41.7l-57.6 57.6a29.8 29.8 0 0 0-25.9-.1l-38.2-38.3a30 30 0 1 0-55.2 1.6L196.8 415A30 30 0 1 0 211 429l36.5-36.5a29.8 29.8 0 0 0 28-.8l37.2 37.2a30 30 0 1 0 54.2.3l57-57.1a30 30 0 1 0 14.1-56.5zM184 452a10 10 0 1 1 7-17.2v.2l.2.1a10 10 0 0 1-7.2 17zm76.8-76.3a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm79 76.3a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm98.4-96.3a10 10 0 1 1 0-20 10 10 0 0 1 0 20zM388 235h-52.7a10 10 0 0 0 0 20h52.8a10 10 0 0 0 0-20zM440.7 238a10 10 0 0 0-14.1 0 10 10 0 0 0 0 14 10 10 0 0 0 14 0 10 10 0 0 0 0-14zM457.5 139H335.3a10 10 0 0 0 0 20h122.2a10 10 0 0 0 0-20zM457.5 187H335.3a10 10 0 0 0 0 20h122.2a10 10 0 0 0 0-20z" />
      </g>
    </svg>
  );
}

export function Appetizer(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M118.4 37.3a2 2 0 0 0-3.6 1.8c2 4.2 3.6 8.6 4.6 13.2h-18.8a2 2 0 0 0 0 4h19.6c.3 2.7.5 5.4.5 8H86.5a2 2 0 1 0 0 4.1h34c-.1 2.7-.5 5.4-1 8h-43a2 2 0 0 0 0 4h41.9c-.8 2.8-1.9 5.5-3.1 8.1H66.4a2 2 0 1 0 0 4h46.8a56.6 56.6 0 0 1-5.7 8.1H64.4a2 2 0 0 0 0 4h39.4c-3.2 3.1-6.7 5.8-10.4 8H50.3a2 2 0 0 0 0 4.1h35a56.4 56.4 0 0 1-44.2-1 2 2 0 0 0-1.7 3.7 60.3 60.3 0 0 0 85.3-53.2v-1.8c0-9.4-2.1-18.7-6.3-27z" />
        <path
          d="M121.3 4.6l-4 4a12.1 12.1 0 0 0-19.2 11l-1-.6c-1.6-.8-3.3-1-4.9-.5a15.8 15.8 0 0 0-10.8 10.8c-.4 1.6-.3 3.3.5 4.8 1 2.1 2.4 4.1 4 6l-2.4 2.3a11 11 0 0 1-3-2.2 11 11 0 0 1-3-4.4c-.7-2.2-2-4.2-3.6-5.8A2 2 0 1 0 71 33a11 11 0 0 1 2.8 4.4c.8 2.1 2.1 4.1 3.8 5.7a16 16 0 0 0 3 2.4l-2.9 2.8a12.1 12.1 0 0 0-19 10.9l-1.2-.6c-1.4-.8-3.2-1-4.8-.5A15.8 15.8 0 0 0 42 68.8c-.4 1.6-.2 3.3.5 4.8 1 2.1 2.4 4.1 4 6l-1.8 1.7a11 11 0 0 1-4.4-2.9 11 11 0 0 1-2.9-4.4c-.7-2.2-2-4.1-3.7-5.7a2 2 0 0 0-2.8 2.8 11 11 0 0 1 2.8 4.4c1.5 3.8 4.3 7 8 8.8L40 85.8a12.1 12.1 0 0 0-19.2 11l-1-.6c-1.5-.8-3.2-1-4.8-.5a15.9 15.9 0 0 0-10.8 10.8c-.5 1.6-.3 3.3.4 4.8 1.1 2.1 2.4 4 4 5.9l-4 4a2 2 0 1 0 2.8 2.9l4-4c1.9 1.5 3.9 2.8 6 4 1 .4 2 .6 3 .7l1.8-.3c5.2-1.5 9.3-5.6 10.8-10.8.5-1.6.3-3.3-.5-4.8l-.6-1h1a12.1 12.1 0 0 0 10-19.2l2.5-2.5c.7.4 1.4 1 2 1.6a11 11 0 0 1 2.8 4.4 15 15 0 0 0 3.8 5.8 2 2 0 0 0 2.8-2.8 11 11 0 0 1-2.8-4.4 17.3 17.3 0 0 0-5.7-7.5l.9-1c1.8 1.6 3.7 3 5.9 4 1 .5 2 .8 3 .8a6 6 0 0 0 1.8-.3c5.2-1.5 9.3-5.6 10.8-10.8.4-1.6.3-3.3-.5-4.8l-.6-1h1a12.1 12.1 0 0 0 10-19.2l3.7-3.8c1.3.6 2.4 1.4 3.4 2.4a11 11 0 0 1 2.8 4.4 15 15 0 0 0 3.7 5.8 2 2 0 1 0 2.9-2.9 11 11 0 0 1-2.9-4.4 15 15 0 0 0-3.7-5.8c-1-1-2-1.8-3.2-2.5l1.3-1.3c1.8 1.6 3.8 2.9 6 4 1 .4 2 .7 3 .7.6 0 1.2 0 1.7-.3 5.3-1.5 9.3-5.5 10.8-10.8.5-1.6.3-3.3-.4-4.8l-.6-1h1a12.2 12.2 0 0 0 9.9-19.2l4-4a2 2 0 0 0-2.8-3zm-82.5 96.9A8.1 8.1 0 1 1 27.2 90a8.1 8.1 0 0 1 11.6 11.5zm37.7-37.7a8.1 8.1 0 1 1-11.7-11.3 8.1 8.1 0 0 1 11.7 11.3zM116 24.3a8.1 8.1 0 1 1 0-11.5 8.2 8.2 0 0 1 0 11.5z"
          fillRule="nonzero"
        />
        <path d="M4 66.7c.3 7.8 2.2 15.5 5.4 22.7a2 2 0 1 0 3.7-1.7c-1.1-2.3-2-4.7-2.7-7.2h17.8a2 2 0 1 0 0-4H9.3c-.6-2.7-1-5.4-1.1-8h18a2 2 0 0 0 0-4.1H8c0-2.7.2-5.4.6-8h37.7a2 2 0 1 0 0-4h-37c.6-2.8 1.5-5.5 2.5-8.1h50.6a2 2 0 0 0 0-4h-49c1.4-3 3-5.6 4.8-8.2H62.4a2 2 0 0 0 0-4H21.3c2.5-3 5.2-5.6 8.3-8h50.9a2 2 0 1 0 0-4H35.4C51.9 6 72.4 5.4 89.6 14a2 2 0 0 0 1.9-3.6A60.3 60.3 0 0 0 4 64.4v2.3z" />
      </g>
    </svg>
  );
}

export function Bullhorn(props) {
  return (
    <svg width="99" height="73" viewBox="0 0 99 73" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path
          d="M69.3 0h-5a4.9 4.9 0 0 0-4.7 4c-3.8 1.8-22.5 10.7-47 14a5 5 0 0 0-3.4 2H4.9A4.9 4.9 0 0 0 0 24.7v9.9c0 2.7 2.2 4.9 4.9 4.9h4.3a5 5 0 0 0 3.3 2h.8l7.6 27.5c.6 2 2.5 3.5 4.7 3.5h10.7a1.6 1.6 0 0 0 0-3h-.5l-7-25a153 153 0 0 1 30.8 11 4.9 4.9 0 0 0 4.8 3.8h5c2.6 0 4.8-2.2 4.8-4.9V5c0-2.6-2.2-4.8-4.9-4.8zm-61 36.5H4.9c-1 0-1.8-.8-1.8-1.8v-10c0-.9.8-1.7 1.8-1.7h3.4v13.5zm24.3 33h-7c-.8 0-1.5-.5-1.7-1.3l-7.3-26c3 .4 6 1 8.8 1.6l7.2 25.7zm38.5-15c0 1-.8 1.8-1.8 1.8h-5c-.9 0-1.7-.8-1.7-1.8V18.2a1.6 1.6 0 0 0-3 0V52a162.9 162.9 0 0 0-46.7-13.5c-.9-.1-1.5-.9-1.5-1.8v-14c0-.8.6-1.6 1.5-1.7 22.7-3 40.5-10.7 46.6-13.6v4.2a1.6 1.6 0 0 0 3.1 0V4.9c0-1 .8-1.7 1.8-1.7h5c1 0 1.7.8 1.7 1.7v49.6z"
          fillRule="nonzero"
        />
        <path d="M97.4 28.2H81a1.6 1.6 0 0 0 0 3h16.5a1.6 1.6 0 0 0 0-3zM94 12.6a1.5 1.5 0 0 0-2.1-.8l-1.7.7a1.5 1.5 0 1 0 1.2 2.8l1.7-.7c.8-.3 1.2-1.2.8-2zM87.3 15.4a1.6 1.6 0 0 0-2-.8l-5 2.1a1.5 1.5 0 1 0 1.2 2.9l5-2.1c.8-.4 1.1-1.3.8-2zM93.1 44.8l-1.7-.7a1.5 1.5 0 1 0-1.2 2.9l1.7.7a1.5 1.5 0 0 0 2-.8c.4-.8 0-1.7-.8-2zM86.5 42l-5-2.1a1.6 1.6 0 0 0-1.2 2.8l5 2.2a1.5 1.5 0 0 0 2-.9c.3-.7 0-1.7-.8-2z" />
      </g>
    </svg>
  );
}

export function Cheese(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M54.8 79.3c-1.8-.7-3.7-1.6-5.3-3.3 1.3 3 3.8 5.4 7 6.5l2 .3c2.4 0 4-1.4 4.2-4-1.3.7-2.9 1-4.4 1-1 0-2.3 0-3.5-.5zm1.4-4.4l2.4.2c1.1 0 2.3-.5 3-1.2v-.4l-.3-.5c-1.1-2-2.8-3.6-4.8-4.6l-1.7-.7-1.4-.3h-.2c-1 0-1.9 0-2.6.5l-1.1.7c1.4 2.9 4 5 7 6l-.3.3z" />
        <path d="M120.7 81.2l2 .7a2.3 2.3 0 0 0 3.3-2.4v-5.1c0-5-2.3-9.7-6.2-12.8L79.3 23.2c-1-.7-2.2-.7-3 0-.8.7-2 .7-3 .2-2.9-.7-5.4-4-5.4-7 0-.4 0-.9.4-1.3.5-1 .2-2.2-.7-2.8l-7-6.6c-5.3-4.4-12-5.8-17-3.4L9 18.8a11 11 0 0 0-6.3 10.5V66a2.3 2.3 0 0 0 2.8 2.4h2.3c3.5 1.1 6.5 5 6.5 8.6 0 2.8-2.3 4.2-5 3.2-1-.2-2-.7-2.9-1.6a2.3 2.3 0 0 0-3.7 1.9v5A11 11 0 0 0 10.8 96l101.7 30a11 11 0 0 0 13.5-10.6V96c0-1.1-.7-2.3-1.8-2.3a9.8 9.8 0 0 1-7.5-7.7c0-1.4 0-2.8.7-3.5 1-.9 2.1-1.3 3.5-1.1l-.2-.2zM66.5 56l-1.2-.2a8.1 8.1 0 0 1-4.2-5.6V49c0-1.6 1-2.5 2.3-2.5h1.7c2.5 1 4.9 3.7 5 6.5v.5c.2.4.2.9 0 1.4-.2.7-.8 1.2-1.5 1.4h-2.1V56zM11 23.4L45.5 6.7c1-.5 2.1-.7 3.3-.7 3 0 6 1.1 8.8 3.2l5.8 5.4v2c0 4.7 3.5 9.9 8.4 11.5 1.9.5 4 .5 5.6 0L114.6 63 74.1 50.9c-1.1-4-4.1-7.2-8.1-8.6-.9-.3-1.8-.5-2.8-.5a7 7 0 0 0-6.5 4l-47-14c-1.4-.2-2.3-1.1-2.3-3v-.2c0-2.4 1.3-4.5 3.4-5.4l.3.2zm9.5 33.8a9.3 9.3 0 0 1-6.3-8.4c0-3.3 2.4-5.8 5.9-5.8l2 .2a9.3 9.3 0 0 1 6.3 8.6c0 3.3-2.3 5.8-5.6 5.8a7 7 0 0 1-2.3-.4zm14 36.3l-2.6-.2c-4.6-1.7-8.4-6.3-8.4-11a7 7 0 0 1 7-7.2l2.8.2c4.7 1.7 8.2 6.3 8.2 11 0 4.2-2.8 7.2-7 7.2zm24-6c-1.2 0-2.4 0-3.8-.5-6.3-2.2-10.6-8-11-14.7 0-1.6.3-3 1-4.4v-.2l.3-.5a8.1 8.1 0 0 1 3-3c1.4-.7 3-1.2 4.6-1.2 2 0 3.9.5 5.6 1.4l.7.2 1.2.7.7.7.7.5 1.6 1.6.7.5c0 .5.5.7.7 1.1l.5.7c0 .5.4.7.7 1.2l.7 1.6c.7 1.7.9 3.3.9 5 0 5.3-3.7 9.2-9 9.2h.2zm20-19.1h1.8a9 9 0 0 1 6 8.1c0 3-2.2 5.4-5.3 5.4h-1.8a9 9 0 0 1-6-8.2c0-3 2.2-5.3 5.3-5.3zm4.2 41.1l-2-.2c-3.4-1-6-4.6-6-7.9 0-3.3 2.4-5.6 5.4-5.6.7 0 1.4 0 2 .5 3.3 1 6 4.6 6 7.9 0 3-2.4 5.3-5.4 5.3zm17-11.4l-2.6-.2c-4.7-1.6-8.4-6.3-8.4-11a7 7 0 0 1 7-7.1l2.8.2c4.6 1.6 8.1 6.3 8.1 11 0 4.1-2.8 7.1-7 7.1z" />
      </g>
    </svg>
  );
}

export function Drink(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color}>
        <path
          d="M99.2 61.9L95.4 52A33.6 33.6 0 0 1 92.8 29l.4-1.6a3.7 3.7 0 0 0-3.6-4.6H76.2l1.7-8.4 20.3-6.8a3.7 3.7 0 1 0-2.3-7L73.5 7.8a3.7 3.7 0 0 0-2.5 2.8l-2.4 12h-5.5a18.7 18.7 0 0 0-37 3.7c0 9 6.5 16.8 15.4 18.4-.6 2.6-1.5 5.1-2.7 7.5l-3.6 9.6A25.9 25.9 0 0 0 56 97.5V112l-13.3 8.8a3.7 3.7 0 0 0 2 6.9h44.9a3.7 3.7 0 0 0 2-6.9l-13.2-8.8V97.4A26 26 0 0 0 99.2 62zM49.4 30h17.7l-3 15h-15c1-5.4 1-10.9.3-15zm2.8 45.4a3.7 3.7 0 1 1 0-7.5 3.7 3.7 0 0 1 0 7.5zM71 108.9c-2.5-.5-5-.5-7.5 0v-11H71v11zm-1-27a3.7 3.7 0 1 1-5.4-5.3 3.7 3.7 0 0 1 5.3 5.2zm7.4-15a3.7 3.7 0 1 1-5.3-5.3 3.7 3.7 0 0 1 5.3 5.3zM71.7 45l3-15H85c-.8 5-.6 10 .4 15H71.7z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export function KnifeFork(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M12.1253 1.66699C11.8722 1.66699 11.667 1.87219 11.667 2.12533V17.5003C11.667 17.9606 12.0401 18.3337 12.5003 18.3337H13.3337C13.7939 18.3337 14.167 17.9606 14.167 17.5003V14.167H15.0003C15.9208 14.167 16.667 13.4208 16.667 12.5003V6.20866C16.667 3.70037 14.6336 1.66699 12.1253 1.66699ZM15.0003 12.5003H13.3337V3.60033C14.3484 4.0726 14.9981 5.08943 15.0003 6.20866V12.5003Z"
        fill="#696969"
      />
      <path
        d="M9.16683 1.66699H5.00016C4.07969 1.66699 3.3335 2.41318 3.3335 3.33366V9.16699C3.3335 10.0875 4.07969 10.8337 5.00016 10.8337H5.8335V17.5003C5.8335 17.9606 6.20659 18.3337 6.66683 18.3337H7.50016C7.9604 18.3337 8.3335 17.9606 8.3335 17.5003V10.8337H9.16683C10.0873 10.8337 10.8335 10.0875 10.8335 9.16699V3.33366C10.8335 2.41318 10.0873 1.66699 9.16683 1.66699ZM9.16683 9.16699H5.00016V3.33366H5.8335V7.08366C5.8335 7.31378 6.02004 7.50033 6.25016 7.50033C6.48028 7.50033 6.66683 7.31378 6.66683 7.08366V3.33366H7.50016V7.08366C7.50016 7.31378 7.68671 7.50033 7.91683 7.50033C8.14695 7.50033 8.3335 7.31378 8.3335 7.08366V3.33366H9.16683V9.16699Z"
        fill="#696969"
      />
    </svg>
  );
}

export function PhoneWithInfo(props) {
  return (
    <svg width="70" height="95" viewBox="0 0 70 95" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M57 16c.8 0 1.5-.6 1.5-1.4V6.4C58.5 3 55.6 0 52.1 0H6.5A6.4 6.4 0 0 0 0 6.4V88c0 3.5 2.8 6.4 6.4 6.4H52c3.5 0 6.4-2.9 6.4-6.4v-26a1.5 1.5 0 0 0-3 0v26c0 1.9-1.5 3.4-3.4 3.4H6.5A3.4 3.4 0 0 1 3 87.9V6.4c0-1.8 1.5-3.3 3.4-3.3H52c1.9 0 3.4 1.5 3.4 3.3v8.2c0 .8.7 1.5 1.5 1.5z" />
        <path d="M32.6 6.5H26a1.5 1.5 0 0 0 0 3h6.6a1.5 1.5 0 0 0 0-3zM19.5 6.5c-.8 0-1.5.7-1.5 1.5a1.5 1.5 0 0 0 3 0c0-.8-.6-1.5-1.5-1.5zM32.6 84.7H26a1.5 1.5 0 0 0 0 3.1h6.6a1.5 1.5 0 0 0 0-3zM50.5 62c-.9 0-1.5.6-1.5 1.5v14.7H9.6V16.1h40.9a1.5 1.5 0 0 0 0-3H8c-.8 0-1.5.6-1.5 1.5v65.2c0 .8.7 1.5 1.5 1.5h42.4c.8 0 1.5-.7 1.5-1.5V63.5c0-.9-.7-1.6-1.5-1.6z" />
        <path d="M50.5 27.7c-.9 0-1.5.7-1.5 1.5a1.5 1.5 0 0 0 3 0c0-.8-.7-1.5-1.5-1.5z" />
        <path
          d="M50.5 19.6a19.5 19.5 0 0 0-12.9 34 25.2 25.2 0 0 1-4.4 5.4 1.5 1.5 0 0 0 1.1 2.7c.3 0 6-.5 11-4a19.5 19.5 0 1 0 5.2-38.1zm0 35.8c-1.7 0-3.3-.2-5-.7-.4-.2-.9-.1-1.3.2-1.9 1.4-4 2.2-5.7 2.8.8-1 1.6-2.3 2.4-3.7.4-.7.2-1.5-.4-2a16.4 16.4 0 1 1 10 3.4z"
          fillRule="nonzero"
        />
        <path d="M52.1 47.3v-10a1.5 1.5 0 0 0 0-3H49a1.5 1.5 0 0 0 0 3v10a1.5 1.5 0 0 0 0 3H52a1.5 1.5 0 0 0 0-3zM27.7 53.8H16.3a1.5 1.5 0 0 0 0 3h11.4a1.5 1.5 0 0 0 0-3zM21.1 47.3h-4.8a1.5 1.5 0 0 0 0 3H21a1.5 1.5 0 0 0 0-3zM27.7 60.3H16.3a1.5 1.5 0 0 0 0 3h11.4a1.5 1.5 0 0 0 0-3zM19.5 71.7h-3.2a1.5 1.5 0 0 0 0 3h3.2a1.5 1.5 0 0 0 0-3zM42.3 71.7h-3.2a1.5 1.5 0 0 0 0 3h3.2a1.5 1.5 0 0 0 0-3zM31 71.7h-3.3a1.5 1.5 0 0 0 0 3h3.2a1.5 1.5 0 0 0 0-3z" />
      </g>
    </svg>
  );
}

export function ListWithHand(props) {
  return (
    <svg width="80" height="98" viewBox="0 0 80 98" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M21.2 19.6c-.9 0-1.6.7-1.6 1.6a3.4 3.4 0 1 1-3.3-3.4 1.5 1.5 0 0 0 0-3 6.5 6.5 0 1 0 6.4 6.4c0-.9-.7-1.6-1.5-1.6zM21.2 37.6c-.9 0-1.6.7-1.6 1.6a3.4 3.4 0 1 1-3.3-3.4 1.5 1.5 0 0 0 0-3 6.5 6.5 0 1 0 6.4 6.4c0-.9-.7-1.6-1.5-1.6zM39.2 41H27.7a1.5 1.5 0 0 0 0 3h11.5a1.5 1.5 0 0 0 0-3zM39.2 34.4H27.7a1.5 1.5 0 0 0 0 3h11.5a1.5 1.5 0 0 0 0-3zM36 59h-8.3a1.5 1.5 0 0 0 0 3H36a1.5 1.5 0 0 0 0-3zM39.2 52.4H27.7a1.5 1.5 0 0 0 0 3h11.5a1.5 1.5 0 0 0 0-3zM54 23H27.6a1.5 1.5 0 0 0 0 3H54a1.5 1.5 0 0 0 0-3zM47.4 16.4H27.7a1.5 1.5 0 0 0 0 3h19.7a1.5 1.5 0 0 0 0-3zM21.2 55.6c-.9 0-1.6.7-1.6 1.6a3.4 3.4 0 1 1-3.3-3.4 1.5 1.5 0 0 0 0-3 6.5 6.5 0 1 0 6.4 6.4c0-.9-.7-1.6-1.5-1.6z" />
        <path d="M60.5 0H4.8A4.8 4.8 0 0 0 0 4.8v68.7c0 2.7 2.2 4.9 4.8 4.9h27.8a1.5 1.5 0 0 0 0-3.1H4.8c-1 0-1.7-.8-1.7-1.8V4.8c0-1 .8-1.7 1.7-1.7h55.7c1 0 1.7.7 1.7 1.7v50.7a1.5 1.5 0 0 0 3 0V4.8c0-2.6-2-4.8-4.7-4.8z" />
        <path
          d="M75 62.2c-1.5-.1-3 .3-4 1.1a5.6 5.6 0 0 0-4.2-2.7c-1.5-.2-2.9.2-4 1.1a5.6 5.6 0 0 0-4.2-2.8c-1.1 0-2.2.1-3.2.6V40a5.6 5.6 0 0 0-6.2-5.6c-2.8.3-5 2.8-5 5.8v19.6l-4.5 4.5a9.7 9.7 0 0 0-2.6 9.1L40.5 88a13 13 0 0 0 12.7 10H67a13 13 0 0 0 13-13V68c0-3-2.2-5.5-5-5.8zM77 85a10 10 0 0 1-10 10H53.2a9.9 9.9 0 0 1-9.7-7.7l-3.4-14.6c-.5-2.2.1-4.6 1.8-6.2l2.3-2.3v5.3a1.5 1.5 0 0 0 3 0V40.2c0-1.4 1-2.6 2.4-2.7a2.5 2.5 0 0 1 2.8 2.5v27a1.5 1.5 0 0 0 3 0v-2.5a2.6 2.6 0 0 1 2.9-2.5c1.3.1 2.3 1.3 2.3 2.8v3.8a1.5 1.5 0 0 0 3 0v-2.4a2.6 2.6 0 0 1 2.9-2.6c1.2.2 2.2 1.4 2.2 2.8v3.9a1.5 1.5 0 0 0 3.1 0v-2.5a2.6 2.6 0 0 1 2.8-2.5c1.3.1 2.3 1.3 2.3 2.7v17z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export function Trophy(props) {
  return (
    <svg width="50" height="61" viewBox="0 0 50 61" {...props}>
      <g fill="#4A5155" fillRule="evenodd">
        <path
          d="M46.9 6.3h-7.4H41c1.3 0 2.3-1.1 2.3-2.4V2.3c0-1.3-1-2.3-2.3-2.3H27a.9.9 0 1 0 0 1.8h14.1c.3 0 .6.2.6.5v1.6c0 .3-.3.5-.6.5H9a.5.5 0 0 1-.6-.5V2.3c0-.3.3-.5.6-.5h14a.9.9 0 0 0 0-1.8h-14C7.6 0 6.6 1 6.6 2.3v1.6c0 1.3 1 2.3 2.3 2.3h1.6v.1H3.1a3 3 0 0 0-3 3.1v13.7c0 7 5.6 12.6 12.5 12.6h3.8c1 .8 2.2 1.4 3.4 2l-1.9 8.6h-6.8c-1.3 0-2.3 1-2.3 2.3v9.5c0 1.2 1 2.3 2.3 2.3h27.8c1.3 0 2.3-1 2.3-2.3v-2.7a.9.9 0 1 0-1.7 0v2.7c0 .3-.3.5-.6.5H11.1a.5.5 0 0 1-.6-.5v-9.5c0-.3.3-.5.6-.5h27.8c.3 0 .6.2.6.5v2.7a.9.9 0 1 0 1.7 0v-2.7c0-1.3-1-2.3-2.3-2.3h-6.8l-1.9-8.7c1.2-.5 2.4-1 3.4-1.9h3.8c7 0 12.6-5.6 12.6-12.6V9.4a3 3 0 0 0-3.1-3zM10.5 11V24c0 2.5.7 5 1.8 7a8 8 0 0 1-7.6-8V11h5.8zm2.1 23C6.6 34 1.8 29 1.8 23V9.4c0-.7.6-1.3 1.3-1.3h7.4v1.1H3.8c-.5 0-.9.4-.9.9v13c0 5.4 4.4 9.8 9.7 9.8h.9l.9 1h-1.8zm17.7 12.3H19.7l1.8-8.1a14.7 14.7 0 0 0 7 0l1.8 8.1zM25 36.8c-7 0-12.7-5.7-12.7-12.7V6.2h25.4v18c0 7-5.7 12.6-12.7 12.6zm14.5-12.7V11h5.8V23a8 8 0 0 1-7.6 8c1.1-2 1.8-4.5 1.8-7zM37.4 34h-1.8l1-1.1h.8c5.3 0 9.7-4.4 9.7-9.8v-13c0-.5-.4-.9-.9-.9h-6.7V8.1h7.4c.7 0 1.3.6 1.3 1.3v13.7c0 6-4.9 10.9-10.8 10.9z"
          fillRule="nonzero"
        />
        <path d="M33.8 52.5H16.2a.9.9 0 0 0 0 1.7h17.6a.9.9 0 0 0 0-1.7z" />
        <path
          d="M32.3 14.4l-4.5-.7-2-4a.9.9 0 0 0-1.6 0l-2 4-4.5.7c-.7 0-1 1-.4 1.5l3.2 3.1-.8 4.5a.9.9 0 0 0 1.3 1l4-2.2 4 2.1a.9.9 0 0 0 1.3-.9l-.8-4.5 3.2-3.1a.9.9 0 0 0-.4-1.5zM27.9 18c-.2.2-.3.5-.2.8l.5 3.1-2.8-1.5a.9.9 0 0 0-.8 0L21.8 22l.5-3.1c0-.3 0-.6-.2-.8L19.8 16l3.1-.5c.3 0 .6-.2.7-.5l1.4-2.8 1.4 2.8c.1.3.4.5.7.5l3.1.5L28 18z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export function MainCourse(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M98.5 46c-.2.7-.7 1.3-1.5 1.6-3 .8-6 2.2-8.4 4.1l-2 1.6-1.6 1.6v.1l-1.1 1.3h-.1l-3 4.2-.4.8a47 47 0 0 0-3.2 7l-.5 1.8-1 3.7-.3 1.8-.2 1.7-.2 1.4-.3 4.3h46.8c-.3-18.2-3.6-30.7-9.7-36.5l5-15.1c.4-1 1.4-1.7 2.5-1.6a8.5 8.5 0 1 0-1.7-16.8c-.8.1-1.5-.2-2-.8a8.5 8.5 0 1 0-11.2 12.2c.8.5 1.2 1.6.9 2.6L100 42.7c-1 0-2 .2-3 .5l.8.7c.6.5.9 1.3.7 2zm10.8-17.7c1-2.8-.1-6-2.8-7.6a4.2 4.2 0 1 1 5.7-6.1 6 6 0 0 0 6.3 2.5h1.8a4.2 4.2 0 0 1-1 8.5 6.7 6.7 0 0 0-6.5 4.4L108.2 44l-.5-.2-1.1-.4-.5-.2-.5-.1h-.2l-.9-.3 4.8-14.4z" />
        <path d="M22.4 113.5a34 34 0 0 1 10.7 5.6c5.4 4 15 8.7 30.9 8.7 15.8 0 25.5-4.8 30.8-8.7a34 34 0 0 1 10.8-5.6c7.3-2 19.8-8 22.2-23a6.4 6.4 0 0 0-6.3-7.5v2.2c0 1.1-1 2.1-2.2 2.1H38.4a4.2 4.2 0 0 1-7.8 2.3l-.3-.3a2 2 0 0 0-2.9 0l-.3.3a4.2 4.2 0 0 1-7.5-.8c-.3-.7-.9-1.2-1.6-1.4-.8-.1-1.5.1-2 .7-.8.8-2 1.3-3.1 1.3a4.2 4.2 0 0 1-1.5-8.2 2.1 2.1 0 0 0 .8-3.6c-1-.8-1.4-1.9-1.4-3 0-2.4 1.9-4.3 4.2-4.3.5 0 1 0 1.5.2a2 2 0 0 0 2-.3c.5-.4.9-1.1.8-1.8V68a4.2 4.2 0 1 1 7.9-2.2 34 34 0 0 0-1.2 6l-.2 1.3-.1 3.5c0 1.1 1 2 2.1 2 1.8 0 3.4 1.2 4 3a2 2 0 0 0 1.6 1.3h37a45 45 0 0 1 .5-5.5l.2-1.8a39.6 39.6 0 0 1 18.8-30c2.3-1.1 4.6-2 7-2.5-7.5-6-16.9-9.2-26.5-9.1a45.4 45.4 0 0 0-41.7 27.3A8.5 8.5 0 0 0 15.3 66a8.5 8.5 0 0 0-7.8 12.6 8.7 8.7 0 0 0-3 4.7c-3 1-4.8 4-4.3 7.1 2.4 15.2 15 21 22.2 23.1zm42-70.7c.6-1 2-1.2 3-.6 3.1 2.1 6 4.6 8.8 7.4a2.1 2.1 0 0 1 0 3 2.1 2.1 0 0 1-3 0 53.6 53.6 0 0 0-8.2-6.8 2.1 2.1 0 0 1-.6-3zm-13 7.4c.6-1 1.8-1.5 2.9-1 5.3 2.7 10 6.6 13.6 11.4a2.1 2.1 0 0 1-.6 3 2.1 2.1 0 0 1-3-.6 36.2 36.2 0 0 0-11.9-10c-1-.5-1.5-1.8-1-2.8zm-12.9 11a2.1 2.1 0 0 1 2.6-1.5c.4 0 9.4 2.5 18.3 15.8a2.1 2.1 0 0 1-.6 3 2.1 2.1 0 0 1-3-.6C48 66 40.1 63.8 40 63.7a2.1 2.1 0 0 1-1.5-2.6zM62 15a2.1 2.1 0 1 0 4.2 0c0-.2 0-.2.5-.6a4.7 4.7 0 0 0 0-7.3c-.5-.4-.5-.5-.5-.6 0-.2 0-.2.5-.6 1-1 1.6-2.3 1.6-3.7a2.1 2.1 0 1 0-4.2 0c0 .2 0 .2-.4.6-1.1 1-1.7 2.3-1.7 3.7 0 1.4.6 2.7 1.7 3.6l.4.7-.4.6a5 5 0 0 0-1.7 3.6zM49 25.6a2.1 2.1 0 0 0 4.2 0c0-.2 0-.2.5-.7a4.7 4.7 0 0 0 0-7.3c-.5-.4-.5-.4-.5-.6 0-.1 0-.2.5-.6 1-1 1.7-2.2 1.7-3.6a2.1 2.1 0 0 0-4.3 0l-.4.6a4.7 4.7 0 0 0 0 7.3c.4.4.4.4.4.6 0 .2 0 .2-.4.6a5 5 0 0 0-1.7 3.7zm25.5 0a2.1 2.1 0 1 0 4.3 0l.4-.7a4.7 4.7 0 0 0 0-7.3c-.4-.4-.4-.4-.4-.6 0-.1 0-.2.4-.6 1-1 1.7-2.2 1.7-3.6a2.1 2.1 0 0 0-4.2 0l-.5.6a4.7 4.7 0 0 0 0 7.3c.5.4.5.4.5.6 0 .2 0 .2-.5.6a5 5 0 0 0-1.7 3.7z" />
      </g>
    </svg>
  );
}

export function Money(props) {
  return (
    <svg width="60" height="48" viewBox="0 0 60 48" {...props}>
      <g fill="#4A5155" fillRule="nonzero">
        <path d="M43.1 18.3a6.8 6.8 0 1 0 0 13.6 6.8 6.8 0 0 0 0-13.6zm0 11.8a5 5 0 1 1 0-10 5 5 0 0 1 0 10z" />
        <path d="M57.8 31.4V20.7a.9.9 0 1 0-1.8 0v4.6h-3.3a9.6 9.6 0 0 0-14.1-8.7A10 10 0 0 0 31 9a.9.9 0 0 0-.3 1.7c3.4.7 5.9 3.6 6.3 7a9.5 9.5 0 0 0-3.5 6.9v.7A8.1 8.1 0 1 1 27 10.7a.9.9 0 0 0-.4-1.7 10 10 0 1 0 7.2 18.2l.3 1a9.6 9.6 0 0 0-7.6 7.3H12V32c0-3.8-3-6.8-6.7-6.8H1.8V12h3.4c3.7 0 6.7-3 6.7-6.7V1.8h34v3.4c0 3.7 3 6.7 6.7 6.7H56v4.7a.9.9 0 1 0 1.8 0V2.3c0-1.3-1-2.3-2.3-2.3H2.3C1 0 0 1 0 2.3v32.6c0 1.3 1 2.3 2.3 2.3h24A9.6 9.6 0 0 0 43 43.7a9.6 9.6 0 0 0 14.7-12.3zM47.6 1.8h7.9c.3 0 .5.2.5.5v7.9h-3.4a5 5 0 0 1-5-5V1.8zm-45.8.5c0-.3.2-.5.5-.5h7.9v3.4a5 5 0 0 1-5 5H1.8V2.3zm8.4 33.2h-8a.5.5 0 0 1-.4-.6v-7.8h3.4a5 5 0 0 1 5 5v3.4zM52.5 27H56v2.7a9.5 9.5 0 0 0-3.8-1.7l.3-1zM35.8 45.3a7.8 7.8 0 0 1-1-15.5l.6 1A6.8 6.8 0 1 0 41.6 41l.5 1.1a7.8 7.8 0 0 1-6.3 3.1zm1.5-12.6l2 1.1a5 5 0 1 1-2-1zm-2-8zm15.1 7.8a5 5 0 1 1-3.4 1.3l1.9-1a5 5 0 0 1 1.5-.3zm0 12.8a7.8 7.8 0 0 1-7.3-10.6l1.2-.1a6.8 6.8 0 1 0 6.5-3.9l.7-1a7.8 7.8 0 0 1-1 15.6z" />
        <path d="M36.2 36.9V35c.4 0 .6.2 1 .4.2 0 .4 0 .6-.3.1-.2.2-.5 0-.7-.4-.4-1-.5-1.6-.5v-.2c0-.2-.2-.3-.3-.3-.2 0-.3.1-.3.3v.3c-1.2.1-2 .7-2 1.9s.9 1.6 2 2v2c-.6-.1-.8-.3-1.3-.7-.2-.2-.5-.1-.7.1-.2.4-.2.7 0 1 .4.4 1.2.7 2 .7v.3c0 .2.1.3.3.3.1 0 .3-.1.3-.3v-.3c1-.2 1.9-.8 1.9-2.1 0-1.2-.8-1.7-1.9-2.1zm-.6-.2l-.5-.3c-.2-.2-.3-.3-.3-.5s.1-.4.3-.5l.5-.2v1.5zm.6 3.2v-1.7c.6.3.7.6.7 1s-.3.6-.7.7zM50.9 36.9V35c.3 0 .5.2 1 .4.1 0 .4 0 .5-.3.2-.2.2-.5 0-.7-.4-.4-1-.5-1.5-.5v-.2c0-.2-.2-.3-.4-.3s-.3.1-.3.3v.3c-1.2.1-2 .7-2 1.9s1 1.6 2 2v2c-.5-.1-.8-.3-1.3-.7h-.5l-.2.1c-.2.4-.2.7 0 1 .4.4 1.2.7 2 .7v.3c0 .2.2.3.3.3.2 0 .4-.1.4-.3v-.3c1-.2 1.8-.8 1.8-2.1 0-1.2-.8-1.7-1.8-2.1zm-.6-.2c-.6-.3-.8-.5-.8-.8 0-.5.3-.7.8-.7v1.5zm.5 3.2v-1.7c.6.3.7.6.7 1s-.3.6-.7.7zM43.5 24.4v-1.7c.4 0 .6.1 1 .3.2.1.5 0 .6-.2.1-.2.2-.6 0-.8-.4-.3-1-.4-1.6-.4v-.3c0-.1-.1-.2-.3-.2-.2 0-.3 0-.3.2v.3c-1.2.2-2 .8-2 2 0 1.1 1 1.5 2 2v1.9c-.6 0-.8-.3-1.3-.6-.2-.2-.5-.1-.7.1-.2.3-.2.6 0 .9.4.5 1.2.8 2 .8v.3c0 .1.1.2.3.2.2 0 .3 0 .3-.2v-.3c1-.2 2-.8 2-2.1 0-1.2-.8-1.8-2-2.2zm-.6-.2c-.5-.2-.7-.4-.7-.8s.3-.6.7-.7v1.5zm.6 3.3v-1.7c.6.2.7.5.7.9s-.3.7-.7.8zM29.4 18.1v-1.7c.4 0 .6 0 1 .3.2 0 .4 0 .6-.2 0-.2.2-.6 0-.8-.5-.3-1.1-.4-1.6-.4V15l-.3-.2c-.2 0-.4 0-.4.2v.3c-1.1.1-1.9.8-1.9 2 0 1.1.9 1.5 2 2v1.9c-.6 0-.9-.3-1.3-.7-.3-.2-.5 0-.7.2-.2.3-.2.6 0 .8.4.6 1.2.8 2 .8v.3c0 .2 0 .3.3.3.1 0 .3-.1.3-.3v-.3c1-.1 1.9-.8 1.9-2 0-1.3-.8-1.8-2-2.2zm-.6-.2c-.6-.2-.8-.4-.8-.8s.4-.6.8-.7v1.5zm.5 3.3v-1.8c.6.3.8.6.8 1s-.3.7-.8.8z" />
      </g>
    </svg>
  );
}

export function Pasta(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M76.6 81.6c1 0 2.1-1 2.1-2.1a15 15 0 0 0-15-15 2.1 2.1 0 1 0 0 4.3c6 0 10.8 4.8 10.8 10.7 0 1 .8 2 2 2z" />
        <path d="M83 81.6c1 0 2.1-1 2.1-2.1 0-11.8-9.5-21.4-21.3-21.4a8.5 8.5 0 0 0-8.5 8.6 2.1 2.1 0 0 0 4.2 0c0-2.4 2-4.3 4.3-4.3a17 17 0 0 1 17 17c0 1.1 1 2.2 2.2 2.2z" />
        <path d="M63.8 71a2.1 2.1 0 1 0 0 4.2c2.3 0 4.3 1.9 4.3 4.3a2.1 2.1 0 0 0 4.2 0c0-4.8-3.8-8.6-8.5-8.6z" />
        <path
          d="M89.4 81.6c1 0 2.1-1 2.1-2.1a27.7 27.7 0 0 0-27.7-27.8h-4.3c-5.9 0-10.6 4.8-10.6 10.7a2.1 2.1 0 0 0 4.2 0c0-3.5 2.9-6.4 6.4-6.4h4.3a23.5 23.5 0 0 1 23.5 23.5c0 1 .8 2 2 2zm-55.5-49c1 0 2.2-1.1 2.2-2.2h10.6c3.4 0 6.5-1.6 8.6-4.3H115a6.4 6.4 0 0 0 0-12.8H55.3c-2-2.7-5.2-4.2-8.6-4.2H36.1a2.1 2.1 0 1 0-4.3 0v21.3c0 1 .8 2.1 2.1 2.1zm12.8-19.3c2.2 0 4 1 5.1 2.6l.3.6.8 1.5v3.2l-.8 1.7-.3.4a6.4 6.4 0 0 1-5 2.8H36V22h8.5a2.1 2.1 0 1 0 0-4.3H36v-4.3h10.6zM27.5 32.5c1 0 2.2-1 2.2-2.1V9a2.1 2.1 0 1 0-4.3 0v21.3c0 1 .8 2.1 2.1 2.1zm0 27.8c1 0 2.2-1.1 2.2-2.2V36.8a2.1 2.1 0 1 0-4.3 0V58c0 1 .8 2.2 2.1 2.2zm-6.4-23.5c-1.2 0-2.1 1-2.1 2.1v21.4a2.1 2.1 0 1 0 4.3 0V38.9c0-1.3-1.1-2.1-2.2-2.1zM10.5 24a2.1 2.1 0 1 0 0 4.3H19v4.9a2.1 2.1 0 1 0 4.3 0V9A2.1 2.1 0 1 0 19 9v2h-8.5a2.1 2.1 0 0 0 0 4.4H19v2h-8.5a2.1 2.1 0 1 0 0 4.4H19v2h-8.5z"
          fillRule="nonzero"
        />
        <path d="M108.4 105a23.5 23.5 0 0 0-23.5-21.3H70a2.1 2.1 0 0 0 0 4.3h15c9.8 0 18.1 7.3 19.2 17H102a17 17 0 0 0-17-14.9H72a2.1 2.1 0 1 0 0 4.3H85c6.3-.1 11.8 4.4 12.8 10.7h-2.1c-1-5-5.5-8.7-10.7-8.6H74.2a2.1 2.1 0 1 0 0 4.3H85c2.8 0 5.3 1.6 6.2 4.3h-3.8c0-1.3-1.1-2.2-2.2-2.2H74.5c-1.2 0-2.2 1-2.2 2.2h-2.1C69.3 73.7 52 62.4 36 62.4h-2.2a21.3 21.3 0 0 0-21.3 21.3v10.7a2.1 2.1 0 1 0 4.3 0V83.7a17 17 0 0 1 17-17h2.2c21.3 0 29.2 19.2 29.8 38.4h-2.1C63 82.2 52.9 68.8 36 68.8a15 15 0 0 0-15 15v2a2.1 2.1 0 1 0 4.3 0v-2c0-6 4.8-10.7 10.7-10.7 19.8 0 23 20.9 23.4 32h-2.1c-.7-24.8-12-30-21.3-30a6.4 6.4 0 0 0-6.4 6.5 2.1 2.1 0 0 0 4.2 0c0-1.3.9-2.1 2.2-2.1 10.8 0 16.6 8.5 17 25.6H51c0-1.3-1-2.2-2.1-2.2H38.2c-1.2 0-2.1 1-2.1 2.2H32a6.4 6.4 0 0 1 6-4.3h10.6a2.1 2.1 0 0 0 0-4.3H38c-5.2 0-9.7 3.5-10.7 8.6h-2.1c1-6.3 6.5-10.8 12.8-10.7h8.5a2.1 2.1 0 0 0 0-4.3H38a17 17 0 0 0-17 15h-1.8c1-9.8 9.4-17.2 19.2-17.1h4.3a2.1 2.1 0 0 0 0-4.3h-4.3a23.5 23.5 0 0 0-23.5 21.4H0l6.4 9.6c3 4.7 8.2 7.6 13.9 7.6h87c5.6 0 10.7-2.7 13.7-7.4l6.4-9.6h-19v-.2z" />
      </g>
    </svg>
  );
}

export function Pizza(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M18.4 86.2a53 53 0 1 1 89.8 2.5l5.7 3.7a59.7 59.7 0 1 0-46 27l-.6-6.8a53.1 53.1 0 0 1-49-26.1v-.3zm77.3 28.1c-7.1 4.1-15 6.5-23.3 6.9l.6 6.8a59.2 59.2 0 0 0 46-26.7l-5.6-3.7a52.6 52.6 0 0 1-17.7 16.7z" />
        <path d="M110.5 95.6l-41.2-27 2.8 49.2a49 49 0 0 0 38.4-22.2zm-24.2 14.2a3.5 3.5 0 0 1-1.7 2.3 3.4 3.4 0 1 1 1.7-2.3zm5.7-12l-.8.3-.6.6c-.6.6-.8.9-1.4.9l-1.2-.9-2.2-4c-1.2.7-2.4 1-3.7 1.2-1.2 0-1.4-.6-1.7-.9-.6-.6-.9-2-.6-3.7.3-2 1.4-3.7 2.8-4.8a7.1 7.1 0 0 1 4-1.2c2 0 4 .6 4.6 1.5.8 1 0 3-1.7 5.4 1.1 1.1 2.5 2.2 2.8 3 .6.7.9 1.2.6 1.5 0 .6-.6.9-1.2 1.1h.3z" />
        <path d="M106.8 35A49.5 49.5 0 1 0 67 109l-2.8-49.3 41.2 27c10-15 11.4-35 1.4-51.7zm-70.2-6l1.7-.6A3.4 3.4 0 0 1 40 35a3.4 3.4 0 0 1-3.4-6zm4 53.2a8.5 8.5 0 0 1-11.7-3.1 8.5 8.5 0 1 1 11.4 2.8l.2.3zm9.9-24.7l-1.1.5-1.5.6c-.8 0-1.4-.9-1.7-1.7l-1.4-6.6-3.4.3c-2.3 0-4-.5-4.3-2-.5-1.1 0-3.4.9-5.4.8-1.4 2.5-4 5.7-5 .9-.4 1.8-.6 2.8-.7 4 0 8 2.9 8.6 4.6.8 2-1.2 4.3-4.6 6.3l2.8 5.6c.3 1.2.6 1.8 0 2.3 0 .6-.8.6-1.7.9h-1.1v.3zm7.1 35L56 93c-1.1 0-2.3-.8-2.8-1.7a3.4 3.4 0 1 1 4.5 1.1zM77 29.8A6.3 6.3 0 1 1 71.3 19a6.3 6.3 0 0 1 6 10.8H77zm14.7 39.5a3.6 3.6 0 1 1-3.4-6.3 3.6 3.6 0 0 1 3.4 6.3zm11.4-17.6l-.5.8-.6 1.2c-.3.8-.6 1.7-1.7 1.7-.3 0-.9 0-1.4-.6a42.7 42.7 0 0 1-5.2-4c-2.8 2.9-5.7 4.6-7 3.4-1-.5-2-2.2-2.6-4.5-.6-2.9-.3-5.7.8-7.7 2-3.4 6.3-4.8 9.4-4.8.9 0 1.7 0 2.3.5 1.7 1.2 1.7 4.3 0 8 2.8.8 5.1 2 6.2 2.8.9.3 1.2.9 1.5 1.5l-.9 1.7h-.3z" />
      </g>
    </svg>
  );
}

export function Presentation(props) {
  return (
    <svg width="53" height="55" viewBox="0 0 53 55" {...props}>
      <g fill="#4A5155" fillRule="evenodd">
        <path
          d="M50.9 4.7H28.6V2.1a2 2 0 0 0-4.2 0v2.6H2.1a2 2 0 0 0-2 2.1v3c0 1.1.9 2 2 2h.5v30.5c0 1.2 1 2.2 2.1 2.2h9.6l-2 7.2a2 2 0 1 0 4 1l1-3.3h18.4l1 3.4a2 2 0 1 0 4-1.1l-2-7.2h9.6c1.1 0 2-1 2-2.2v-13a.8.8 0 1 0-1.5 0v13c0 .3-.2.6-.5.6H28a.8.8 0 1 0 0 1.6h6.3l.9 3.3H17.7l1-3.3h5.7a.8.8 0 1 0 0-1.6H4.7a.5.5 0 0 1-.5-.6V12h44.6v13.6a.8.8 0 1 0 1.6 0V12h.5c1.1 0 2-1 2-2.1v-3c0-1.1-.9-2-2-2zM37 44.5l2.1 7.6a.5.5 0 1 1-.9.2L36 44.5h1zm-20 0l-2.2 7.8a.5.5 0 0 1-1-.2l2.2-7.6h1zM26 2a.5.5 0 0 1 1 0v2.6h-1V2.1zm25.3 7.7c0 .2-.2.5-.4.5H2a.5.5 0 0 1-.4-.5v-3c0-.3.2-.5.4-.5H51c.2 0 .4.2.4.5v3z"
          fillRule="nonzero"
        />
        <path
          d="M43.5 37.6h-1.2v-22c0-.6-.6-1.1-1.2-1.1h-3.5c-.7 0-1.2.5-1.2 1.2v3.7h-3.1c-.7 0-1.2.5-1.2 1.2v3.1H29c-.7 0-1.2.5-1.2 1.2v3h-3.1c-.7 0-1.2.5-1.2 1.1v8.6h-1.3a.8.8 0 1 0 0 1.6h21.3a.8.8 0 1 0 0-1.6zm-15.7 0H25v-8.2h2.7v8.2zm4.3 0h-2.7V25.3H32v12.3zm4.3 0h-2.7V21h2.7v16.6zm4.3 0H38V16h2.7v21.5z"
          fillRule="nonzero"
        />
        <path d="M16.6 16.2h-4.2a.8.8 0 1 0 0 1.6h4.2a.8.8 0 1 0 0-1.6zM18.7 20.1h-8.4a.8.8 0 1 0 0 1.6h8.4a.8.8 0 1 0 0-1.6zM18.7 24h-8.4a.8.8 0 1 0 0 1.7h8.4a.8.8 0 1 0 0-1.6zM18.7 28h-8.4a.8.8 0 1 0 0 1.6h8.4a.8.8 0 1 0 0-1.6z" />
      </g>
    </svg>
  );
}

export function RoboCall(props) {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2 1h512v512H-2z" />
        <path
          d="M424.88 329.1c-26.24 0-51.63-4.26-75.3-11.94a20.85 20.85 0 0 0-21.55 5.12l-33.5 42.03c-60.37-28.8-116.9-83.2-146.98-145.71l41.6-35.41a21.77 21.77 0 0 0 5.12-21.76c-7.9-23.68-11.95-49.07-11.95-75.31A21.3 21.3 0 0 0 161.2 65H87.39C75.87 65 62 70.12 62 86.12 62 284.31 226.9 449 424.88 449c15.15 0 21.12-13.44 21.12-25.17v-73.6a21.3 21.3 0 0 0-21.12-21.12z"
          fill={props.style.color}
        />
        <g transform="translate(250.5 67)" fill={props.style.color}>
          <path d="M47.5 95a47.5 47.5 0 1 1 0-95 47.5 47.5 0 0 1 0 95zm0-17.97a29.53 29.53 0 1 0 0-59.06 29.53 29.53 0 0 0 0 59.06z" />
          <circle cx="48" cy="46.5" r="12.5" />
          <path d="M10.37 21.08l37.8 21.82-4.5 7.8-37.8-21.83z" />
          <path d="M84.3 21.5L46.5 43.32l4.5 7.8 37.8-21.83z" />
          <path d="M52.5 93.5v-44h-9v44z" />
        </g>
        <g transform="translate(365 67)" fill={props.style.color}>
          <path d="M47.5 95a47.5 47.5 0 1 1 0-95 47.5 47.5 0 0 1 0 95zm0-17.97a29.53 29.53 0 1 0 0-59.06 29.53 29.53 0 0 0 0 59.06z" />
          <circle cx="48" cy="46.5" r="12.5" />
          <path d="M10.37 21.08l37.8 21.82-4.5 7.8-37.8-21.83z" />
          <path d="M84.3 21.5L46.5 43.32l4.5 7.8 37.8-21.83z" />
          <path d="M52.5 93.5v-44h-9v44z" />
        </g>
        <path fill={props.style.color} d="M297.5 149h114v13h-114z" />
      </g>
    </svg>
  );
}

export function Rice(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color}>
        <path
          d="M119.5 53.3H8.5c-2 0-3.5 1.5-3.5 3.4a59 59 0 0 0 30.3 51.4c.5.3 1.1.5 1.7.5h54c.6 0 1.1-.2 1.7-.5A59 59 0 0 0 123 56.7c0-1.9-1.5-3.4-3.5-3.4zm-86.2 62.2c1.5 4.1 5.4 6.9 9.7 6.9h42c4.3 0 8.2-2.8 9.7-7H33.3zm75.8-84.7c-2.3-4-6.2-7-10.8-8.1a17.2 17.2 0 0 0-21.6-12.5c-6.5-6.8-19-6.8-25.4 0a17.2 17.2 0 0 0-21.6 12.5A17.2 17.2 0 0 0 18 46.3h92c2.2-5 1.9-10.8-.9-15.5zm-73 1.7a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zm42 0a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7zm13.8 7a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export function StarFilled({ color = "#FFCA00", ...others }) {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" {...others}>
      <path
        d="M19.1 33l-9.4 5a1 1 0 0 1-1.5-1.1L10 26.4c.2-1-.1-2-.9-2.7l-7.6-7.4a1 1 0 0 1 .6-1.7L12.6 13a3 3 0 0 0 2.3-1.6l4.7-9.6a1 1 0 0 1 1.8 0l4.7 9.6a3 3 0 0 0 2.3 1.6l10.5 1.6a1 1 0 0 1 .6 1.7l-7.6 7.4a3 3 0 0 0-1 2.7l1.9 10.5a1 1 0 0 1-1.5 1L22 33a3 3 0 0 0-2.8 0z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Salad(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="nonzero">
        <path d="M60.2 34.1c5.1 0 10 2.2 13.3 6l1-6L67 23.3l4.3-3 4.7 6.8 2-11.3 5 .7L79.4 37c1.2-1.1 2.6-2 4-2.8-.5-2.1-.6-4.3-.2-6.5a20 20 0 0 1 3.7-7.5 30.1 30.1 0 0 0 3.3-6.6l.8-1.7a17 17 0 0 1-2.6-1.5 22 22 0 0 1-2.5-2.5c-1-1.3-2.2-2.5-3.2-3-1.5 0-2.9.6-4 1.5l-3.3 1.5c-1.2.3-2.3.6-3.5.7a8 8 0 0 0-4.3 1.5 16 16 0 0 0-3 5.5c-.4 1.3-1 2.6-1.5 3.8-.7 1.1-1.4 2.2-2.3 3.3a15.6 15.6 0 0 0-2.7 5.7c-.3 2 0 4 .5 5.8h1.7zm2.5 20.1h9.8a12.5 12.5 0 1 0-24.6 0h9.8v-1l-3.5-1.8 2-4.5 4 2 4-2 2 4.5-3.5 1.8v1zm-57-2l.8 2h1.3a20 20 0 0 1 19.8-22.6 20 20 0 0 1 17.6 10.8c2-3.2 4.8-5.6 8.3-7a19 19 0 0 1-.5-7.8V27l-1-3.5c-.4-2.2-1.2-4.3-2.6-6-1.8-1.4-4-2.3-6.2-2.6a34.1 34.1 0 0 1-8-3.5c-1.9-1.3-4-2.2-6.3-2.5-2.3.3-4.5 1.2-6.3 2.5-1.2.8-2.5 1.5-3.8 2-1.4.5-2.8 1-4.2 1.3-2.2.3-4.3 1.2-6 2.5-1.4 1.8-2.3 4-2.6 6.3a34.1 34.1 0 0 1-3.5 8C1.1 33.4.3 35.6 0 38c.3 2.3 1.1 4.4 2.5 6.3.8 1.2 1.4 2.4 2 3.7.5 1.4 1 2.8 1.3 4.3zm82.1-.5c0 .9.3 1.7.8 2.5h8.5a5 5 0 0 0 .8-2.5 5 5 0 1 0-10 0z" />
        <path d="M42.7 54.2a15 15 0 1 0-30.2 0h5.3a10 10 0 1 1 19.6 0h5.3zm65.2 0a15 15 0 0 0-30.1 0h5.3a10 10 0 1 1 19.6 0h5.2z" />
        <path d="M22.6 51.7c0 .9.3 1.7.7 2.5H32a5 5 0 0 0 .7-2.5 5 5 0 0 0-10 0zM126.7 29l-.7-3.8v-4.2c0-2-.3-4.1-1-6a8 8 0 0 0-3.5-2.8 20.6 20.6 0 0 1-5.5-4.5c-1-1.3-2.3-2.5-3.3-2.8-1.4 0-2.8.5-4 1.3l-3.3 1.5c-1.1.3-2.3.6-3.5.7a8 8 0 0 0-4.3 1.5 16 16 0 0 0-3 5.5c-.4 1.3-1 2.6-1.5 3.8L91 22.6a15.6 15.6 0 0 0-2.8 5.7c-.2 1.3-.2 2.6 0 3.8 5.7-1.4 11.8-.2 16.6 3.3l-7.6-11 4-2.8L106 28l2.3-12.6 5 1-2.5 12.6 6.8-4.8 3 4.3-10.8 7.5-.8 3.5a20 20 0 0 1 4 12v2.6h4.8a16 16 0 0 0 3-5.5 40.4 40.4 0 0 1 3.8-7.3c1.4-1.7 2.4-3.7 3-5.8.2-2.1 0-4.2-.8-6.3v-.2zM6.5 89.3a28.9 28.9 0 0 0 28.6 25.1h50.2a28.9 28.9 0 0 0 28.6-25l3.8-30.2H2.5l4 30.1zm97.4-1.2l1.8-14 5 .4-1.8 14c-1.2 11.1-10 19.8-21 21l-.6-5.1c8.5-1 15.3-7.6 16.4-16l.2-.3zm-26.1 16.3h5v5h-5v-5zm-42.7 15h50.2v5H35.1v-5z" />
      </g>
    </svg>
  );
}

export function StarOutline({ color = "#FFCA00", ...others }) {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" {...others}>
      <path
        d="M19.1 33l-9.4 5a1 1 0 0 1-1.5-1.1L10 26.4c.2-1-.1-2-.9-2.7l-7.6-7.4a1 1 0 0 1 .6-1.7L12.6 13a3 3 0 0 0 2.3-1.6l4.7-9.6a1 1 0 0 1 1.8 0l4.7 9.6a3 3 0 0 0 2.3 1.6l10.5 1.6a1 1 0 0 1 .6 1.7l-7.6 7.4a3 3 0 0 0-1 2.7l1.9 10.5a1 1 0 0 1-1.5 1L22 33a3 3 0 0 0-2.8 0z"
        stroke={color}
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Steak(props) {
  return (
    <svg width="128" height="128" viewBox="0 0 128 128" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path
          d="M74.2 57.2a8 8 0 0 1-8-8V47a14.8 14.8 0 0 0-29.5 0v29.9a27.7 27.7 0 0 0 26 28.1 27.3 27.3 0 0 0 28.6-27.2v-3.5a17 17 0 0 0-17-17zm12.6 20.5A22.8 22.8 0 0 1 64 100.4h-1.1a23.4 23.4 0 0 1-21.7-23.6V46.9a10.2 10.2 0 0 1 20.5 0v2.3c0 7 5.6 12.5 12.5 12.5 7 0 12.6 5.6 12.6 12.5v3.5z"
          fillRule="nonzero"
        />
        <path
          d="M74.2 66.3a17 17 0 0 1-17-17v-2.4a5.7 5.7 0 0 0-11.4 0v29.9c-.2 10 7.4 18.3 17.3 19a18.2 18.2 0 0 0 19.1-18.1v-3.5a8 8 0 0 0-8-8zm-21-19.9l3.3 3.3-6.8 6.8-3.3-3.2 6.9-6.9zM48.7 74l9-11.4 3.6 2.8-9 11.4-3.6-2.8zm10.3 14.2l-3.5-2.9 11.4-13.6 3.5 2.9L59 88.2zm12 .8h-4.6v-4.5h4.5V89zm9-6.8h-4.5v-4.5h4.5v4.5zm38.7 45.5V46c-9 1.2-16 9-16 18.1v25h2.4V75.4h4.5v14c2.7 1 4.5 3.6 4.6 6.5v31.8h4.5z"
          fillRule="nonzero"
        />
        <path
          d="M127.7 64A63.7 63.7 0 1 0 9.4 96.7V89a11.4 11.4 0 0 1-4.6-9V43.4h4.6v16h4.5v-16h4.6v16H23v-16h4.6V80c0 3.6-1.7 7-4.6 9v23.9a63.6 63.6 0 0 0 86.5-4.3V96c0-1.3-1-2.3-2.3-2.3h-6.8c-1.2 0-2.3-1-2.3-2.3V64A22.8 22.8 0 0 1 121 41.2c1.2 0 2.3 1 2.3 2.3v44.1a64 64 0 0 0 4.5-23.6zM73.1 36.7h4.6v4.5H73v-4.5zm22.8 41a31.9 31.9 0 1 1-63.8-.9V46.9a19.3 19.3 0 0 1 38.7 0v2.3c0 1.9 1.6 3.4 3.4 3.4 12 0 21.7 9.7 21.7 21.6v3.5zM79.9 48v-4.6h4.6v4.6h-4.6zm16-6.9v9.1h-4.6V43L86 41l1.5-4.3 3.2 1c-.6-2-1.8-3.9-3.5-5.3L83.8 36l-3.2-3.2 2.4-2.4c-1-.4-2-.5-3-.5H73v-4.6h6l-1.3-3.8L82 20l1.9 5.8a16 16 0 0 1 7.3 4.2l2.7-3.8 3.7 2.6-3.6 5.1a16 16 0 0 1 1.8 7.2z"
          fillRule="nonzero"
        />
        <path d="M19.6 85.8A6.7 6.7 0 0 0 23 80V64H9.4v16c0 2.4 1.3 4.7 3.5 5.8.8.5 1.3 1.5 1 2.5v39.4h4.6V88.2c-.2-1 .3-1.9 1-2.4z" />
      </g>
    </svg>
  );
}

export function Computer(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M17.237,3.056H2.93c-0.694,0-1.263,0.568-1.263,1.263v8.837c0,0.694,0.568,1.263,1.263,1.263h4.629v0.879c-0.015,0.086-0.183,0.306-0.273,0.423c-0.223,0.293-0.455,0.592-0.293,0.92c0.07,0.139,0.226,0.303,0.577,0.303h4.819c0.208,0,0.696,0,0.862-0.379c0.162-0.37-0.124-0.682-0.374-0.955c-0.089-0.097-0.231-0.252-0.268-0.328v-0.862h4.629c0.694,0,1.263-0.568,1.263-1.263V4.319C18.5,3.625,17.932,3.056,17.237,3.056 M8.053,16.102C8.232,15.862,8.4,15.597,8.4,15.309v-0.89h3.366v0.89c0,0.303,0.211,0.562,0.419,0.793H8.053z M17.658,13.156c0,0.228-0.193,0.421-0.421,0.421H2.93c-0.228,0-0.421-0.193-0.421-0.421v-1.263h15.149V13.156z M17.658,11.052H2.509V4.319c0-0.228,0.193-0.421,0.421-0.421h14.308c0.228,0,0.421,0.193,0.421,0.421V11.052z"></path>
      </g>
    </svg>
  );
}
export function ClientsIcon(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M15.573,11.624c0.568-0.478,0.947-1.219,0.947-2.019c0-1.37-1.108-2.569-2.371-2.569s-2.371,1.2-2.371,2.569c0,0.8,0.379,1.542,0.946,2.019c-0.253,0.089-0.496,0.2-0.728,0.332c-0.743-0.898-1.745-1.573-2.891-1.911c0.877-0.61,1.486-1.666,1.486-2.812c0-1.79-1.479-3.359-3.162-3.359S4.269,5.443,4.269,7.233c0,1.146,0.608,2.202,1.486,2.812c-2.454,0.725-4.252,2.998-4.252,5.685c0,0.218,0.178,0.396,0.395,0.396h16.203c0.218,0,0.396-0.178,0.396-0.396C18.497,13.831,17.273,12.216,15.573,11.624 M12.568,9.605c0-0.822,0.689-1.779,1.581-1.779s1.58,0.957,1.58,1.779s-0.688,1.779-1.58,1.779S12.568,10.427,12.568,9.605 M5.06,7.233c0-1.213,1.014-2.569,2.371-2.569c1.358,0,2.371,1.355,2.371,2.569S8.789,9.802,7.431,9.802C6.073,9.802,5.06,8.447,5.06,7.233 M2.309,15.335c0.202-2.649,2.423-4.742,5.122-4.742s4.921,2.093,5.122,4.742H2.309z M13.346,15.335c-0.067-0.997-0.382-1.928-0.882-2.732c0.502-0.271,1.075-0.429,1.686-0.429c1.828,0,3.338,1.385,3.535,3.161H13.346z"></path>
      </g>
    </svg>
  );
}
export function Notification(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z"></path>
      </g>
    </svg>
  );
}
export function Diagram(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M11.709,7.438H8.292c-0.234,0-0.427,0.192-0.427,0.427v8.542c0,0.234,0.192,0.427,0.427,0.427h3.417c0.233,0,0.426-0.192,0.426-0.427V7.865C12.135,7.63,11.942,7.438,11.709,7.438 M11.282,15.979H8.719V8.292h2.563V15.979zM6.156,11.709H2.74c-0.235,0-0.427,0.191-0.427,0.426v4.271c0,0.234,0.192,0.427,0.427,0.427h3.417c0.235,0,0.427-0.192,0.427-0.427v-4.271C6.583,11.9,6.391,11.709,6.156,11.709 M5.729,15.979H3.167v-3.416h2.562V15.979zM17.261,3.167h-3.417c-0.235,0-0.427,0.192-0.427,0.427v12.812c0,0.234,0.191,0.427,0.427,0.427h3.417c0.234,0,0.427-0.192,0.427-0.427V3.594C17.688,3.359,17.495,3.167,17.261,3.167 M16.833,15.979h-2.562V4.021h2.562V15.979z"></path>
      </g>
    </svg>
  );
}
export function Molecule(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M14.68,12.621c-0.9,0-1.702,0.43-2.216,1.09l-4.549-2.637c0.284-0.691,0.284-1.457,0-2.146l4.549-2.638c0.514,0.661,1.315,1.09,2.216,1.09c1.549,0,2.809-1.26,2.809-2.808c0-1.548-1.26-2.809-2.809-2.809c-1.548,0-2.808,1.26-2.808,2.809c0,0.38,0.076,0.741,0.214,1.073l-4.55,2.638c-0.515-0.661-1.316-1.09-2.217-1.09c-1.548,0-2.808,1.26-2.808,2.809s1.26,2.808,2.808,2.808c0.9,0,1.702-0.43,2.217-1.09l4.55,2.637c-0.138,0.332-0.214,0.693-0.214,1.074c0,1.549,1.26,2.809,2.808,2.809c1.549,0,2.809-1.26,2.809-2.809S16.229,12.621,14.68,12.621M14.68,2.512c1.136,0,2.06,0.923,2.06,2.06S15.815,6.63,14.68,6.63s-2.059-0.923-2.059-2.059S13.544,2.512,14.68,2.512M5.319,12.061c-1.136,0-2.06-0.924-2.06-2.06s0.923-2.059,2.06-2.059c1.135,0,2.06,0.923,2.06,2.059S6.454,12.061,5.319,12.061M14.68,17.488c-1.136,0-2.059-0.922-2.059-2.059s0.923-2.061,2.059-2.061s2.06,0.924,2.06,2.061S15.815,17.488,14.68,17.488"></path>
      </g>
    </svg>
  );
}
export function Heart(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M9.719,17.073l-6.562-6.51c-0.27-0.268-0.504-0.567-0.696-0.888C1.385,7.89,1.67,5.613,3.155,4.14c0.864-0.856,2.012-1.329,3.233-1.329c1.924,0,3.115,1.12,3.612,1.752c0.499-0.634,1.689-1.752,3.612-1.752c1.221,0,2.369,0.472,3.233,1.329c1.484,1.473,1.771,3.75,0.693,5.537c-0.19,0.32-0.425,0.618-0.695,0.887l-6.562,6.51C10.125,17.229,9.875,17.229,9.719,17.073 M6.388,3.61C5.379,3.61,4.431,4,3.717,4.707C2.495,5.92,2.259,7.794,3.145,9.265c0.158,0.265,0.351,0.51,0.574,0.731L10,16.228l6.281-6.232c0.224-0.221,0.416-0.466,0.573-0.729c0.887-1.472,0.651-3.346-0.571-4.56C15.57,4,14.621,3.61,13.612,3.61c-1.43,0-2.639,0.786-3.268,1.863c-0.154,0.264-0.536,0.264-0.69,0C9.029,4.397,7.82,3.61,6.388,3.61"></path>
      </g>
    </svg>
  );
}
export function Check(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03"></path>
      </g>
    </svg>
  );
}

export function TwoArrows(props) {
  return (
    <svg width="80" height="98" class="svg-icon" viewBox="0 0 20 20" {...props}>
      <g fill={props.color} fillRule="evenodd">
        <path d="M12.319,5.792L8.836,2.328C8.589,2.08,8.269,2.295,8.269,2.573v1.534C8.115,4.091,7.937,4.084,7.783,4.084c-2.592,0-4.7,2.097-4.7,4.676c0,1.749,0.968,3.337,2.528,4.146c0.352,0.194,0.651-0.257,0.424-0.529c-0.415-0.492-0.643-1.118-0.643-1.762c0-1.514,1.261-2.747,2.787-2.747c0.029,0,0.06,0,0.09,0.002v1.632c0,0.335,0.378,0.435,0.568,0.245l3.483-3.464C12.455,6.147,12.455,5.928,12.319,5.792 M8.938,8.67V7.554c0-0.411-0.528-0.377-0.781-0.377c-1.906,0-3.457,1.542-3.457,3.438c0,0.271,0.033,0.542,0.097,0.805C4.149,10.7,3.775,9.762,3.775,8.76c0-2.197,1.798-3.985,4.008-3.985c0.251,0,0.501,0.023,0.744,0.069c0.212,0.039,0.412-0.124,0.412-0.34v-1.1l2.646,2.633L8.938,8.67z M14.389,7.107c-0.34-0.18-0.662,0.244-0.424,0.529c0.416,0.493,0.644,1.118,0.644,1.762c0,1.515-1.272,2.747-2.798,2.747c-0.029,0-0.061,0-0.089-0.002v-1.631c0-0.354-0.382-0.419-0.558-0.246l-3.482,3.465c-0.136,0.136-0.136,0.355,0,0.49l3.482,3.465c0.189,0.186,0.568,0.096,0.568-0.245v-1.533c0.153,0.016,0.331,0.022,0.484,0.022c2.592,0,4.7-2.098,4.7-4.677C16.917,9.506,15.948,7.917,14.389,7.107 M12.217,15.238c-0.251,0-0.501-0.022-0.743-0.069c-0.212-0.039-0.411,0.125-0.411,0.341v1.101l-2.646-2.634l2.646-2.633v1.116c0,0.174,0.126,0.318,0.295,0.343c0.158,0.024,0.318,0.034,0.486,0.034c1.905,0,3.456-1.542,3.456-3.438c0-0.271-0.032-0.541-0.097-0.804c0.648,0.719,1.022,1.659,1.022,2.66C16.226,13.451,14.428,15.238,12.217,15.238"></path>
      </g>
    </svg>
  );
}

export function QueueIcon(props) {
  return (
    <svg
      width="750"
      height="750"
      class="svg-icon"
      viewBox="0 0 750 750"
      {...props}
    >
      <g>
        <defs>
          <path
            d="M20.158 3.553l-.763 3.298C31.353 8.348 40.26 8.913 46.12 8.546c5.86-.368 13.461-1.773 22.805-4.214V.806C60.14 3.51 52.782 5.082 46.851 5.518c-5.932.437-14.83-.218-26.693-1.965z"
            id="a"
          />
          <path
            d="M12.81 31.289c-4.977.01-8.224-.164-9.739-.523-1.515-.36-2.499-1.189-2.951-2.487-.22-8.657.008-14.01.682-16.056 1.011-3.07 10-10.47 20.734-11.942 7.157-.98 13.393 1.514 18.709 7.483L12.81 31.289z"
            id="b"
          />
          <path
            d="M12.888 31.19c6.238 2.635 9.865 3.952 10.879 3.952 1.521 0 4.617-10.815 11.709-12.001 7.091-1.186 15.611 7.23 16.534 4.55.147-.426-2.752-3.777-6.002-5.636-2.833-1.622-5.825-1.813-10.532-1.813-4.513 0-9.867 1.538-15.125 4.78-1.729 1.067-4.217 3.123-7.463 6.169z"
            id="c"
          />
          <path id="d" d="M21.976 115.062v178.447l33.87-19.02V95.363z" />
          <path
            id="e"
            d="M0 81.806v6.333l56.602 33.71L199.88 38.425V32.33L138.077 4z"
          />
          <path
            id="f"
            d="M.228 52.602l38.768-24.96 2.448 3.742L2.068 56.868z"
          />
          <path
            d="M.071 12.464l30.5 18.124V20.482C22.508 11.736 16.023 6.936 11.116 6.084 6.21 5.232 2.528 7.358.071 12.464z"
            id="g"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle fill="#009688" cx="375" cy="375" r="375" />
          <path
            d="M491.088 731.16L602.64 628.826c-61.645-34.078-113.399-65.315-155.262-93.713-41.95-28.457-114.82-81.775-218.616-159.958L44.445 551.18C109.11 672.253 235.223 749.5 375 749.5c39.886 0 78.928-6.236 116.088-18.34z"
            stroke="#979797"
            fill-opacity=".18"
            fill="#000"
          />
          <path
            d="M381.89 468.176l-42.061-28.126-156.365 82.082 55.628 33.625 142.797-87.58z"
            fill-opacity=".3"
            fill="#000"
          />
          <path
            d="M252.493 283.71l9.833-6.22c-5.473-12.826-9.525-25.622-12.157-38.39-1.972-9.566-2.735-23.812-3.948-42.198-.406-6.15-.986-18.382-1.922-19.664-.935-1.283-2.589-3.327-5.049-4.386-1.64-.706-4.106-1.323-7.399-1.852.544 14.182 1.17 25.63 1.876 34.346.708 8.715 1.02 21.776.937 39.185.992 7.63 2.52 13.722 4.586 18.28 2.066 4.557 6.48 11.524 13.243 20.9z"
            fill="#EC9C01"
          />
          <path
            d="M178.752 452.676c-.475 8.366-.475 13.386 0 15.06.475 1.674 8.47 7.603 23.986 17.788l6.678-24.606-22.402-16.844-8.262 8.602z"
            fill="#E32A2A"
          />
          <path
            d="M192.927 271.599c-4.197 17.237-6.295 31.274-6.295 42.11 0 10.835-.997 21.65-2.991 32.446-4.613 19.452-7.777 35.759-9.494 48.923-2.575 19.745 2.446 56.11 2.938 57.556.491 1.446 5.597 2.154 9.547 2.377 2.633.148 6.759-1.561 12.378-5.128l44.275-146.564-1.347-34.233c-7.668 2.594-15.752 3.89-24.253 3.89s-16.754-.459-24.758-1.377z"
            fill="#803530"
          />
          <g transform="translate(173 267)">
            <use fill="#803530" />
            <use fill-opacity=".3" fill="#000" />
          </g>
          <path
            d="M176.595 188.67c6.7-5.91 11.791-9.857 15.274-11.839 4.746-2.7 13.2-4.093 17.816-5.28 14.552-3.738 27.482-1.064 30.813 5.28 1.603 3.053 3.037 20.42 2.326 50.637-.183 7.765-.579 21.237-1.19 40.415-11.876 3.363-20.714 5.294-26.514 5.794-5.8.5-13.55-.082-23.25-1.747 2.874-10.959 2.874-20.857 0-29.695-2.875-8.837-7.966-26.692-15.275-53.564z"
            fill="#FFB90E"
          />
          <path
            d="M203.936 175.764c.73-3.53 1.094-6.499 1.094-8.908 0-2.41-.365-6.109-1.094-11.097l28.306 11.279-2.098 10.126c-5.436 2.483-9.805 3.597-13.108 3.342-3.303-.256-7.67-1.836-13.1-4.742z"
            fill="#E1C8AC"
          />
          <path
            d="M205.318 270.755c1.894-1.78 3.389-3.611 4.485-5.497 1.645-2.828 2.199-5.798 2.381-6.308.182-.51 5.17.987 6.474 1.599.643.302 3.384 2.285 7.23 6.265 1.344 1.39 2.748 3.82 3.61 5.344.863 1.524 4.057 12.437 3.82 13.488-.236 1.05-2.347 1.417-2.73.77-.254-.43-.99-2.147-2.208-5.15.558 3.996.743 6.133.555 6.41-.283.418-7.3 1.66-9.098 1.213-.857-.213-1.344-3.124-5.754-9.699-1.168-1.741-4.09-4.553-8.765-8.435z"
            fill="#E5C3A0"
          />
          <path
            d="M196.836 122.65c-.2 11.138.486 19.073 2.06 23.807 1.574 4.734 5.404 10.38 11.49 16.94 4.27 4.76 7.882 7.33 10.837 7.708 4.431.568 10.315-.768 11.445-1.801 1.13-1.033 3.475-2.257 4.629-9.919.77-5.108 1.055-13.937.856-26.488-.028-3.981-.627-7.397-1.799-10.247-1.171-2.85-4.152-6.509-8.942-10.976-6.616-.242-11.906.35-15.872 1.773-3.966 1.423-8.867 4.491-14.704 9.203z"
            fill="#F7DCBF"
          />
          <path
            d="M196.611 123.985c.29 12.492 1.106 21.019 2.45 25.581 2.016 6.844 5.193 14.042 6.245 14.735 1.052.693 2.515-6.328 2.194-7.493-.32-1.165-3.203-1.315-4.113-3.171-.35-.717-.648-1.907-.89-3.57-.176-1.206-.357-2.885 0-3.922.357-1.037 3.67-1.902 5.003-.983 1.333.92 1.842 5.397 2.25 5.845.408.448 4.251-.99 4.251-1.44v-6.844l-17.39-18.738z"
            fill="#4E0F00"
          />
          <g fill="#4E0F00">
            <path d="M235.142 138.205v2.669l3.03-2.372v-2.373zM230.977 139.134v2.85c-1.227-.3-2.301-.66-3.223-1.078-1.383-.628-7.772-.463-6.322-1.772.967-.872 4.148-.872 9.545 0z" />
          </g>
          <g transform="translate(196 110)">
            <use fill="#F14040" x />
            <use fill-opacity=".15" fill="#000" />
          </g>
          <path
            d="M209 141.093c-.568-11.71-.568-18.59 0-20.636.852-3.07 8.877-8.652 18.398-7.266 6.348.925 10.085 4.147 11.21 9.667l1.285 9.69c-3.874-1.272-9.022-1.272-15.446 0-6.424 1.272-11.573 4.12-15.447 8.545z"
            fill="#F14040"
          />
          <g transform="translate(196 110)">
            <use fill="#F14040" />
            <use fill-opacity=".15" fill="#000" x />
          </g>
          <path
            d="M217.181 185.276c0-1.027.89-4.228 3.817-4.59 2.927-.363 4.826 1.511 5.203 2.401.162.383-.274 1.19-.6 2.385-.118.439-.252 1.213-.4 2.324 1.55 4.184 3.354 11.981 5.409 23.39 3.082 17.114 7.059 49.645 6.1 52.084-.96 2.439-6.635 7.718-7.384 8.182-.75.465-6.84-8.707-7.284-10.62-.214-.926 1.194-13.66.813-37.953-.07-4.401-.1-11.868-.813-21.102-.19-2.449-.537-6.71-1.044-12.784-.6-.83-1.071-1.385-1.414-1.662-1.315-1.066-2.403-1.562-2.403-2.055z"
            fill="#E42928"
          />
          <path
            d="M174.87 190.094c-.677 1.05-8.284 23.1-10.837 34.702-2.552 11.603-5.927 27.922-3.454 30.254 1.072 1.01 8.125 4.093 20.495 7.886 6.664 2.044 22.533 6.661 24.579 6.661 2.046 0 3.272-2.86 4.25-4.938.98-2.077 2.863-5.256 1.274-6.785-1.059-1.02-10.548-5.735-28.468-14.146 3.108-8.545 5.546-15.737 7.312-21.575 2.649-8.759 7.113-25.375 7.113-26.869 0-1.494-2.212-8.324-9.286-9.808-7.075-1.485-12.303 3.567-12.979 4.618z"
            fill="#FEC552"
          />
          <g fill="#FEC64D">
            <path d="M204.618 168.99c-.54-.421-2.46 2.56-3.347 3.84-.591.852-.909 1.64-.952 2.36 1.63 3.426 3.243 6 4.84 7.719 2.393 2.58 10.267 5.682 10.559 5.682.292 0-.555-2.902.438-4.755.992-1.853 2.196-3.149 2.637-3.568.442-.418-5.264-1.226-7.699-3.246-2.434-2.02-5.936-7.61-6.476-8.032zM223.622 179.577c-.461.78 1.509.271 2.738 1.648 1.23 1.377 1.75 6.703 2.36 6.12.611-.585 3.047-9.932 3.304-12.727.256-2.795.256-3.389-.338-3.603-.593-.214-1.636 1.532-3.41 3.603-1.773 2.07-4.193 4.18-4.654 4.96z" />
          </g>
          <path
            d="M222.925 162.301a8.386 8.386 0 004.803 1.48c1.744 0 3.345-.122 4.802-.367"
            stroke="#DFB9B9"
          />
          <path
            d="M232.476 144c.432 2.66.772 4.623 1.022 5.887.374 1.897 2.876 4.045 2.139 5.035-.492.66-2.371.944-5.637.852"
            stroke-opacity=".15"
            stroke="#000"
            stroke-linecap="square"
            stroke-linejoin="round"
          />
          <g>
            <path
              fill="#E32A2A"
              d="M227.594 362.062v178.447l23.252-14.211V348.73z"
            />
            <path
              fill="#FFB90E"
              d="M250 341.33v178.447l87.235-49.774V300.392z"
            />
            <g transform="matrix(-1 0 0 1 249.822 247)">
              <use fill="#E32A2A" />
              <use fill-opacity=".15" fill="#000" />
            </g>
            <g transform="translate(172 247)">
              <use fill="#FF611C" />
              <use fill-opacity=".15" fill="#000" />
            </g>
            <path
              d="M313.602 247.283L173.491 328.02a1 1 0 00-.007 1.73l54.172 31.761a1 1 0 001.004.005l141.785-81.46a1 1 0 00-.013-1.741l-55.845-31.041a1 1 0 00-.985.008z"
              stroke="#FF611C"
              fill="#FF611C"
            />
          </g>
          <g>
            <path
              d="M186.155 316.279v9.65a1 1 0 00.491.861l43.73 25.882a1 1 0 001.023-.003l47.813-28.597a1 1 0 00.487-.858v-8.78a1 1 0 00-1.513-.858l-47.298 28.21-43.212-26.36a1 1 0 00-1.52.853z"
              fill="#1D3243"
            />
            <path
              d="M235.42 288.167l43.568 24.661a1 1 0 01.004 1.739l-47.49 27.146a1 1 0 01-1.002-.006l-43.988-25.82a1 1 0 01.022-1.737l46.932-25.992a2 2 0 011.954.01z"
              fill="#3B637A"
            />
            <path
              fill="#2D5167"
              d="M209.004 295.417l9.139-5.84 12.76 34.232-8.763 6.118z"
            />
            <path
              fill="#183C4C"
              d="M222.144 330.11l-12.683-8.137-7.303-22.486 7.303-4.123z"
            />
            <path
              fill="#D8D8D8"
              d="M251.886 264.24l2.481 2.857-44.994 28.267-2.483-3.644z"
            />
            <g transform="translate(168 264)">
              <use fill="#D8D8D8" />
              <use fill-opacity=".15" fill="#000" />
            </g>
          </g>
          <g>
            <g transform="translate(227 288)">
              <use fill="#D8D8D8" />
              <use fill-opacity=".2" fill="#000" />
            </g>
            <path
              fill="#D8D8D8"
              d="M257.284 318.731l12.13-9.146v-9.611l-12.13 7.76zM268.943 300.174c-6.525-6.176-11.53-9.9-15.016-11.172-3.486-1.272-7.581-1.072-12.286.6l-9.627 5.319 24.942 12.988 11.987-7.735z"
            />
          </g>
          <g>
            <path
              d="M350.952 531.67c11.265 1.019 18.767 1.019 22.507 0 5.61-1.527 8.282-6.525 8.942-8.561.659-2.037-.445-5.636.659-6.773 1.104-1.137 7.895.695 8.703-1.248.808-1.943 3.735-3.569-7.107-10.417-7.228-4.566-19.1-10.392-35.614-17.48l1.91 44.48z"
              fill-opacity=".25"
              fill="#000"
            />
            <path
              d="M364.769 243.044c-.745.326-1.118-2.589-1.118-8.743s.95-14.92 2.849-26.301l-37.5 3.927c.255 10.09 1.066 17.234 2.433 21.434 2.051 6.3 3.574 9.214 8.31 14.3 3.157 3.392 11.499 1.852 25.026-4.617z"
              fill="#E5C3A0"
            />
            <path
              d="M347.221 237.445c1.128.765 4.57.765 7.145.765 2.576 0 6.737-.789 8.16-1.817 1.425-1.029 2.78-6.4 4.131-10.252 1.35-3.85 2.451-8.922 3.013-11.677.561-2.755.561-5.106.561-7.724 0-1.745-.187-3.366-.561-4.864-1.53-3.676-3.331-6.469-5.403-8.377-3.108-2.863-7.661-5.025-9.9-5.91-2.24-.884-5.405-1.279-8.264-1.279-2.859 0-6.207.28-8.63.951-2.425.671-7.602 2.878-10.82 5.28-2.145 1.6-4.458 4.258-6.94 7.972-1.767 2.44-2.538 3.661-2.312 3.661.339 0 3.855-2.655 4.45-2.298.31.187-.944 4.07-1.424 7.754-.441 3.395.378 6.546.482 7.175.113.68.379-.541 1.274-3.455.257-.835.257-4.576 1.105-2.845.33.672 1.024 1.47 1.428 3.277.29 1.297.24 5.34.664 5.82.426.478 3.399-1.802 3.763-1.293.176.246 1.06 1.51 1.773 4.15.269.997.079 3.331.482 3.682.403.352 3.557-1.285 3.557-1.818 0-.532-2.242-2.326-2.124-3.83.05-.632.83-4.004 2.736-4.004 1.905 0 3.927 1.756 4.721 4.004.794 2.247-.366 6.262 0 8.32.367 2.057 2.127 3.79 3.088 4.975 2.116 2.612 3.328 3.306 3.845 3.657z"
              fill="#160700"
            />
            <path
              d="M313.149 248.94c-.5-1.122-.573-11.81.498-13.243 1.072-1.432 3.445-1.892 5.447-2.184 2.003-.292 2.249.424 3.882 0 1.633-.425 2.414-3.424 3.132-5.918.717-2.495.81-9.893 2.322-9.092 1.512.802 1.09 7.491.763 11.203-.326 3.711-2.923 8.449-2.56 9.26.362.81 3.048.81 4.43 0 1.381-.811 1.291-3.073 2.771-3.813 1.48-.74 6.465-.64 6.158.544-.307 1.185-5.08 4.189-7.294 7.33-2.214 3.141-3.505 8.73-5.773 9.02-2.268.29-3.005-2.392-5.582-3.106-2.578-.714-7.696 1.123-8.194 0z"
              fill="#E4CCB3"
            />
            <path
              d="M370.902 311.787c6.427.802 10.821 1.05 13.182.745 3.542-.459 3.542-.459 6.372-3.275 2.83-2.817.437-25.408-2.409-44.192-1.897-12.523-7.263-19.277-16.098-20.263l-1.047 66.985zM338.469 250.509c2.688-3.259 4.68-5.419 5.975-6.48 1.295-1.062 2.94-1.989 4.936-2.78 3.95-.772 6.921-1.266 8.912-1.481 2.987-.323 7.615-.764 8.331-.382.478.255.478 1.933 0 5.036-2 6.004-4.776 9.334-8.33 9.991-3.555.657-10.163-.645-19.824-3.904z"
              fill="#0F3975"
            />
            <path
              d="M348.828 523.489c2.095 2.447 5.095 3.909 9.001 4.386 3.906.477 9.366.049 16.38-1.285-.229-4.73-.229-8.89 0-12.485.227-3.595.227-8.908 0-15.941-17.67-9.878-30.322-15.108-37.958-15.69-7.636-.581-13.937 3.164-18.901 11.238l31.478 29.777z"
              fill="#011935"
            />
            <path
              d="M374.485 503.217c3.095-3.213-.27-26.874 0-56.915.18-20.027.048-49.74-.394-89.141-17.113-1.32-26.572 5.135-28.38 19.364-1.807 14.23 3.571 44.31 16.135 90.243 6.363 26.441 10.576 38.591 12.639 36.45z"
              fill="#062953"
            />
            <path
              d="M319.37 362.772c5.882 38.789 11.313 66.289 16.294 82.5 4.981 16.212 6.76 33.727 5.337 52.545 3.23 4.447 6.846 7.483 10.85 9.107 4.004 1.624 9.374 2.234 16.11 1.83 4.425 0 5.649-12.83 3.673-38.493-1.143-14.848-4.266-33.722-5.96-58.02-.811-11.616-.811-31.932 0-60.949l-46.303 11.48z"
              fill="#123F82"
            />
            <path
              d="M316.657 365.545c-.93-1.412-.93-19.248 0-53.507l-1.282-49.047c-.416-4.06 9.952-9.322 31.105-15.789 31.729-9.7 36.816 5.81 37.658 8.867.841 3.057-.845 18.71-2.99 26.894-4.779 18.242-5.628 34.97-6.406 46.361-1.095 16.033-.389 28.106 2.12 36.221-13.352 4.727-23.479 7.09-30.382 7.09-10.355 0-28.428-4.972-29.823-7.09z"
              fill="#1652A8"
            />
            <path
              d="M296.618 298.412c-.702-2.027 1.311-12.96 4.09-21.998 2.779-9.04 9.398-26.11 11.246-27.886 1.848-1.777 5.948-1.042 9.565 0 2.412.694 4.539 2.24 6.382 4.636-20.387 31.517-30.815 46.6-31.283 45.248z"
              fill="#103E79"
            />
            <path
              d="M323.64 253.658c2.937-1.648 7.18-2.335 11.613 0s8.124 6.032 7.053 10.589c-1.072 4.557-10.261 21.51-16.75 30.503-6.489 8.993-15.924 20.866-20.315 20.866-4.391 0-4.391-1.453-5.9-4.684-1.508-3.23-3.635-10.107-2.756-13.856.88-3.748 7.115-14.863 11.65-22.139 4.533-7.276 12.47-19.63 15.406-21.279z"
              fill="#1C63CD"
            />
          </g>
          <g>
            <path
              d="M270.233 472.059l11.297-7.007a3 3 0 013.102-.037l69.219 40.719a3 3 0 011.479 2.586v54.704l-12.688 8.393-72.409-99.358z"
              fill="#56331F"
            />
            <path
              d="M269.182 529.037v-55.438a2 2 0 013.023-1.719l68.363 40.711a4 4 0 011.953 3.437v55.506l-73.34-42.497z"
              fill="#6D4426"
            />
            <path
              d="M332.247 503.985l5.428 4.066a1 1 0 001.316-.103l3.61-3.706a1 1 0 00-.147-1.52l-5.505-3.816a1 1 0 00-1.269.107l-3.533 3.457a1 1 0 00.1 1.515zM319.247 494.985l5.428 4.066a1 1 0 001.316-.103l3.61-3.706a1 1 0 00-.147-1.52l-5.505-3.816a1 1 0 00-1.269.107l-3.533 3.457a1 1 0 00.1 1.515zM295.247 481.985l5.428 4.066a1 1 0 001.316-.103l3.61-3.706a1 1 0 00-.147-1.52l-5.505-3.816a1 1 0 00-1.269.107l-3.533 3.457a1 1 0 00.1 1.515zM282.247 473.985l5.428 4.066a1 1 0 001.316-.103l3.61-3.706a1 1 0 00-.147-1.52l-5.505-3.816a1 1 0 00-1.269.107l-3.533 3.457a1 1 0 00.1 1.515z"
              fill="#F5B264"
            />
            <path
              d="M297.746 479.297c.218-5.177.474-7.923.769-8.238.46-.49 2.585-2.87 4.465-2.114 1.88.755 23.984 12.852 24.662 13.732.678.88.167 10.395 0 10.946-.094.31-.58.87-1.459 1.68a1 1 0 01-1.153.144c-1.776-.96-2.664-1.567-2.664-1.824v-6.888l-18.392-11.32v4.472l-1.62 1.845a1 1 0 01-1.27.195l-2.858-1.733a1 1 0 01-.48-.897z"
              fill="#F5B264"
            />
          </g>
          <g>
            <path
              d="M397.5 570.106c.526 1.754 6.94 3.82 13.401 3.82 4.098 0 11.382-1.153 21.854-3.458a7 7 0 002.905-1.4c5.72-4.639 9.414-7.4 11.085-8.285 2.687-1.423 14.914.843 20.589 0 3.782-.562 8.02-2.212 12.712-4.95-2.455-2.868-5.138-5.008-8.048-6.42-2.91-1.413-7.493-2.798-13.747-4.157-11.632-.615-20.515-.615-26.65 0-6.136.616-11.064 2.001-14.785 4.156-5.892 3.951-10.09 7.267-12.592 9.948-3.753 4.021-7.25 8.992-6.724 10.746z"
              fill-opacity=".25"
              fill="#000"
            />
            <path
              d="M400.478 269.97c-.145 8.141.16 13.88.917 17.217 1.134 5.004 4.306 9.444 5.29 10.167.655.483 2.594.483 5.815 0v13.477c17.405-3.873 24.92-9.85 22.541-17.931-2.377-8.081-13.898-15.725-34.563-22.93z"
              fill="#97574E"
            />
            <path
              d="M397.575 255.418c1.402-4.741 7.912-14.556 20.293-14.556 8.254 0 14.977 4.852 20.168 14.556 2.088 4.895 3.146 8.431 3.176 10.607.11 8.067-2.272 14.052 0 17.142 3.191 4.34 4.787 12.204 4.787 23.593-4.782-5.012-8.692-7.663-11.731-7.952-12.481-1.187-20.957-6.641-25.497-11.401a29.966 29.966 0 01-2.046-2.389c-1.975-2.562-5.334-5.18-7.15-11.207-1.378-4.574-3.402-13.653-2-18.393z"
              fill="#F4E1FF"
            />
            <path
              d="M446.137 306.823c.016-4.657-.175-8.211-.574-10.663-.597-3.677-2.485-7.317-3.048-8.135-.563-.817-6.562-2.321-10.948-4.702-4.386-2.38-12.021-8.262-12.458-8.001-.437.261 5.355 5.433 8.835 8.001 3.48 2.569 11.4 6.14 9.964 5.875-1.436-.266-15.427-3.822-20.57-7.21-5.144-3.388-8.125-11.975-8.554-11.975-.43 0 1.004 8.387 4.588 11.975 3.584 3.588 8.534 6.246 14.572 7.21 6.038.963-8.373 1.44-13.27-1.173-4.896-2.611-13.898-13.7-13.898-13.318 0 .383 4.125 11.12 12.596 17.188 2.757 1.974 6.556 3.677 12.999 5.576 2.863.843 6.372 1.305 10.333 2.567 2.635.84 5.779 3.101 9.433 6.785z"
              fill-opacity=".5"
              fill="#CFB1E5"
            />
            <path
              d="M439.989 392.285c6.333-13.795 9.842-22.911 10.527-27.348 1.028-6.654 1.028-29.214 0-39.392-1.028-10.18-2.016-20.282-6.236-19.529-2.813.503-7.99 20.569-15.532 60.2l11.24 26.07z"
              fill="#970102"
            />
            <path
              d="M451.047 553.829l9.466-15.187c.627 3.413.967 5.734 1.021 6.964.052 1.197-.394 3.098-1.34 5.703a1 1 0 01-.246.379c-.966.932-1.94 1.517-2.92 1.755-1.038.251-3.031.38-5.981.386z"
              fill="#020401"
            />
            <path
              d="M445.49 546.961c.577-2.435 1.146-4.39 1.708-5.867.562-1.476 1.549-3.608 2.96-6.395 3.757 2.805 5.76 4.106 6.009 3.902.249-.203-3.31 2.583-10.676 8.36z"
              fill="#501F22"
            />
            <path
              d="M451.016 553.726c2.192-1.216 3.875-2.637 5.05-4.262 1.763-2.438.964-6.545 3.234-8.19 1.513-1.098 2.758 3.517 3.734 13.844h3.104l-.993-21.582a1 1 0 00-1.484-.828l-4.361 2.42-5.948.632c-1.09 2.473-2.019 4.311-2.788 5.513-.77 1.203-1.933 2.484-3.492 3.845l-4.119 2.223a1 1 0 00-.524.912c.06 1.845.449 3.16 1.168 3.943.798.87 3.271 1.38 7.419 1.53z"
              fill="#259E1B"
            />
            <path
              d="M449.74 536.159c1.433.445 4.454-.074 7.146-.933 1.684-.537 3.868-1.629 6.552-3.276a1 1 0 00.472-.952c-1.55-15.445-3.518-27.952-5.905-37.521-3.629-14.547-11.328-32.15-14.096-42.294-2.769-10.145.558-25.375 0-37.934-.373-8.373-1.295-16.48-2.769-24.321l-28.64 9.408 13.694 69.176c5.149 11.651 9.163 21.674 12.042 30.069 4.32 12.592 10.07 38.132 11.504 38.578z"
              fill="#DD450E"
            />
            <path
              d="M394.723 396.134c-.285 10.447-.285 17.805 0 22.077.455 6.817 2.047 12.2 2.678 17.297 1.836 14.832 6.125 29.364 7.103 39.624.98 10.26 2.517 29.781 3.927 44.744.913 9.685 1.8 18.125 2.66 25.32a1 1 0 00.7.838c3.461 1.062 6.172 1.593 8.132 1.593 1.951 0 4.973-.526 9.066-1.578a1 1 0 00.75-1.033c-1.062-16.439-1.901-28.316-2.52-35.631-2.312-27.356-4.038-48.78-2.988-64.39 1.478-21.977 4.748-40.529 9.808-55.657l-39.316 6.796z"
              fill="#FC5D1C"
            />
            <path
              d="M393.914 409.918c0 1.282 13.116 4.002 19.59 4.002 6.475 0 14.72-2.29 16.226-3.61 1.003-.88 1.816-8.852 2.439-23.916l1.904 21.812a1 1 0 001.18.896c2.618-.487 4.254-.891 4.906-1.212 1.067-.526 2.33-1.578 3.788-3.158a1 1 0 00.208-1.012 131.392 131.392 0 01-3.996-13.486c-1.651-6.955-4.339-11.054-4.339-21.893 0-10.84 4.12-21.087 5.56-31.835.472-3.532 2.486-9.171 2.975-14.39.335-3.581.335-9.425 0-17.532-9.086-5.262-15.77-7.675-20.055-7.238-6.426.655-11.955 3.583-17.868 11.107-5.914 7.524-15.283 21.776-15.283 26.096 0 2.88 1.149 6.562 3.446 11.049.228 16.629.228 29.028 0 37.198-.341 12.255-.68 25.84-.68 27.122z"
              fill="#CA0302"
            />
            <path
              d="M390.505 356.967c1.5-3.083 2.454-5.29 2.862-6.622 4.167-13.618 6.054-23.312 7.432-29.013 1.606-6.643 5.506-10.18 11.701-10.61 9.698 3.495 10.833 15.172 3.405 35.032-5.778 15.45-10.565 24.141-14.419 27.603-.572.514-1.325 1.085-2.26 1.715a4 4 0 01-1.781.657c-9.943 1.135-17.132 1.539-21.568 1.211-4.435-.327-10.22-1.687-17.353-4.081a1 1 0 01-.682-.948v-10.327a1 1 0 01.98-1c8.272-.161 14.454-.445 18.546-.85 4.107-.408 8.27-1.154 12.486-2.236a1 1 0 00.651-.531z"
              fill="#EA2121"
            />
            <path
              d="M358.195 371.808v-11.947c-1.176-1.4-2.137-2.388-2.883-2.965-.745-.577-1.916-1.237-3.511-1.98-2.235-.42-4.13-.629-5.683-.629-1.338 0-3.23.156-5.676.466a1 1 0 00-.817 1.326l.4 1.129a1 1 0 00.943.665h4.522a1 1 0 01.9.566l.612 1.268a1 1 0 01-.422 1.312l-3.464 1.89a1 1 0 01-1.323-.342c-1.573-2.476-2.876-3.869-3.91-4.18-1.71-.514-2.144 4.658-1.71 7.49.228 1.483 1.117 3.453 2.67 5.909a4 4 0 003.846 1.836l15.506-1.814z"
              fill="#955E4A"
            />
            <path
              d="M415.873 305.224c-4.929 2.854-7.393 4.193-7.393 4.018 0-.274 2.34-5.026 4.54-7.227 1.467-1.467 4.357-2.991 8.671-4.574 4.516-.047 7.761.177 9.737.672 1.648.414 3.662 1.092 6.041 2.034a1 1 0 01.313 1.662l-2.322 2.157c-.991.081-1.703.081-2.134 0-2.514-.473-4.803-.29-6.74-.29-1.543 0-4.644.352-9.305 1.055a4 4 0 00-1.408.493z"
              fill="#F02425"
            />
            <g>
              <path
                d="M408.037 559.286c.743-3.405 1.424-6.023 2.04-7.855a31.254 31.254 0 012.423-5.398l7.302 3.394-11.765 9.86z"
                fill="#955E4A"
              />
              <path
                d="M418.284 564.516c1.293-1.597 2.881-5.459 4.764-11.585-3.362 1.55-5.904 3.353-7.626 5.411-2.583 3.088-3.64 7.99-3.456 8.139.184.148 4.378.43 6.318-1.965z"
                fill="#220205"
              />
              <path
                d="M408.934 558.151c2.096-1.212 3.285-2.584 3.566-4.117.465-2.533 3.424-6.519 4.126-6.519.702 0 .64.844 3.198.844 1.505 0 4.06-.377 7.666-1.13a1 1 0 011.186 1.17c-.7 3.568-1.128 6.393-1.285 8.478-.164 2.175-.178 5.465-.04 9.87a1 1 0 01-1.143 1.02l-1.359-.197a1 1 0 01-.854-.929l-.832-13.688c-3.911 2.047-6.535 3.901-7.873 5.563-1.337 1.662-2.46 4.464-3.37 8.408-3.036-.08-5.14-.413-6.316-1-.848-.422-1.523-1.13-2.028-2.127a4 4 0 01-.4-2.309c.182-1.433.443-2.424.785-2.972.348-.56 1.014-1.033 1.997-1.42a1 1 0 011.062.212l.718.695a1 1 0 001.196.148z"
                fill="#41CD2E"
              />
            </g>
          </g>
          <g>
            <path
              d="M436.079 596.664c0 5.803 8.783 9.892 17.234 12.368 5.633 1.651 15.135 1.997 28.506 1.037 3.067-.977 5.512-2.066 7.336-3.267 2.734-1.8 4.701-5.188 7.047-6.205 2.346-1.018 6.844.239 9.955-1.018 3.11-1.256 6.72-2.906 7.72-6.123s-1.909-8.33-6.463-11.766c-4.554-3.435-15.144-6.982-19.701-7.97-4.558-.987-8.86.988-12.636 2.86-2.518 1.248-5.628 3.924-9.33 8.028-7.619.064-13.134.79-16.546 2.176-5.117 2.08-13.122 4.076-13.122 9.88z"
              fill-opacity=".25"
              fill="#000"
            />
            <path
              d="M509.282 332.493c3.438 3.377 5.678 6.067 6.719 8.07 3.606 6.938 5.262 12.547 6.791 16.966 2.192 6.332 3.662 14.151 4.409 23.457-1.215 15.233-1.822 26.592-1.822 34.078 0 7.486.607 13.71 1.822 18.67l8.564 14.346c-1.054 4.777-2.731 7.612-5.03 8.505-3.45 1.338-6.475-4.46-7.083-4.46-.608 0 1.345 4.562 2.717 6.581 1.373 2.02 3.804 1.807 3.01 2.622-.793.815-4.106-.268-6.092-1.555-1.985-1.287-3.307-3.43-3.814-5.18-.228-.788-3.486-12.205-4.896-34.646-.677-10.772.677-34.81 0-39.997-.451-3.458-5.258-16.261-14.419-38.409l9.124-9.048zM512.948 451.586c-.992 9.934-1.703 17.443-2.134 22.525-.432 5.082-.432 11.352 0 18.81 1.423 16.473 2.134 27.093 2.134 31.86 0 3.284-1.024 13.789-4.048 27.83-1.365 6.337-5.507 18.067-5.507 20.457 0 2.39.448 6.698 1.024 10.142.576 3.443 1.757 4.14.643 6.205-.743 1.377-5.182 1.12-13.317-.77h-11.56v-7.352l4.362-1.528c2.787-2.324 4.794-4.556 6.024-6.697 1.845-3.21 3.18-5.938 4.389-11.429.806-3.66.103-19.607-2.11-47.841l-9.83-59.968 29.93-2.244z"
              fill="#B27D4B"
            />
            <path
              d="M482.427 585.898c6.087 2.66 9.283 3.832 9.589 3.516.46-.474-1.09-3.811-.64-4.373.217-.27 2.174-.318 7.277-.855 1.98-.208 6.149-.55 6.867-1.017.72-.466 0-3.056-.622-3.73-.307-.332-2.8.457-7.75 1.81-1.736.474-4.32 1.92-6.197 1.454-1.877-.466-2.385-1.141-4.079-2.13-1.13-.66-2.61-1.61-4.445-2.848v8.173z"
              fill="#3B68A1"
            />
            <path
              d="M483.212 591.722l6.983.324c2.03 1.562 3.697 2.654 5.003 3.275 1.305.621 2.977.892 5.015.812 1.356-.122 2.545-.393 3.565-.812 1.531-.629 3.321-1.392 3.642-1.982.321-.59.25-.59.321-1.617.072-1.027.333-2.562 0-2.958-.333-.396-2.956-.363-3.226 0s-.607 1.503-.969 1.503c-.361 0-5.698.315-7.136 0-1.438-.315-3.435-1.133-5.893-1.866-1.639-.489-4.26-1.139-7.863-1.949l.558 5.27z"
              fill="#260108"
            />
            <path
              d="M460.863 447.26c2.286 21.457 4.191 37.436 5.718 47.936 2.29 15.75 4.62 24.145 5.054 30.722.435 6.576.794 29.594-.528 43.606s-1.322 12.271-4.526 16.996c-3.204 4.726-18.906 2.74-18.906 5.597 0 1.168-.509 4.677 4.435 5.835 3.117.73 12.018 0 14.471 0 2.454 0 4.502 1.135 6.628 1.793 2.126.658.483.45 4.98.554 4.498.104 6.22-.73 6.826-2.347.607-1.616-1.474-12.169-1.474-18.838 0-2.392.585-6.775 2.027-13.62 1.238-5.88 3.283-18.233 3.956-23.196.239-1.764-.366-11.543 0-29.839.214-10.688.885-33.15 2.015-67.387l-30.676 2.187z"
              fill="#C18A52"
            />
            <path
              d="M513.984 431.55c.574 5.18 1.076 8.99 1.506 11.428.43 2.439.75 4.9.961 7.383-.536 3.385-1.834 5.802-3.892 7.251-3.087 2.174-2.687 3.396-10.415 3.396-5.152 0-7.913-8.141-8.283-24.423l20.123-5.036z"
              fill="#9E3793"
            />
            <path
              d="M460.85 435.333l-.779 14.21c-1.444 6.733-1.676 10.95-.694 12.648 1.473 2.547 15.389 6.891 22.683 7.336 7.294.444 16.56-3.167 19.126-4.79 2.566-1.621 1.219-11.47 1.826-16.773.405-3.536.405-8.27 0-14.202l-42.162 1.571z"
              fill="#CF4CCC"
            />
            <path
              d="M458.647 453.233c-.688.626-.825 7.51-.46 8.702.363 1.192 5.833 3.33 9.876 4.987 4.044 1.657 9.696 2.205 12.96 2.205 3.265 0 7.71.246 11.043-.666 3.334-.91 8.199-2.693 9.497-3.76 1.299-1.067.998-4.286 1.243-5.692.346-1.99-.366-3.64-.648-3.92-.721-.717-15.094 3.363-21.135 3.363-6.04 0-21.688-5.845-22.376-5.219z"
              fill="#D773CD"
            />
            <path
              d="M460.26 437.571c2.863 2.525 5.041 4.098 6.536 4.721 8.42 3.509 15.61 3.621 20.419 3.376 3.76-.192 12.18-1.581 18.146-4.292 3.698-1.68 6.39-1.68 8.808-6.557 1.178-2.376-1.473-9.949-5.153-25.513-.903-3.824-1.78-10.728-2.628-20.713 2.202-16.7 4.33-29.641 6.386-38.824s1.238-15.17-2.452-17.96c-4.76-2.857-9.761-3.884-15.003-3.083-5.24.802-12.899 3.765-22.973 8.89-3.854 4.68-6.555 8.731-8.103 12.153-1.548 3.42-2.875 8.273-3.983 14.556-.618 3.684-.618 6.262 0 7.735.929 2.21 5.016 2.21 5.016 4.203 0 1.33-1.672 21.765-5.016 61.308z"
              fill="#95CD4E"
            />
            <path
              d="M468.007 346.03c.632-2.55 2.792-8.066 8.424-8.066 5.631 0 7.4 6.092 7.887 8.066.487 1.973.474 21.947 0 32.932-.475 10.986-2.619 21.185-3.199 23.407-.58 2.222-22.65 34.791-23.491 37.617-.539 1.807-.218 10.173 0 16.3.122 3.453.424 6.1 0 6.695-1.18 1.655-5.589-8.597-7.342-8.597-.847 0-1.952 3.132-2.086 6.663-.144 3.775.692 6.76 0 7.2-.632.402-3.605.25-5.383-4.647-.733-2.018-1.446-5.385-.723-8.282.482-1.93 2.06-6.458 4.737-13.582 11.687-24.132 17.967-37.57 18.84-40.317 1.309-4.12 1.704-52.84 2.336-55.39z"
              fill="#C18A52"
            />
            <g>
              <path
                d="M508.67 322.278c.696 13.707.696 23.123 0 28.25-.71 5.229-1.404 8.952-2.698 12.512-3.187 8.772-6.522 13.654-6.847 14.603-.653 1.9-.653-3.895 0-17.385-6.06 17.82-9.413 26.298-10.062 25.437-.503-.668.78-6.65.637-14.149-.135-7.026-1.644-15.353-3.5-22.808-1.552-6.237-5.725-8.388-9.65-18.354-.815-2.067-2.117-4.224-3.434-8.106-.95-2.799-2.589-8.33-4.919-16.596l40.473 16.596z"
                fill="#E6D095"
              />
              <path
                d="M475.329 277.09c3.821-3.546 12.86-5.172 18.956-3.546 2.184.583 6.116 1.105 9.06 3.546 2.535 2.102 5.95 5.687 7.082 9.364 1.133 3.676 1.133 10.674.872 16.057-.26 5.383-3.22 11.902-2.398 15.459.821 3.556 2.324 22.615 0 33.504-2.325 10.889-11.02 18.64-12.127 18.64-1.108 0 1.107-12.604 0-17.916-1.108-5.312-4.813-14.97-5.422-13.926-.291.498 1.982 7.602 1.56 13.202-.464 6.125-4.988 9.297-4.988 8.854 0-.442.717-6.361-1.664-14.724-1.037-3.641-1.725-6.798-4.571-11.49-2.847-4.692-3.178-5.199-5.034-10.122-1.747-4.633-4.902-18.146-5.593-18.146-.691 0-1.779 6.349-1.401 10.618.377 4.27 4.24 13.717 3.587 14.115-.652.398-4.778-8.224-6.056-12.61-1.782-6.115-1.88-13.087-1.686-18.064.498-12.82 6.002-19.268 9.823-22.815z"
                fill="#FFE9B0"
              />
            </g>
            <g>
              <path
                d="M448.059 594.952c0 .641 1.44 1.657 2.436 2.253.995.595 2.503 1.614 3.174 1.105.671-.51.951-2.955 2.203-4.656.834-1.134 2.418-2.733 4.75-4.795-2.758.22-4.415.414-4.97.582-.832.252-2.89 1.825-4.35 2.885-1.46 1.06-3.243 1.985-3.243 2.626zM461.423 589.154c.063 2.74.12 4.8.171 6.182.076 2.073-.225 3.81.209 4.188.434.378 6.268.306 6.98 0 .714-.307-.676-4.218-.338-4.792.339-.575 3.963-.382 6.845-.902 2.882-.521 8.267-1.523 8.462-1.865.194-.342.194-2.525 0-2.959-.195-.434-2.206.024-3.4.148-.796.083-1.936.27-3.418.561-1.8.783-3.249 1.313-4.347 1.59-1.646.417-3.532 1.034-5.185.822-1.653-.213-3.958-3.83-4.353-3.992-.263-.108-.805.232-1.626 1.02z"
                fill="#3E90F3"
              />
              <path
                d="M448.438 598.788c.914 1.118 2.395 2.18 3.443 2.89 1.047.709 8.007.841 11.397 1.226 2.26.257 4.354.517 6.28.78 4.208 1.19 7.233 1.785 9.077 1.785 2.766 0 2.94-.31 4.468-1.243 1.527-.932 3.375-2.283 3.375-3.683s-.761-3.357-1.209-3.357c-.208 0-.634 1.083-1.831 2.178-.514.47-.929.867-2.058.867-1.129 0-5.228.183-7.038-.288-1.81-.47-2.592-1.155-4.38-1.587-.864-.209-3.835.02-9.359 0-2.105-.008-5.903-.008-7.553-.328-1.65-.319-6.622-5.13-6.877-3.122-.256 2.01 1.35 2.764 2.265 3.882z"
                fill="#360009"
              />
            </g>
          </g>
          <g>
            <path
              d="M488.773 631.59c0 3.269 3.122 10.527 15.82 14.465 12.7 3.938 42.285 5.882 49.804 5.882 7.519 0 14.552-3.041 20.995-5.882 6.443-2.84 7.914-6.855 11.114-8.276 3.2-1.42 12.49.397 17.581-1.42 5.091-1.817 10.73-4.869 10.73-9.79 0-4.922-2.037-9.465-8.737-13.407-4.466-2.628-13.788-5.448-27.966-8.46-5.186-.794-10.585-.269-16.198 1.574-5.613 1.843-13.961 5.832-25.044 11.967-11.907.115-20.627.94-26.162 2.476-3.197.887-7.676 1.47-12.06 3.751-3.205 1.667-9.877 3.85-9.877 7.12z"
              fill-opacity=".25"
              fill="#000"
            />
            <path
              d="M554.802 324.802l1.515 29.627c.99 1.845 2.06 3.156 3.21 3.932 1.15.777 3.017 1.449 5.6 2.017v11.548h20.788v-17.497-35.355l-31.113 5.728z"
              fill="#9F6737"
            />
            <path
              d="M554.991 294.912c3.977.004 6.913.25 8.807.736 2.84.73 5.89 3.668 6.725 3.254.384-.19.27-1.35-.399-2.583-.787-1.45-1.492-1.877-1.001-1.877.907 0 8.89 2.922 13.318 6.124 2.952 2.134 6.556 5.697 10.81 10.689 2.204 4.865 3.306 9.108 3.306 12.728 0 3.62-1.57 8.727-4.71 15.322-2.243 3.256-3.607 5.74-4.091 7.452-.485 1.712-.485 4.047 0 7.004-2.22.319-3.992.478-5.315.478-1.984 0-5.003-1.854-5.616-1.502-.614.353.47 5.893 0 5.292-.23-.292-1.483-1.76-3.602-3.79-.794-.76-1.989-1.502-2.7-1.502-.71 0-1.337 6.67-2.065 2.823-.334-1.76-1.886-5.923-2.568-13.045-.312-3.256-.11-10.321-.513-11.726-.403-1.404-3.207-3.109-4.5-2.255-.536.353-1.815 1.533-2.27 3.267-.277 1.056.615 3.797 0 3.797s-3.331-1.07-4.783-4.809c-1.452-3.737-1.892-8.115-1.892-11.42 0-3.304 2.457-10.239 3.06-14.385.4-2.764.4-6.121 0-10.072z"
              fill="#FFFCDF"
            />
            <path
              d="M556.614 312.52l4.347-11.006c6.637 7.142 10.142 10.81 10.514 11.005.372.195.164-2.142-.622-7.012 1.587.53 2.9 1.245 3.94 2.145 1.558 1.35 3.572 5.718 4.152 6.01.387.195 1.224-.976 2.512-3.513l5.44 18.886c-.283-6.866-.764-11.99-1.44-15.373-.678-3.381-1.767-6.1-3.269-8.155l-3.243 1.38c-2.678-1.899-4.757-3.303-6.238-4.213-1.481-.91-3.244-1.867-5.288-2.87v2.87l-8.792-6.087-2.013 15.932z"
              fill-opacity=".65"
              fill="#EEE8C6"
            />
            <path
              d="M591.698 395.998c5.837 16.524 10.192 28.918 13.065 37.182 4.31 12.395 8.112 20.351 8.447 23.66.335 3.31.642 8.004.977 11.611.335 3.608 3.404 8.287 6.601 11.644 3.197 3.357 7.978 5.853 8.873 3.735.367-.868-1.22-.593-3.326-3.37-1.13-1.491-3.577-4.7-2.667-4.7.91 0 5.166 4.942 6.916 4.335 1.75-.608 5.26-4.828 3.785-8.599-.573-1.466-2.88-3.27-5.579-6.883-1.929-2.585-5.122-6.285-6.745-10.137-.796-1.892-2.216-15.115-6.38-39.93-.993-5.92-3.481-15.904-7.464-29.95l-16.503 11.402z"
              fill="#895327"
            />
            <path
              d="M592.776 362.028c4.959.104 8.699 1.92 11.22 5.447 3.783 5.29 9.285 20.892 9.285 23.236 0 1.562-4.55 2.74-13.65 3.53l-6.855-32.213z"
              fill="#B00023"
            />
            <path
              d="M539.747 529.118c-.956 25.553-1.75 44.076-2.383 55.57-.633 11.495-1.694 26.683-3.183 45.564l13.92 1.845c3.249-8.266 5.75-15.416 7.506-21.45 2.634-9.05 5.623-20.645 6.669-28.952 1.046-8.307.867-16.605 1.28-27.961.276-7.572.57-18.007.88-31.307l-24.69 6.691z"
              fill="#A16938"
            />
            <path
              d="M570.864 518.105c3.92 24.763 7.2 42.41 9.844 52.94 2.644 10.532 4.447 25.292 5.409 44.281 2.975 2.725 5.523 4.225 7.646 4.5 3.184.414 9.19.414 10.705-2.115 1.515-2.53-1.515-45.898-1.515-54.846 0-8.948-1.978-21.162-3.777-30.562-1.2-6.266-3.004-15.085-5.413-26.457l-22.899 12.259z"
              fill="#885327"
            />
            <path
              d="M595.263 468.256c.512 14.274.962 24.54 1.35 30.797.584 9.386 1.878 18.719 1.878 21.153 0 2.434-7.305 4.642-12.19 5.653-3.256.674-9.091.15-17.505-1.572l-7.483-56.031h33.95z"
              fill="#B88800"
            />
            <path
              d="M537.048 471.443c-.884 14.79-1.326 25.81-1.326 33.062 0 10.876-.05 27.976 1.326 29.95 1.377 1.974 6.871 2.525 12.62 3.112 5.75.586 15.79.301 17.311-1.138 1.521-1.44 1.521-12.14 4.286-26.696 1.843-9.703 4.713-21.672 8.61-35.908l-42.827-2.382z"
              fill="#F4B601"
            />
            <path
              d="M537.068 475.614c5.213 3.596 9.146 5.856 11.798 6.78 8.293 2.885 16.28 2.7 21.723 2.7 12.08 0 26.37-9.396 26.37-13.553s-3.592-19.91-2.214-39.065c.616-8.56 3.148-19.412 5.744-33.863.85-4.738 2.058-12.776 3.622-24.113-.038-4.534-.612-7.591-1.72-9.17-1.108-1.58-3.657-2.973-7.646-4.177-9.462-.121-17.514.563-24.156 2.052-6.641 1.489-15.247 4.539-25.817 9.15-5.003 5.916-7.007 16.71-6.01 32.38.996 15.672.431 39.298-1.694 70.879z"
              fill="#E80C30"
            />
            <path
              d="M535.879 387.619c-5.202 6.294-8.892 11.228-11.071 14.8-3.268 5.36-7.993 10.553-7.993 16.458 0 2.735 2.4 11.037 7.993 22.58 2.454 5.065 9.374 10.432 9.374 16.578 0 4.098-.658 9.623-1.973 16.576.357 5.39 1.58 8.084 3.67 8.084s3.93-2.045 5.52-6.136c1.715-8.246 2.572-14.42 2.572-18.524 0-6.154-11.762-28.439-11.762-32.497 0-2.705 5.94-11.7 17.82-26.984l-14.15-10.935z"
              fill="#A16938"
            />
            <path
              d="M530.162 388.935c1.362 2.655 3.509 5.159 6.44 7.511 2.932 2.352 7.799 5.475 14.6 9.37 6.603-7.895 10.464-13.522 11.581-16.881 1.676-5.039 2.638-12.95-3.27-17.037-3.94-2.725-8.286-2.725-13.04 0a30.462 30.462 0 00-7.673 5.806c-2.22 2.32-5.1 6.063-8.638 11.23z"
              fill="#F82C59"
            />
            <g>
              <path
                d="M551.185 628.2c1.417.653 1.162 3.967 1.417 6.99.17 2.016.17 5.284 0 9.804-3.993 1.353-7.238 2.03-9.735 2.03-2.497 0-6.669-.677-12.515-2.03-7.488.543-12.783.02-15.885-1.566-4.653-2.38-8.935-10.006-8.935-11.13 0-1.125.938-1.81 1.733-2.586.53-.517 1.352-1.021 2.464-1.512h15.842c2.85-.57 4.82-1.55 5.908-2.942 1.633-2.088 1.633-7.906 2.573-7.906s2.989 1.32 3.274 3.311c.285 1.992-1.916 3.488-1.916 6.174 0 2.686 2.505 4.09 5.34 4.336 2.836.245 9.02-3.625 10.435-2.973z"
                fill="#1C0DFE"
              />
              <path
                d="M505.366 632.458c-.627 0 .626 4.966 2.558 7.51 1.932 2.543 3.937 4.556 8.235 6.113 2.865 1.038 8.987 1.61 18.368 1.717a25.65 25.65 0 005.802 1.38c2.942.344 7.06.381 9.627-.531 2.566-.912 4.116-4.438 3.48-4.761-.635-.324-5.313 2.405-8.71 2.603-2.265.132-5.664-.123-10.199-.765-5.196-.213-8.854-.437-10.976-.672-2.12-.236-4.287-.841-6.497-1.815-2.954-.954-5.096-2.044-6.426-3.27-1.994-1.839-4.636-7.51-5.262-7.51z"
                fill="#DDD5D2"
              />
            </g>
            <g>
              <path
                d="M585.696 614.596l4.304 4.152c2.514.657 4.332.986 5.454.986 1.121 0 2.58-.329 4.377-.986 2.233-1.72 3.642-2.411 4.226-2.076.877.502.877 3.68 1.054 5.631.118 1.3.118 3.296 0 5.986-.509 1.123-1.399 1.985-2.67 2.586-1.271.601-3.236 1.09-5.895 1.464-2.469-.119-4.26-.355-5.375-.71-1.115-.353-2.129-1.007-3.042-1.962-1.33-2.185-2.38-3.8-3.15-4.846a9 9 0 00-2.825-2.518l-5.59-1.384c-1.419-1.013-2.423-1.874-3.01-2.583-.589-.71-1.334-1.956-2.235-3.74-.26-2.172-.26-3.732 0-4.679.262-.947 1.006-2.086 2.234-3.42 2.18-.688 3.765-1.033 4.756-1.033.991 0 2.273.345 3.845 1.034 2.361-2.887 3.542-3.61 3.542-2.166v10.264z"
                fill="#160AC3"
              />
              <path
                d="M571.296 615.037c-.965-.164.732 3.677 1.874 5.191 1.141 1.514 3.411 3.424 4.504 3.834 1.093.41 2.953-.44 3.835 0 .882.44 2.577 3.193 3.388 4.462 2.087 3.27 2.907 5.111 3.861 5.547 1.986.907 11.516.263 13.48-.601 1.964-.865 2.927-4.97 2.617-5.481-.31-.512-2.596 2.234-4.182 2.901-1.058.445-2.752.776-5.083.994-2.224.07-4.278-.624-6.161-2.083-2.825-2.19-5.705-6.585-6.457-7.695-.751-1.11-3.398-.692-5.298-1.11-1.901-.419-2.299-1.463-3.087-2.26-1.975-1.996-2.832-3.62-3.291-3.699z"
                fill="#DAD9D7"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
