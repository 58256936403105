const INITIAL_STATE = { error: false, loading: false, data: {} };

export function userPermissionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_USER_PERMISSIONS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "ADD_USER_PERMISSIONS_SUCCESS":
      return { error: false, loading: false, data: action.permissions };
    case "ADD_USER_PERMISSIONS_ERROR":
      return { error: true, loading: false, data: {} };
    case "LOG_OUT":
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function getUserPermissions(state) {
  return state.userPermissions;
}
