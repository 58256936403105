import React from "react";
import { useSelector } from "react-redux";
import { Route as RRRoute, Redirect } from "react-router-dom";

function Route(props) {
  const userValidated = useSelector(state => state.authedUser.data?.role);
  const checkData = JSON.parse(localStorage.getItem("check"));
  let isRecognized = false;
  if (checkData) isRecognized = checkData.isRecognized;

  if (props.private && !userValidated && !isRecognized) {
    return <Redirect to="/admin/login" />;
  }
  return <RRRoute {...props} />;
}

export default React.memo(Route);
