const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function customersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_CUSTOMERS_LOADING":
      return { error: false, loading: true, data: { ...state.data } };
    case "ADD_CUSTOMERS_SUCCESS": {
      const newData = { ...state.data, ...action.customers };
      return { error: false, loading: false, data: newData };
    }
    case "ADD_CUSTOMERS_ERROR":
      return { error: true, loading: false, data: {} };
    case "ADD_CUSTOMERS": {
      const newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "DELETE_CUSTOMERS": {
      let newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid } = item;
        delete newData[uid];
      });
      return { data: newData, error: false, loading: false };
    }
    case "UPDATE_CUSTOMERS": {
      let newData = { ...state.data };
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "ADD_CUSTOMER": {
      let newData = { ...state.data };
      newData[action?.payload?.uid] = action?.payload?.data;
      return { loading: state.loading, data: newData, error: false };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
