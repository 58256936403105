const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function restaurantSettlementsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case "ADD_RESTAURANT_SETTLEMENTS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "ADD_RESTAURANT_SETTLEMENT_LOADING": {
      const newData = { ...action.data };
      newData[action.uid] = { loading: true };
      return { data: newData, loading: true, error: false };
    }
    case "ADD_RESTAURANT_SETTLEMENT": {
      const newData = { ...action.data };
      newData[action.payload.uid] = action.payload.data;
      return { data: newData, loading: true, error: false };
    }
    case "SET_RESTAURANT_SETTLEMENTS": {
      let newData = { ...action.data };
      return { data: newData, error: false, loading: false };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
