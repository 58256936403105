import objectPath from "object-path";

import buildReducer, { chainReducers } from "../buildReducer";

const INITIAL_STATE = { error: false, loading: false, data: {} };

const baseReducer = buildReducer("CHECK");

function secondReducer(state, action) {
  switch (action.type) {
    case "ADD_ITEM_TO_CHECK": {
      const newData = { ...state.data };
      const { businessUid, checkUid, data } = action.payload;
      const check = { ...newData[checkUid] };
      const items = check?.items ? { ...check.items } : {};
      items[data.basketUid] = data;
      newData[checkUid] = { ...check, items };
      return { ...state, data: newData };
    }
    case "DELETE_ITEM_FROM_CHECK": {
      const newData = { ...state.data };
      const { businessUid, basketUid, checkUid } = action.payload;
      const check = { ...newData[checkUid] };
      const items = check?.items ? { ...check.items } : {};
      delete items[basketUid];
      newData[checkUid] = { ...check, items };
      return { ...state, data: newData };
    }
    case "UPDATE_CHECK_DATA": {
      const newData1 = { ...state.data };
      const check1 = { ...newData1[action.payload.checkUid] };
      const pathArr = action.payload.path.split(".");
      objectPath.set(check1, pathArr, action.payload.value);
      newData1[action.payload.checkUid] = check1;
      return { ...state, data: newData1 };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

const checksReducer = chainReducers([baseReducer, secondReducer]);
export default checksReducer;
