import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { SnackbarConsumer } from "app/util/Snackbar/SnackbarContext";

class SimpleSnackbar extends React.Component {
  static propTypes = {
    autoHideDuration: PropTypes.number,
  };

  static defaultProps = {
    autoHideDuration: 4000,
    classes: {},
    open: false,
    text: "",
  };

  render() {
    const { autoHideDuration } = this.props;
    return (
      <SnackbarConsumer>
        {({ openSnackbar, closeSnackbar, message, isOpen }) => (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            autoHideDuration={autoHideDuration}
            open={isOpen}
            onClose={closeSnackbar}
            message={<span id="message-id">{message}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={closeSnackbar}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </SnackbarConsumer>
    );
  }
}

export default SimpleSnackbar;
