const INITIAL_STATE = { error: false, loading: false, data: {} };

export function menuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_MENU_LOADING": return { error: false, loading: true, data: {} };
    case "ADD_MENU_SUCCESS": return { error: false, loading: false, data: action.menu };
    case "ADD_MENU_ERROR": return { error: true, loading: false, data: {} };
    default:
      return state;
  }
}
