const INITIAL_STATE = { error: false, loading: false, data: {} };

export function incomingCallReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "INCOMING_CALL_LOADING":
      return { error: false, loading: true, data: {} };
    case "SET_INCOMING_CALL":
      return { error: false, loading: false, data: action.data };
    case "INCOMING_CALL_ERROR":
      return { error: true, loading: false, data: {} };
    case "LOG_OUT":
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function selectIncomingCall(state) {
  return state.authedUser.data;
}
