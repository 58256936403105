import cloneDeep from "lodash/cloneDeep";

import {
  SORT_BY,
  VIEW_BY,
} from "pages/business-page/frames/nexus/bookings/util";

const BOOKING_STATUSES = [
  "pendingUid",
  "unconfirmedUid",
  "delayedUid",
  "confirmedUid",
  "partialllySeatedUid",
  "cancelledUid",
  "seatedUid",
];

const INITIAL_STATE = {
  bookingsTypeFilter: ["reservation", "waitlist", "walk-in"],
  expandedList: "",
  filterMenuOpen: false,
  search: "",
  selectedMenuItem: 0,
  sortBy: SORT_BY.DATE_TIME,
  statusFilter: BOOKING_STATUSES,
  viewBy: VIEW_BY.BOOKINGS,
};

export default function nexusBookingsReducer(state = INITIAL_STATE, action) {
  const setState = (param) => {
    const newState = { ...state };
    newState[param] = action.data;
    return newState;
  };

  switch (action.type) {
    case "SET_EXPANDED_LIST": {
      return setState("expandedList");
    }
    case "NEXUS_BOOKINGS_SET_STATUS_FILTER": {
      return setState("statusFilter");
    }
    case "NEXUS_BOOKINGS_SET_FILTER_MENU_OPEN": {
      return setState("filterMenuOpen");
    }
    case "NEXUS_BOOKINGS_SET_SELECTED_MENU_ITEM": {
      return setState("selectedMenuItem");
    }
    case "NEXUS_BOOKINGS_SET_VIEW_BY": {
      return setState("viewBy");
    }
    case "NEXUS_BOOKINGS_SET_BOOKINGS_TYPE_FILTER": {
      return setState("bookingsTypeFilter");
    }
    case "NEXUS_BOOKINGS_SET_SEARCH": {
      return setState("search");
    }
    case "NEXUS_BOOKINGS_SET_SORT_BY": {
      return setState("sortBy");
    }
    case "NEXUS_BOOKINGS_RESET": {
      // Don't reset the dimensions
      return {
        ...cloneDeep(INITIAL_STATE),
        dimens: { ...state.dimens },
      };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
