const INITIAL_STATE = 'dark';

export function themeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_THEME_LIGHT": return 'light';
    case "SET_THEME_DARK": return 'dark';
    default:
      return state;
  }
}
