import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import { responsiveStoreEnhancer } from "redux-responsive";
import throttle from "lodash/throttle";
// import createSagaMiddleware from "redux-saga";

import rootReducer from "reducers/rootReducer";
import { loadState, saveState } from "datastore/localStorage";

// import mySaga from "./mySaga";

const logger = createLogger();
// const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState();
const composeEnhancers =
  (process.env.REACT_APP_TARGET &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(thunk, logger) //, sagaMiddleware)
  )
);
store.subscribe(
  throttle(() => {
    saveState({
      appUser: store.getState().appUser,
      authedUser: {
        ...store.getState().authedUser,
        error: false,
        loading: false,
      },
      bookingsPage: store.getState().bookingsPage,
      business: store.getState().business,
      businessPermissions: store.getState().businessPermissions,
      checks: store.getState().checks,
      discounts: store.getState().discounts,
      feedback: store.getState().feedback,
      floorplans: store.getState().floorplans,
      menuItems: store.getState().menuItems,
      menus: store.getState().menus,
      modifiers: store.getState().modifiers,
      nexusBookings: store.getState().nexusBookings,
      posUi: store.getState().posUi,
      reviewHq: store.getState().reviewHq,
      reservations: store.getState().reservations,
      restoUser: store.getState().restoUser,
      sections: store.getState().sections,
      surcharges: store.getState().surcharges,
      tags: store.getState().tags,
      taxes: store.getState().taxes,
      ui: store.getState().ui,
      userPermissions: store.getState().userPermissions,
      waitlist: store.getState().waitlist,
      waitlistHistory: store.getState().waitlistHistory,
      walkins: store.getState().walkins,
      walkinsHistory: store.getState().walkinsHistory,
    });
  }, 1000)
);
// sagaMiddleware.run(mySaga);

export default store;
