import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import setWith from "lodash/setWith";

const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function sectionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_SECTIONS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "UPDATE_SECTION": {
      const newState = {
        error: false,
        loading: false,
        data: cloneDeep(state.data),
      };
      if (!isEqual(newState.data[action.section.uid], action.section)) {
        newState.data[action.section.uid] = action.section;
      }
      return newState;
    }
    case "SET_SECTION_NAME": {
      const { data, sectionUid } = action.payload;
      if (state.data[sectionUid]?.name !== data) {
        const newData = cloneDeep(state.data);
        setWith(newData, `[${sectionUid}].name`, data, Object);
        // Some initialization code to prevent errors elsewhere
        if (!newData[sectionUid].tables) newData[sectionUid].tables = {};
        return {
          error: false,
          loading: false,
          data: newData,
        };
      }
      return state;
    }
    case "UPDATE_TABLES": {
      const newData = cloneDeep(state.data);
      const { sectionUid, data } = action.payload;
      data.forEach((table) => {
        const { uid, ...tableData } = table;
        setWith(newData, `[${sectionUid}].tables[${uid}]`, tableData, Object);
      });
      return {
        error: false,
        loading: false,
        data: newData,
      };
    }
    case "ADD_TABLES": {
      const newData = cloneDeep(state.data);
      const { sectionUid, data } = action.payload;
      data.forEach((table) => {
        const { uid, ...tableData } = table;
        setWith(newData, `[${sectionUid}].tables[${uid}]`, tableData, Object);
      });
      return {
        error: false,
        loading: false,
        data: newData,
      };
    }
    case "DELETE_TABLES": {
      const newData = cloneDeep(state.data);
      const { sectionUid, data } = action.payload;
      data.forEach((table) => {
        delete newData?.[sectionUid]?.tables?.[table?.uid];
      });
      return {
        error: false,
        loading: false,
        data: newData,
      };
    }
    case "CLEAR_SECTIONS":
      return INITIAL_STATE;
    case "ADD_SECTIONS_ERROR":
      return { error: true, loading: false, data: null };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
