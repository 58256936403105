import React from "react";

export default function CustomerCancelledIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.81822 6.66781C4.81822 4.32283 6.71755 2.4235 9.06253 2.4235C11.4075 2.4235 13.3068 4.32283 13.3068 6.66781C13.3068 6.89665 13.2888 7.12124 13.2539 7.34023C11.4872 8.05884 9.9977 9.31761 8.99053 10.9115C6.6788 10.8731 4.81822 8.98875 4.81822 6.66781Z"
        fill="currentColor"
      />
      <path
        d="M8.40453 11.9956C5.43854 12.1869 0.573914 13.5998 0.573914 16.2175V19.4007H8.35272C7.86338 18.2852 7.59182 17.0524 7.59182 15.7561C7.59182 14.4149 7.88259 13.1415 8.40453 11.9956Z"
        fill="currentColor"
      />
      <path
        d="M16.6736 7.85578C12.3047 7.85578 8.77319 11.3872 8.77319 15.7561C8.77319 20.125 12.3047 23.6565 16.6736 23.6565C21.0425 23.6565 24.5739 20.125 24.5739 15.7561C24.5739 11.3872 21.0425 7.85578 16.6736 7.85578ZM20.6237 18.5924L19.5098 19.7063L16.6736 16.8701L13.8373 19.7063L12.7234 18.5924L15.5596 15.7561L12.7234 12.9199L13.8373 11.806L16.6736 14.6422L19.5098 11.806L20.6237 12.9199L17.7875 15.7561L20.6237 18.5924Z"
        fill="currentColor"
      />
    </svg>
  );
}
