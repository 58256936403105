import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

// import { useBreakpoints } from "common/hooks";
import HomeLoader from "pages/business-page/frames/nexus/loading-views/Home";

// import MobileAppBar from "./app-bar/MobileAppBar";
// import MobileTabsBar from "./app-bar/MobileTabsBar";

// import AppBar from "./bottom-app-bar";

import AppBarLoader from "./AppBar";

const Root = styled.div((props) => ({
  display: "grid",
  gridTemplateRows: props.theme.bp.greaterThan.sm ? "1fr auto" : "auto 1fr",
  //   backgroundImage: `url(${bgTile})`,
  backgroundRepeat: "repeat",
}));

export default function FloorplanLoader() {
  //   const bp = useBreakpoints();

  return (
    <Root id="nexus">
      {/* {bp.lessThan.md && <MobileAppBar />} */}
      <HomeLoader />
      <AppBarLoader />
      {/* {bp.greaterThan.sm && <AppBar />} */}
      {/* {bp.lessThan.md && <MobileTabsBar />} */}
    </Root>
  );
}
