const INITIAL_STATE = { error: false, loading: false, data: [] };

export function audiencesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_AUDIENCES_LOADING": return { error: false, loading: true, data: [] };
    case "ADD_AUDIENCES_SUCCESS": return { error: false, loading: false, data: action.audiences };
    case "ADD_AUDIENCES_ERROR": return { error: true, loading: false, data: [] };
    default:
      return state;
  }
}

export function getAudience(state, uid) {
  return state.audiences.data.filter(item => item.uid === uid);
}