const INITIAL_STATE = '';

export function customerListSearchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "UPDATE_CUSTOMER_SEARCH": return action.value;
    case "CLEAR_CUSTOMER_SEARCH": return INITIAL_STATE;
    default:
      return state;
  }
}

export function filterWaitlist(waitlist, state) {
  return filterWaitlistByTerm(waitlist, state.waitlistSearch);
}

export function filterWaitlistByTerm(waitlist, searchTerm) {
  const match = (item, searchTerm) => {
    return item.name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
      item.phoneNumber.includes(searchTerm);
  };

  if (typeof searchTerm === 'string' && searchTerm.length > 0) {
    return waitlist.filter(item => match(item, searchTerm));
  }

  return waitlist;
}