import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div((props) => ({
  height: 45,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: props.theme.palette.background[6],
}));
const Container = styled.div({
  display: "flex",
  alignItems: "center",
  height: "100%",
});

// function RightSideButtons() {
//   return (
//     <div css={{ display: "flex" }}>
//       <FilterButton />
//     </div>
//   );
// }

// function XsContent() {
//   return (
//     <React.Fragment>
//       <Container>
//         <ListButton />
//       </Container>

//       <RightSideButtons />
//     </React.Fragment>
//   );
// }

// function SmContent() {
//   return (
//     <React.Fragment>
//       <Container>
//         <ListButton />
//         <CapacityFilter />
//       </Container>

//       <RightSideButtons />
//     </React.Fragment>
//   );
// }

// function MdContent() {
//   return (
//     <React.Fragment>
//       <Container>
//         <ListButton />
//         <CapacityFilter />
//         <StatusFilter />
//       </Container>

//       <RightSideButtons />
//     </React.Fragment>
//   );
// }

// function LgContent() {
//   return (
//     <React.Fragment>
//       <Container>
//         <ListButton />
//         <CapacityFilter />
//         <StatusFilter />
//       </Container>

//       <RightSideButtons />
//     </React.Fragment>
//   );
// }

function Button({ children, style }) {
  return (
    <div
      style={{
        borderRight: "1px solid rgba(255,255,255,0.06)",
        padding: 10,
        height: "100%",
        display: "flex",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

function XlContent() {
  return (
    <React.Fragment>
      <Container>
        <Button>
          <Skeleton style={{ width: 22 }} animation="wave" />
        </Button>
        <div
          style={{
            height: "100%",
            width: 200,
            borderRight: "1px solid rgba(255,255,255,0.06)",
          }}
          className="centered-container"
        >
          <Skeleton style={{ width: 85, height: 21 }} animation="wave" />
        </div>
        <div
          style={{
            height: "100%",
            width: 200,
            borderRight: "1px solid rgba(255,255,255,0.06)",
          }}
          className="centered-container"
        >
          <Skeleton style={{ width: 85, height: 21 }} animation="wave" />
        </div>
      </Container>

      <Button style={{ borderLeft: "1px solid rgba(255,255,255,0.06)" }}>
        <Skeleton style={{ width: 22 }} animation="wave" />
      </Button>
    </React.Fragment>
  );
}

export default function Bar() {
  //   const dimens = useSelector((state) => state.dimens);
  //   const width = dimens?.width ?? 0;

  const renderContent = () => {
    // if (width < 416) return <XsContent />;
    // if (width < 618) return <SmContent />;
    // if (width < 800) return <MdContent />;
    // if (width < 993) return <LgContent />;
    return <XlContent />;
  };

  return <Root>{renderContent()}</Root>;
}
