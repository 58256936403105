const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function waitlistReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_WAITLIST_LOADING":
      return { error: false, loading: true, data: state.data };
    case "ADD_WAITLIST_SUCCESS": {
      //We need it in this way on Nexus. Reverted for now.
      // let newData = { ...state.data };
      let newData = {};
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WAITLIST_ERROR":
      return { error: true, loading: false, data: {} };
    case "ADD_WAITLIST_ENTRY_LOADING": {
      const newData = { ...state.data };
      newData[action.uid] = { loading: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WAITLIST_ENTRY_ERROR": {
      const newData = { ...state.data };
      newData[action.uid] = { error: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WAITLIST_ENTRY_SUCCESS": {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { error: false, loading: false, data: newData };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
