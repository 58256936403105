const INITIAL_STATE = { error: false, loading: false, data: [] };

export default function transactionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TRANSACTIONS_LOADING": {
      return { error: false, loading: true, data: [] };
    }
    case "ADD_TRANSACTIONS_SUCCESS": {
      return { error: false, loading: false, data: action.transaction };
    }
    case "ADD_TRANSACTIONS_ERROR": {
      return { error: true, loading: false, data: [] };
    }
    case "ADD_TRANSACTION_LOADING": {
      const newData = { ...state };
      return { data: newData.data, error: false, loading: true };
    }
    case "ADD_TRANSACTION_SUCCESS": {
      const oldData = { ...state };
      const newTransactions = oldData.data.filter(
        (item) => item.uid !== action.payload.uid
      );
      newTransactions.push(action.payload.transaction);
      return { error: false, loading: false, data: newTransactions };
    }
    case "ADD_TRANSACTION_ERROR": {
      const newData = { ...state };
      return { data: newData.data, error: true, loading: false };
    }
    case "LOG_OUT":
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
