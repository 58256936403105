import React from "react";
import Loadable from "@loadable/component";

import Loader from "pages/LoaderPage";
import FloorplanLoader from "pages/business-page/frames/nexus/loading-views/floorplan";

const DriverAppPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "DriverAppPage" */ "pages/driver-app/index.jsx"
    ),
  { fallback: <Loader /> }
);
const NewLandingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "NewLandingPage" */ "pages/new-landing/index.jsx"
    ),
  { fallback: <Loader /> }
);
const RestaurantsMain = Loadable(
  () =>
    import(
      /* webpackChunkName: "RestaurantsPage" */ "pages/restaurants/RestaurantsMain.jsx"
    ),
  { fallback: <Loader /> }
);
const RestaurantPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "RestaurantPage" */ "pages/restaurant/index.jsx"
    ),
  {
    fallback: () => (
      <div css={{ height: 600, backgroundColor: "red" }}>
        RESTAURANT PAGE LOADER
      </div>
    ),
  }
);
const CustomerDetailsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerDetailsPage" */ "pages/customer-details/index.jsx"
    ),
  { fallback: <Loader /> }
);
const CommandCenterPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "CommandCenterPage" */ "pages/admin/CommandCenter.jsx"
    ),
  { fallback: <Loader /> }
);

const PendingReservationPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "PendingReservationPage" */ "pages/business-page/frames/pending-reservation/index.jsx"
    ),
  { fallback: <Loader /> }
);
const EditReservationPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "EditReservationPage" */ "pages/business-page/frames/edit-reservation/index.jsx"
    ),
  { fallback: <Loader /> }
);
const KioskPage = Loadable(
  () => import(/* webpackChunkName: "KioskPage" */ "pages/kiosk"),
  { fallback: <Loader /> }
);
const AdminPage = Loadable(
  () => import(/* webpackChunkName: "AdminPage" */ "pages/admin/AdminPage"),
  { fallback: <Loader /> }
);

const AdminPages = Loadable(
  () => import(/* webpackChunkName: "AdminPages" */ "pages/admin/index.jsx"),
  { fallback: <Loader /> }
);

const UserAdmin = Loadable(
  () => import(/* webpackChunkName: "BusinessAdmin" */ "pages/admin/UserAdmin"),
  { fallback: <Loader /> }
);
const BusinessAdminLoginPage = Loadable(
  () =>
    import(/* webpackChunkName: "BusinessLoginPage" */ "pages/business-login"),
  { fallback: <Loader /> }
);
const BusinessPinLoginPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "BusinessLoginPage" */ "pages/BusinessPinLoginPage"
    ),
  { fallback: <Loader /> }
);
const SurveyCommentPage = Loadable(
  () => import(/* webpackChunkName: "SurveyCommentPage" */ "pages/survey-page"),
  { fallback: <Loader /> }
);
const FourOhFourPage = Loadable(
  () => import(/* webpackChunkName: "404" */ "pages/FourOhFourPage"),
  { fallback: <Loader /> }
);
const WaitlistPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "WaitlistPage" */ "pages/customer-waitlist/index.jsx"
    ),
  { fallback: <Loader /> }
);
const OrderFeedbackPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "OrderFeedbackPage" */ "pages/feedback/OrderFeedbackPage"
    ),
  { fallback: <Loader /> }
);
const VisitFeedbackPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "VisitFeedbackPage" */ "pages/feedback/VisitFeedbackPage"
    ),
  { fallback: <Loader /> }
);
const LoyaltyAppPage = Loadable(
  () =>
    import(/* webpackChunkName: "LoyaltyAppPage" */ "app/rg/LoyaltyAppPage"),
  { fallback: <Loader /> }
);
const LoyaltyCheckInPage = Loadable(
  () =>
    import(/* webpackChunkName: "FeedbackPage" */ "pages/LoyaltyCheckInPage"),
  { fallback: <Loader /> }
);

// const IncomingCalls = Loadable({
//   loader: () => import(/* webpackChunkName: "IncomingCalls" */'pages/IncomingCalls'),
//   loading: () => <Loader />,
// });
const RedeemPromoPage = Loadable(
  () =>
    import(/* webpackChunkName: "RedeemPromoPage" */ "pages/RedeemPromoPage"),
  { fallback: <Loader /> }
);
const CheckInPage = Loadable(
  () => import(/* webpackChunkName: "CheckInPage" */ "pages/CheckInPage"),
  { fallback: <Loader /> }
);
const AppPage = Loadable(
  () => import(/* webpackChunkName: "AppPage" */ "app/qg/AppPage"),
  { fallback: <Loader /> }
);
const ReservationConfirmPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationConfirmPage" */ "pages/ReservationConfirmPage"
    ),
  { fallback: <Loader /> }
);
const ReservationCancelPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationCancelPage" */ "pages/ReservationCancelPage"
    ),
  { fallback: <Loader /> }
);
const WaitlistConfirmCustomerPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "WaitlistConfirmCustomerPage" */ "pages/WaitlistConfirmCustomerPage"
    ),
  { fallback: <Loader /> }
);
const WaitlistCancelCustomerPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "WaitlistCancelCustomerPage" */ "pages/WaitlistCancelCustomerPage"
    ),
  { fallback: <Loader /> }
);
const CustomerReservationPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerReservationPage" */ "pages/reservations/CustomerReservation"
    ),
  { fallback: <Loader /> }
);
const AdminDetailPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "AdminDetailPage" */ "pages/admin/detail/index.jsx"
    ),
  { fallback: <Loader /> }
);
const UserDetailPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "UserDetailPage" */ "pages/admin/UserDetailPage"
    ),
  { fallback: <Loader /> }
);

// const SurveyPage = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "SurveyPage" */ "pages/survey-page"),
//   loading: () => <Loader />,
// });

const BusinessPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "BusinessPage" */ "pages/business-page/index.jsx"
    ),
  { fallback: <Loader /> }
);
/** ----------------------------BUSINESS PAGES HERE---------------------------- */
const AddReservationPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "AddReservationPage" */ "pages/business-page/frames/add-reservation/index.jsx"
    ),
  { fallback: <Loader /> }
);
const BusinessWaitlistPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "BusinessWaitlistPage" */ "pages/business-page/frames/waitlist/index.jsx"
    ),
  { fallback: <Loader /> }
);
const CustomersPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "CustomersPage" */ "pages/business-page/frames/customers/index.jsx"
    ),
  { fallback: <Loader /> }
);
const DashboardPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "DashboardPage" */ "pages/business-page/frames/dashboard/DashboardFrame.jsx"
    ),
  { fallback: <Loader /> }
);
const EditLayoutPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "EditLayoutPage" */ "pages/business-page/frames/edit-layout/index.jsx"
    ),
  { fallback: <Loader /> }
);
const MarketingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "MarketingPage" */ "pages/business-page/frames/marketing/index.jsx"
    ),
  { fallback: <Loader /> }
);
const MenuPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "MenuPage" */ "pages/business-page/frames/menu/index.jsx"
    ),
  { fallback: <Loader /> }
);
const NexusPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "FloorplanPage" */ "pages/business-page/frames/nexus/index.jsx"
    ),
  { fallback: <FloorplanLoader /> }
);
const OrdersPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "OrdersPage" */ "pages/business-page/frames/orders/index.jsx"
    ),
  { fallback: <Loader /> }
);
const PosPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "PosPage" */ "pages/business-page/frames/pos/index.jsx"
    ),
  { fallback: <Loader /> }
);
const ReservationsHistoryPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationsHistoryPage" */ "pages/business-page/frames/reservations-page/index.jsx"
    ),
  { fallback: <Loader /> }
);
const VisitsHistoryPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "VisitsHistoryPage" */ "pages/business-page/frames/history/index.jsx"
    ),
  { fallback: <Loader /> }
);
const ReviewsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReviewsPage" */ "pages/business-page/frames/reviews/index.jsx"
    ),
  { fallback: <Loader /> }
);
const SettingsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "SettingsPage" */ "pages/business-page/frames/settings/index.jsx"
    ),
  { fallback: <Loader /> }
);
const UserPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "UserPage" */ "pages/business-page/frames/users/index.jsx"
    ),
  { fallback: <Loader /> }
);
const AddTableBlock = Loadable(
  () =>
    import(
      /* webpackChunkName: "AddTableBlock" */ "pages/business-page/frames/settings/block-table/block-form/AddTableBlock.jsx"
    ),
  { fallback: <Loader /> }
);
const BlockTableDetails = Loadable(
  () =>
    import(
      /* webpackChunkName: "BlockTableDetails" */ "pages/business-page/frames/settings/block-table/block-form/index.jsx"
    ),
  { fallback: <Loader /> }
);
const BillingPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "BillingsPage" */ "pages/business-page/frames/billings/index.jsx"
    ),
  { fallback: <Loader /> }
);
const CustomerPageApp = Loadable(
  () =>
    import(/* webpackChunkName: "CustomerPage" */ "pages/customers/index.jsx"),
  { fallback: <Loader /> }
);
const HqPage = Loadable(
  () => import(/* webpackChunkName: "HqPage" */ "pages/hq/index.jsx"),
  { fallback: <Loader /> }
);
const InventoryPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "InventoryPage" */ "pages/business-page/frames/inventory/index.jsx"
    ),
  { fallback: <Loader /> }
);
const ProcurementPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ProcurementPage" */ "pages/business-page/frames/procurement/index.jsx"
    ),
  { fallback: <Loader /> }
);
/** ----------------------------END BUSINESS PAGES---------------------------- */

export {
  AddTableBlock,
  AppPage,
  AddReservationPage,
  AdminPage,
  AdminPages,
  AdminDetailPage,
  BlockTableDetails,
  BillingPage,
  BusinessPage,
  BusinessAdminLoginPage,
  BusinessPinLoginPage,
  BusinessWaitlistPage,
  CheckInPage,
  CommandCenterPage,
  CustomerDetailsPage,
  CustomerReservationPage,
  CustomersPage,
  CustomerPageApp,
  DashboardPage,
  DriverAppPage,
  EditLayoutPage,
  EditReservationPage,
  FourOhFourPage,
  HqPage,
  InventoryPage,
  KioskPage,
  LoyaltyAppPage,
  LoyaltyCheckInPage,
  MarketingPage,
  MenuPage,
  NewLandingPage,
  NexusPage,
  OrderFeedbackPage,
  OrdersPage,
  PendingReservationPage,
  PosPage,
  ProcurementPage,
  RedeemPromoPage,
  // ReportsPage,
  ReservationConfirmPage,
  ReservationCancelPage,
  RestaurantPage,
  RestaurantsMain,
  ReservationsHistoryPage,
  ReviewsPage,
  SettingsPage,
  SurveyCommentPage,
  UserAdmin,
  UserDetailPage,
  UserPage,
  VisitFeedbackPage,
  VisitsHistoryPage,
  WaitlistConfirmCustomerPage,
  WaitlistCancelCustomerPage,
  WaitlistPage,
};
