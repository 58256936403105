import React from "react";

export default function WarningIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0263 2.53132C11.5527 -0.200298 15.4833 -0.200301 17.0098 2.53132L26.2441 19.0558C27.7341 21.7221 25.8067 25.0071 22.7523 25.0071H4.28374C1.22939 25.0071 -0.698011 21.7221 0.791967 19.0558L10.0263 2.53132ZM11.6163 9.69284C11.6163 8.64301 12.4674 7.79197 13.5172 7.79197C14.567 7.79197 15.4181 8.64301 15.4181 9.69284V12.8911C15.4181 13.9409 14.567 14.792 13.5172 14.792C12.4674 14.792 11.6163 13.9409 11.6163 12.8911V9.69284ZM13.5173 21.1427C14.5671 21.1427 15.4181 20.2917 15.4181 19.2419C15.4181 18.192 14.5671 17.341 13.5173 17.341C12.4674 17.341 11.6164 18.192 11.6164 19.2419C11.6164 20.2917 12.4674 21.1427 13.5173 21.1427Z"
        fill="currentColor"
      />
    </svg>
  );
}
