const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function subscriptionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_SUBSCRIPTION_LOADING":
      return { ...state, loading: true };
    case "ADD_SUBSCRIPTION_SUCCESS":
      return { error: false, loading: false, data: action.data };
    case "ADD_SUBSCRIPTION_ERROR":
      return { error: true, loading: false, data: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
