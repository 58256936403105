const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function restaurantPaymentsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case "ADD_RESTAURANT_PAYMENTS_LOADING":
      return { data: state.data, error: false, loading: true };
    case "ADD_RESTAURANT_PAYMENT_LOADING": {
      const newData = { ...state.data };
      newData[action.uid] = { loading: true };
      return { data: newData, loading: true, error: false };
    }
    case "ADD_RESTAURANT_PAYMENT_ERROR": {
      const newData = { ...state.data };
      newData[action.uid] = { error: true };
      return { data: newData, loading: true, error: false };
    }
    case "ADD_RESTAURANT_PAYMENT": {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { data: newData, loading: false, error: false };
    }
    case "SET_RESTAURANT_PAYMENTS": {
      let newData = { ...action.data };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_STRIPE_PAYMENT_ERROR":
      return { data: {}, loading: false, error: true };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
