const INITIAL_STATE = { error: false, loading: false, data: {} };

export function restoUserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_RESTO_USER_LOADING":
      return { data: state.data, error: false, loading: true };
    case "ADD_RESTO_USER_SUCCESS":
      return { error: false, loading: false, data: action.restoUser };
    case "ADD_RESTO_USER_ERROR":
      return { error: true, loading: false, data: {} };
    case "LOG_OUT":
    case "SIGN_OUT_RESTO_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export function getRestoUser(state) {
  return state.restoUser;
}
