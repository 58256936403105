const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function visitsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_VISITS_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_VISITS_SUCCESS":
      return { error: false, loading: false, data: action.visits };
    case "ADD_VISITS_ERROR":
      return { error: true, loading: false, data: {} };
    default:
      return state;
  }
}
