const INITIAL_STATE = {
  checkDetailSelectedTab: "time",
  gridStack: [],
  selectedMenu: null,
  selectedGridItem: null,
};

export default function posUiReducer(state = INITIAL_STATE, action) {
  const setState = (param) => {
    const newState = { ...state };
    newState[param] = action.data;
    return newState;
  };
  const updateState = (param) => {
    const newState = { ...state };
    newState[param] = { ...newState[param], ...action.data };
    return newState;
  };
  switch (action.type) {
    case "POS_SET_SELECTED_MENU": {
      return setState("selectedMenu");
    }
    case "POS_PUSH_GRID_ITEM_STACK": {
      const newState = { ...state };
      const newStack = [...(newState.gridStack ?? []), action.data];
      return { ...newState, gridStack: newStack };
    }
    case "POS_POP_GRID_ITEM_STACK": {
      const newState = { ...state };
      const newStack = [...(newState.gridStack ?? [])];
      newStack.pop();
      return { ...newState, gridStack: newStack };
    }
    case "POS_CLEAR_GRID_ITEM_STACK": {
      const newState = { ...state };
      const newStack = [];
      return { ...newState, gridStack: newStack };
    }
    case "CHECK_DETAIL_SET_SELECTED_TAB": {
      return setState("checkDetailSelectedTab");
    }
    default:
      return state;
  }
}
