// import buildReducer from "./buildReducer";

//temporary moved reducer out from buildReducer
// export const walkinsHistoryReducer = buildReducer("WALKINS_HISTORY");
const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function walkinsHistoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_WALKINS_HISTORYS_LOADING":
      return { error: false, loading: true, data: state.data };
    case "SET_WALKINS_HISTORYS": {
      let newData = {};
      action.data.forEach((item) => {
        const { uid, ...data } = item;
        newData[uid] = data;
      });
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WALKINS_HISTORYS_ERROR":
      return { error: true, loading: false, data: {} };
    case "ADD_WALKIN_HISTORY_LOADING": {
      const newData = { ...state.data };
      newData[action.uid] = { ...newData[action.uid], loading: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WALKIN_HISTORY_ERROR": {
      const newData = { ...state.data };
      newData[action.uid] = { ...newData[action.uid], error: true };
      return { data: newData, error: false, loading: false };
    }
    case "ADD_WALKIN_HISTORY_SUCCESS": {
      const newData = { ...state.data };
      newData[action.payload.uid] = action.payload.data;
      return { error: false, loading: false, data: newData };
    }
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
