// import "./wdyr";
import React, { useEffect } from "react";
import { render } from "react-dom";
// import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import DocumentTitle from "react-document-title";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import Loadable from "@loadable/component";

// import Loader from "pages/LoaderPage";
import DialogProvider from "common/dialog/DialogContext";
import { SnackbarProvider } from "app/util/Snackbar/SnackbarContext";
import { SnackbarProvider as SnackbarProviderNew } from "components/ui/snackbar";
import store from "datastore/store";
import firebase from "datastore/database";
import BreakpointProvider, { useBreakpoints } from "util/BreakpointProvider";
import ModalProvider from "util/ModalContext";
import getTheme from "util/theme";
import { loadStyle } from "util";
import "static/index.scss";

import AppRoutes from "./routes/AppRoutes";
import "./.well-known/assetlinks.json";
import registerServiceWorker from "./registerServiceWorker";

const Sentry = Loadable(
  () => import(/* webpackChunkName: "Sentry" */ "common/Sentry.jsx"),
  { fallback: () => {} }
);

firebase.init();
// firebase.firestore.setLogLevel("debug");

function addBpTheming(theme, bp) {
  theme.bp = bp;
  // Overwrite the toolbar mixin, cause we can't detect orientation and do the other media query stuff
  theme.mixins.toolbar = { minHeight: bp.lessThan.md ? 56 : 64 };
  return theme;
}

function ThemedApp() {
  const bp = useBreakpoints();
  const theme = useSelector(state => state.theme);
  let currentTheme = getTheme(theme);
  currentTheme = addBpTheming(currentTheme, bp);

  return (
    <ThemeProvider theme={currentTheme}>
      <SnackbarProviderNew>
        <SnackbarProvider>
          <EmotionThemeProvider theme={currentTheme}>
            <DialogProvider>
              <ModalProvider>
                <AppRoutes />
              </ModalProvider>
            </DialogProvider>
          </EmotionThemeProvider>
        </SnackbarProvider>
      </SnackbarProviderNew>
    </ThemeProvider>
  );
}

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      // loadStyle(
      //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCcm3i4y2fCsaPRY96GTY9RWceQuW_fhQM&libraries=places,drawing,geometry,visualization"
      // );
      loadStyle(
        "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap"
      );
      // loadStyle("https://use.typekit.net/fny1iim.css");
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      <DocumentTitle title="Resto Guru - Take care of your customers, we'll take care of the rest" />
      <Provider store={store}>
        <BreakpointProvider>
          <Sentry />
          <ThemedApp />
        </BreakpointProvider>
      </Provider>
    </React.Fragment>
  );
}

render(<App />, document.getElementById("root"));
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);

registerServiceWorker();
