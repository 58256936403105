import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Skeleton from "@material-ui/lab/Skeleton";
import { fade } from "@material-ui/core/styles/colorManipulator";

const rightBorder = theme => ({
  borderRight: `1px solid ${theme.palette.divider}`,
});
const leftBorder = theme => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
});

const Root = styled.div(props => ({
  height: 50,
  backgroundColor: props.theme.palette.background[8],
  display: "flex",
  alignItems: "center",
}));
const Tab = styled.div(props => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  padding: 15,
  borderRight: `1px solid ${props.theme.palette.divider}`,
}));
const PurpleSkeleton = styled(Skeleton)(props => ({
  backgroundColor: fade(props.theme.palette.primary.main, 0.4),
  height: 20,
  width: 50,
}));

const DateSelectorRoot = styled.div(
  props => leftBorder(props.theme),
  props => rightBorder(props.theme),
  props => ({
    ...leftBorder(props.theme),
    ...rightBorder(props.theme),
    height: "100%",
    padding: 25,
  })
);
function DateSelectorLoader() {
  return (
    <DateSelectorRoot className="centered-container">
      <Skeleton animation="wave" style={{ height: 20, width: 100 }} />
    </DateSelectorRoot>
  );
}

const ButtonRoot = styled.div(props => ({
  height: "100%",
  width: 50,
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
function Button(props) {
  return (
    <ButtonRoot {...props}>
      <Skeleton animation="wave" style={{ height: 20, width: 20 }} />
    </ButtonRoot>
  );
}

export default function AppBarLoader() {
  return (
    <Root>
      <Button css={rightBorder} />
      <Tab>
        <PurpleSkeleton animation="wave" />
      </Tab>
      <Tab>
        <Skeleton animation="wave" style={{ height: 20, width: 60 }} />
      </Tab>
      <Tab>
        <Skeleton animation="wave" style={{ height: 20, width: 40 }} />
      </Tab>

      <div className="centered-container" css={{ flexGrow: 1, height: "100%" }}>
        <DateSelectorLoader />
      </div>
      <Button css={leftBorder} />
      <Button css={leftBorder} />
      <Button css={leftBorder} />
    </Root>
  );
}
