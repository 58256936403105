const INITIAL_STATE = { error: false, loading: false, data: {} };

export default function blockTableReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TABLE_BLOCKS_LOADING":
      return { error: false, loading: true, data: {} };
    case "ADD_TABLE_BLOCKS_SUCCESS":
      return { error: false, loading: false, data: action.tables };
    case "ADD_TABLE_BLOCKS_ERROR":
      return { error: true, loading: false, data: {} };
    case "LOG_OUT":
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
