const INITIAL_STATE = { error: false, loading: false, data: [] };

export default function tagsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_TAGS_LOADING":
      return { error: false, loading: true, data: [] };
    case "ADD_TAGS_SUCCESS":
      return { error: false, loading: false, data: action.data };
    case "ADD_TAGS_ERROR":
      return { error: true, loading: false, data: [] };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
