import { firebase } from "@firebase/app";
import "@firebase/auth";
import "@firebase/analytics";
import "@firebase/firestore";
import "@firebase/storage";
import "@firebase/functions";

import getUrlBase from "./getUrlBase";

console.log("REACT_APP_TARGET: ", process.env.REACT_APP_TARGET); //eslint-disable-line

const prodConfig = {
  apiKey: "AIzaSyCcm3i4y2fCsaPRY96GTY9RWceQuW_fhQM",
  authDomain: "waitlist-app-3154b.firebaseapp.com",
  databaseURL: "https://waitlist-app-3154b.firebaseio.com",
  projectId: "waitlist-app-3154b",
  storageBucket: "waitlist-app-3154b.appspot.com",
  messagingSenderId: "939732362950",
  appId: "1:939732362950:web:859c17d2f26cabcf81d9c7",
  measurementId: "G-HBD8DX5VGT",
};

const stagingConfig = {
  apiKey: "AIzaSyDFBLF_hhF4q-vjrKil9IG6r5V2Kcy5hSw",
  authDomain: "resto-guru-staging.firebaseapp.com",
  databaseURL: "https://resto-guru-staging.firebaseio.com",
  projectId: "resto-guru-staging",
  storageBucket: "resto-guru-staging.appspot.com",
  messagingSenderId: "933052058276",
  appId: "1:933052058276:web:85b0fe6a0a0e06d666f431",
  measurementId: "G-C4NCHR71BT",
};

const devConfig = {
  apiKey: "AIzaSyD7Ngf5smlK2OEd_fCigj5gpA5HIJ6wINc",
  authDomain: "waitlist-app-dev.firebaseapp.com",
  databaseURL: "https://waitlist-app-dev.firebaseio.com",
  projectId: "waitlist-app-dev",
  storageBucket: "waitlist-app-dev.appspot.com",
  messagingSenderId: "617094780858",
  appId: "1:617094780858:web:911d58ef08492b41c52f83",
  measurementId: "G-94401XS48S",
};

export function getRgUrl() {
  switch (process.env.REACT_APP_TARGET) {
    case "prod":
      return "https://resto.guru";
    case "staging":
      return "https://staging.resto.guru";
    case "local":
      return "http://localhost:8000";
    case "dev":
    default:
      return "https://waitlist-app-dev.web.app";
  }
}

export function getQuiqupUrlBase() {
  return process.env.REACT_APP_TARGET === "prod"
    ? "https://api-ae.quiqup.com"
    : "https://api.staging.quiqup.com";
}

function getConfig() {
  const target = process.env.REACT_APP_TARGET;
  switch (target) {
    case "prod":
      return prodConfig;
    case "staging":
      return stagingConfig;
    case "dev":
    default:
      return devConfig;
  }
}

firebase.init = async () => {
  const config = getConfig();
  firebase.initializeApp(config);
  firebase.analytics();
  firebase.functions();

  firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch((err) => {
      if (err.code === "failed-precondition") {
        console.log("Offline mode disabled"); //eslint-disable-line
      } else if (err.code === "unimplemented") {
        console.log("Offline mode disabled"); //eslint-disable-line
      }
    });
};

export { getUrlBase };

export default firebase;
