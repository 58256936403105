import isEqual from "lodash/isEqual";

const INITIAL_STATE = { error: false, loading: false, business: {} };

export default function businessReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ADD_BUSINESS_LOADING":
      return { ...state, error: false, loading: true };
    case "ADD_BUSINESS_SUCCESS": {
      let newState = {
        business: { ...state.business },
        error: false,
        loading: false,
      };
      // Exclude access controls, check everything else
      delete newState.business.accessControls;
      const { accessControls, ...othersNew } = action.business;
      if (!isEqual(newState.business, othersNew)) {
        newState.business = action.business;
      }
      return newState;
    }
    case "ADD_BUSINESS_ERROR":
      return { error: true, loading: false, business: {} };
    case "SIGN_OUT_USER":
      return INITIAL_STATE;
    default:
      return state;
  }
}
