const INITIAL_STATE = {
  error: false,
  loading: false,
  isRestaurantDrawerOpen: false,
};

export default function restaurantsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "TOGGLE_DRAWER": {
      return { isRestaurantDrawerOpen: !state.isRestaurantDrawerOpen };
    }
    default:
      return state;
  }
}
