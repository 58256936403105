import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background[12],
  },
}));

const SnackbarContext = React.createContext({});

const autoHideDuration = 5000;

export function SnackbarProvider({ children }) {
  const [state, setState] = useState({ isOpen: false, message: "" });
  const { isOpen, message } = state;
  const classes = useStyles();

  const updateState = (data) => {
    setState((old) => ({
      ...old,
      ...data,
    }));
  };

  const openSnackbar = (message) => {
    updateState({ isOpen: true, message });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    updateState({ isOpen: false });
  };

  return (
    <SnackbarContext.Provider
      value={{
        openSnackbar,
        closeSnackbar,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={autoHideDuration}
        open={isOpen}
        onClose={closeSnackbar}
        ContentProps={{
          className: classes.content,
        }}
        message={message}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={closeSnackbar}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      {children}
    </SnackbarContext.Provider>
  );
}

export const SnackbarConsumer = SnackbarContext.Consumer;
export const useSnackbar = () => React.useContext(SnackbarContext);
