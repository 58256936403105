import uniqBy from "lodash/uniqBy";
import Help from "@material-ui/icons/Help";
import CheckCircle from "@material-ui/icons/CheckCircle";
import UnconfirmedIcon from "@material-ui/icons/Help";
import PendingIcon from "@material-ui/icons/Help";
import DelayedIcon from "@material-ui/icons/WatchLater";
// import DeletedIcon from "@material-ui/icons/Delete";
import DeletedIcon from "@material-ui/icons/Cancel";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import RemoveIcon from "@material-ui/icons/Cancel";
import CakeIcon from "@material-ui/icons/Cake";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import PanToolIcon from "@material-ui/icons/PanTool";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import WaitlistIcon from "@material-ui/icons/Assignment";
import ReservationIcon from "@material-ui/icons/EventSeat";
import WalkinIcon from "@material-ui/icons/DirectionsWalk";
import OrderIcon from "@material-ui/icons/Fastfood";
import DiningIcon from "@material-ui/icons/LocalDining";
import FlagIcon from "@material-ui/icons/Flag";
// import WarningIcon from "@material-ui/icons/WarningRounded";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";

import CustomerCancelledIcon from "../assets/CustomerCancelledIcon";
import CustomerConfirmedIcon from "../assets/CustomerConfirmedIcon";
import WarningIcon from "../assets/WarningIcon";
import RestoGuruIcon from "assets/dashboard-icons/restoguru-logo.png";
import TripadvisorIcon from "assets/dashboard-icons/tripadvisor-dashboard.png";
import FacebookIcon from "assets/dashboard-icons/facebook.png";
import GoogleIcon from "assets/dashboard-icons/google.png";
import ZomatoIcon from "assets/dashboard-icons/Zomato.png";

import PERSISTED_STATE_KEYS from "./constants/persistedStateKeys";

export { PERSISTED_STATE_KEYS };

export const table = {
  leftPadding: 27,
};
export const AL_QUOZ_LOCATION = [25.134415, 55.245258];
export const DEFAULT_LOCATION = {
  lat: AL_QUOZ_LOCATION[0],
  lng: AL_QUOZ_LOCATION[1],
};
export const GOOGLE_MAPS_API_KEY = "AIzaSyCcm3i4y2fCsaPRY96GTY9RWceQuW_fhQM";
export const USER_PLACEHOLDER =
  "https://firebasestorage.googleapis.com/v0/b/erfara-2aa21.appspot.com/o/placeholder.png?alt=media&token=3bc07900-7743-4936-b877-fce51a0e4903";

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const CUSTOMER_STATUS = {
  PENDING: "pendingUid",
  UNCONFIRMED: "unconfirmedUid",
  DELAYED: "delayedUid",
  CONFIRMED: "confirmedUid",
  CANCELLED: "cancelledUid",
  DELETED: "deletedUid",
  PARTIALLY_SEATED: "partialllySeatedUid",
  SEATED: "seatedUid",
  COMPLETED: "completedUid",
  REMOVED: "removedUid",
  toString(status) {
    switch (status) {
      case this.CONFIRMED:
        return "Customer Confirmed";
      case this.CANCELLED:
        return "Customer Cancelled";
      case this.DELAYED:
        return "Delayed";
      case this.PENDING:
        return "Pending";
      case this.UNCONFIRMED:
        return "Unconfirmed";
      case this.DELETED:
        return "No-Show";
      case this.PARTIALLY_SEATED:
        return "Partially Seated";
      case this.SEATED:
        return "Seated";
      case this.COMPLETED:
        return "Completed";
      case this.REMOVED:
        return "Removed";
      default:
        return "";
    }
  },
  color(status) {
    switch (status) {
      case this.CONFIRMED:
        return green[200];
      case this.CANCELLED:
        return red[200];
      case this.PENDING:
        return orange[200];
      case this.DELAYED:
        return yellow[200];
      case this.SEATED:
      case this.PARTIALLY_SEATED:
        return blue[200];
      default:
      case this.UNCONFIRMED:
        return grey[700];
      case this.COMPLETED:
        return green[200];
      case this.REMOVED:
      case this.DELETED:
        return grey[700];
    }
  },
  icon(status) {
    switch (status) {
      case this.CONFIRMED:
        return CustomerConfirmedIcon;
      case this.CANCELLED:
        return CustomerCancelledIcon;
      case this.PENDING:
        return WarningIcon;
      default:
      case this.UNCONFIRMED:
        return Help;
      case this.DELAYED:
        return DelayedIcon;
      case this.COMPLETED:
        return CheckCircle;
      case this.REMOVED:
      case this.DELETED:
        return RemoveIcon;
      case this.SEATED:
      case this.PARTIALLY_SEATED:
        return DiningIcon;
    }
  },
};

export function getStatusColor(status) {
  switch (status) {
    case CUSTOMER_STATUS.CONFIRMED:
      return green[200];
    case CUSTOMER_STATUS.CANCELLED:
      return red[200];
    case CUSTOMER_STATUS.PENDING:
      return orange[200];
    case CUSTOMER_STATUS.UNCONFIRMED:
      return grey[200];
    case CUSTOMER_STATUS.DELETED:
      return red[200];
    case CUSTOMER_STATUS.PARTIALLY_SEATED:
      return blue[200];
    case CUSTOMER_STATUS.SEATED:
      return blue[200];
    case CUSTOMER_STATUS.REMOVED:
      return red[200];
    default:
      return "white";
  }
}

export const STATUS_ICONS = {
  [CUSTOMER_STATUS.PENDING]: WarningIcon,
  [CUSTOMER_STATUS.UNCONFIRMED]: UnconfirmedIcon,
  [CUSTOMER_STATUS.DELAYED]: DelayedIcon,
  [CUSTOMER_STATUS.CONFIRMED]: CustomerConfirmedIcon,
  [CUSTOMER_STATUS.PARTIALLY_SEATED]: EventSeatIcon,
  [CUSTOMER_STATUS.SEATED]: EventSeatIcon,
  [CUSTOMER_STATUS.CANCELLED]: CustomerCancelledIcon,
  [CUSTOMER_STATUS.COMPLETED]: CheckCircle,
  [CUSTOMER_STATUS.REMOVED]: RemoveIcon,
  [CUSTOMER_STATUS.DELETED]: RemoveIcon,
};

export const ORDER_STATUS = {
  CREATED: "createdUid",
  PENDING: "pendingUid",
  HOLD: "holdUid",
  CONFIRMED: "confirmedUid",
  FIRED: "firedUid",
  PREPARED: "preparedUid",
  DRIVER_REQUESTED: "driverRequestedUid",
  IN_TRANSIT: "inTransitUid",
  DELIVERED: "deliveredUid",
  REJECTED: "rejectedUid",
  ERROR: "errorUid",
  VOID: "voidUid",
  COMPED: "compedUid",
  toString(status) {
    switch (status) {
      default:
      case this.CREATED:
        return "Created";
      case this.PENDING:
        return "Pending";
      case this.HOLD:
        return "Hold";
      case this.CONFIRMED:
        return "Confirmed";
      case this.FIRED:
        return "Preparing";
      case this.PREPARED:
        return "Ready for Delivery";
      case this.DRIVER_REQUESTED:
        return "Driver Requested";
      case this.IN_TRANSIT:
        return "Delivering";
      case this.DELIVERED:
        return "Delivered";
      case this.REJECTED:
        return "Rejected";
      case this.ERROR:
        return "Error";
      case this.VOID:
        return "Void";
      case this.COMPED:
        return "Comped";
    }
  },
};

export function getOrderStatusColor(status) {
  switch (status) {
    default:
    case ORDER_STATUS.CREATED:
      return grey[200];
    case ORDER_STATUS.PENDING:
      return orange[200];
    case ORDER_STATUS.CONFIRMED:
      return green[200];
    case ORDER_STATUS.FIRED:
      return orange[200];
    case ORDER_STATUS.PREPARED:
      return green[200];
    case ORDER_STATUS.DRIVER_REQUESTED:
      return grey[200];
    case ORDER_STATUS.IN_TRANSIT:
      return grey[200];
    case ORDER_STATUS.DELIVERED:
      return green[200];
    case ORDER_STATUS.REJECTED:
      return red[200];
    case ORDER_STATUS.ERROR:
      return red[200];
  }
}

export const ORDER_TYPE = {
  DELIVERY: "deliveryUid",
  DINE_IN: "dineInUid",
  QUIQUP: "quiqupUid",
  TAKEAWAY: "takeawayUid",
  TO_GO: "toGoUid",
  INVALID: "invalidUid",
  toString(type) {
    switch (type) {
      case ORDER_TYPE.DELIVERY:
        return "Delivery";
      case ORDER_TYPE.DINE_IN:
        return "Dine-In";
      case ORDER_TYPE.QUIQUP:
        return "Quiqup";
      case ORDER_TYPE.TAKEAWAY:
        return "Take-away";
      case ORDER_TYPE.TO_GO:
        return "To Go";
      default:
        return "Invalid";
    }
  },
  parse(type) {
    switch (type) {
      case "Delivery":
        return ORDER_TYPE.DELIVERY;
      case "Dine-In":
        return ORDER_TYPE.DINE_IN;
      case "Quiqup":
        return ORDER_TYPE.QUIQUP;
      case "Take-away":
        return ORDER_TYPE.TAKEAWAY;
      case "To Go":
        return ORDER_TYPE.TO_GO;
      default:
        return ORDER_TYPE.INVALID;
    }
  },
};

export const POS_ITEM_TYPE = {
  GROUP: "group",
  ITEM: "item",
};

export const SERVICE_SOURCE = {
  PER_PAYMENT: "prepayment",
  PAYMENT_LINK: "payment-link",
  MANUAL: "manual",
  ONLINE: "web-delivery-stripe-payment",

  toString(type) {
    switch (type) {
      case this.PER_PAYMENT:
        return "Pre payment";
      case this.PAYMENT_LINK:
        return "Payment Link";
      case this.MANUAL:
        return "Manual Add";
      case this.ONLINE:
        return "Online";
      default:
        return "";
    }
  },
};

export const SERVICE_TYPE = {
  ORDER: "order",
  BOOKING: "booking",

  toString(type) {
    switch (type) {
      case this.ORDER:
        return "Order";
      case this.BOOKING:
        return "Booking";
      default:
        return "";
    }
  },
};
export const REFUND_TYPE = {
  FULL_REFUND: "fullRefund",
  HALF_REFUND: "halfRefund",
  QUARTER_REFUND: "quarterRefund",
  CUSTOM: "custom",

  toString(type) {
    switch (type) {
      case this.FULL_REFUND:
        return "Full Refund";
      case this.HALF_REFUND:
        return "Half Refund";
      case this.QUARTER_REFUND:
        return "Quarter Refund";
      case this.CUSTOM:
        return "Custom";
      default:
        return "";
    }
  },
};

export const CRITERIA_TYPE = {
  PER_TABLE: "per-table",
  PER_HEAD: "per-head",
  CUSTOM: "custom",
  toString(type) {
    switch (type) {
      case this.PER_TABLE:
        return "Per table";
      case this.PER_HEAD:
        return "Per head";
      case this.CUSTOM:
        return "Custom";
      default:
        return "";
    }
  },
};

export const PAYMENT_TYPE = {
  CARD_ON_DELIVERY: "cardOnDeliveryUid",
  CASH_ON_DELIVERY: "cashOnDeliveryUid",
  CREDIT_CARD: "creditCardUid",

  toString(type) {
    switch (type) {
      case this.CARD_ON_DELIVERY:
        return "Card on delivery";
      case this.CASH_ON_DELIVERY:
        return "Cash on delivery";
      case this.CREDIT_CARD:
        return "Credit/Debit Card";
      default:
        return "";
    }
  },
};

export const QUIQUP_ORDER_STATUS = {
  PENDING: "pending", //this job is currently being ‘built’ by the customer and is not yet submitted
  REQUESTED: "requested", //Customer has requested the job
  PENDING_ASSIGNMENT: "pending_assignment", //Our Generate Match Engine is finding the best Courier for the job
  RECEIVED: "received", //CSRs have received the job
  ASSIGNED: "assigned", //CSRs have assigned the job to a courier
  QUEUED: "queued", //operations are queueing and this job has been queued
  PICKUP_STARTED: "pickup_started", //Courier is on their way to the pickup
  PICKUP_ARRIVED: "pickup_arrived", //Courier has arrived at a pickup
  ITEMS_PURCHASED: "items_purchased", //Courier has purchased the items and uploaded the receipt
  DROP_STARTED: "drop_started", //Courier is on their way to a dropoff
  DROP_ARRIVED: "drop_arrived", //Courier has arrived at a dropoff location
  CANCELLED: "cancelled", //Job has been cancelled
  COMPLETE: "complete", //Job has been completed successfully
  toString(type) {
    switch (type) {
      case this.PENDING:
        return "Pending";
      case this.REQUESTED:
        return "Requested";
      case this.PENDING_ASSIGNMENT:
        return "Pending Assignment";
      case this.RECEIVED:
        return "Received";
      case this.ASSIGNED:
        return "Assigned";
      case this.QUEUED:
        return "Queued";
      case this.PICKUP_STARTED:
        return "Pickup Started";
      case this.PICKUP_ARRIVED:
        return "Pickup Arrived";
      case this.ITEMS_PURCHASED:
        return "Items Purchased";
      case this.DROP_STARTED:
        return "Drop Started";
      case this.DROP_ARRIVED:
        return "Drop Arrived";
      case this.CANCELLED:
        return "Cancelled";
      case this.COMPLETE:
        return "Complete";
      default:
        return "Unknown Status";
    }
  },
};

export const ORDER_SOURCE = {
  WEBSITE: "rgWebOrdering",
  INSTAGRAM: "instagramOrdering",
  FACEBOOK: "facebookOrdering",
  ZOMATO: "zomatoOrdering",
  CAREEM: "careemOrdering",
  TALABAT: "talabatOrdering",
  MANUAL: "rgBackendForm",
  PHONE: "phoneOrdering",
  toString(status) {
    switch (status) {
      case this.WEBSITE:
        return "Website";
      case this.INSTAGRAM:
        return "Instagram";
      case this.FACEBOOK:
        return "Facebook";
      case this.ZOMATO:
        return "Zomato";
      case this.CAREEM:
        return "Careem Now";
      case this.TALABAT:
        return "Talabat";
      case this.MANUAL:
        return "Manual Add";
      case this.PHONE:
        return "Phone Order";
      default:
        return "";
    }
  },
};

export const BOOKING_SOURCE = {
  RG_WEB: "rgWeb",
  toString(status) {
    switch (status) {
      case this.RG_WEB:
        return "Website";
      default:
        return "";
    }
  },
};

export const INITIATOR = {
  RESTAURANT: "restaurant",
  CUSTOMER: "customer",
  RESTOGURU: "resto-guru",
};

export const TIMELINE_TYPE = {
  NEW_RESERVATION: "new-reservation",
  NEW_WAITLIST: "new-waitlist",
  NEW_WALKIN: "new-walkin",
  UPDATE_WAITLIST: "update-waitlist",
  UPDATE_WALKIN: "update-walkin",
  OPEN_WAITLIST_QUEUE: "open-waitlist-queue",
  REMOVE_WAITLIST: "remove-waitlist",
  REMOVE_WALKIN: "remove-walkin",
  ROBO_CALL: "robo-call",
  CONFIRM_RESERVATION: "confirm-reservation",
  CUSTOMER_CONFIRMED_WAITLIST: "customer-confirmed-waitlist",
  CUSTOMER_CONFIRMED_RESERVATION: "customer-confirmed-reservation",
  CUSTOMER_CANCELLED_WAITLIST: "customer-cancelled-waitlist",
  CUSTOMER_CANCELLED_RESERVATION: "customer-cancelled-reservation",
  OPEN_FEEDBACK_VISIT: "open-feedback-visit",
  OPEN_FEEDBACK_ORDER: "open-feedback-order",
  FEEDBACK_VISIT: "feedback-visit",
  FEEDBACK_ORDER: "feedback-order",
  SEAT_WAITLIST: "seat-waitlist",
  SEAT_WALKIN: "seat-walkin",
  SEAT_RESERVATION: "seat-reservation",
  UPDATE_RESERVATION: "update-reservation",
  REMOVE_RESRVATION: "remove-reservation",
  ASSIGN_TABLE_WALKIN: "assign-table-walkin",
  ASSIGN_TABLE_WAITLIST: "assign-table-waitlist",
  BOOKING_PARTIALLY_SEATED_WAITLIST: "booking-partially-seated-waitlist",
  BOOKING_PARTIALLY_SEATED_RESERVATION: "booking-partially-seated-reservation",
  BOOKING_SEATED_WAITLIST: "booking-seated-waitlist",
  BOOKING_SEATED_RESERVATION: "booking-seated-reservation",
  BOOKING_SEATED_WALKIN: "booking-seated-walkin",
  UNASSIGN_TABLE_WAITLIST: "unassign-table-waitlist",
  ASSIGN_TABLE_RESERVATION: "assign-table-reservation",
  UNASSIGN_TABLE_RESERVATION: "unassign-table-reservation",
  UNASSIGN_TABLE_WALKIN: "unassign-table-walkin",
  NEW_ORDER: "new-order",
  ADD_ADDRESS: "add-address",
  CONFIRM_ORDER: "confirm-order",
  CONFIRM_ORDER_QUIQUP: "confirm-order-quiqup",
  CANCEL_ORDER: "cancel-order",
  FIRED_ORDER: "fired-order",
  PREPARED_ORDER: "prepared-order",
  IN_TRANSIT_ORDER: "in-transit-order",
  DELIVERED_ORDER: "delivered-order",
  CUSTOMER_CREATED: "customer-created",
  UPDATE_CUSTOMER: "update-customer",
  OPEN_FEEDBACK_ZOMATO: "open-feedback-zomato",
  OPEN_FEEDBACK_GOOGLE: "open-feedback-google",
  OPEN_FEEDBACK_INSTA: "open-feedback-insta",
  PAYMENT_GATEWAY_RECEIVED: "payment-gateway-received",
  SIM_CALL: "sim-call",
  SMS: "sms",
  DRIVER_CONTACTED_SMS: "driver-contacted-sms",
  DRIVER_CONTACTED_TEL: "driver-contacted-tel",
  DRIVER_CONTACTED_WHATSAPP: "driver-contacted-whatsapp",
  FEEDBACK_REQUESTED: "feedback-requested",
  CONFIRMED_WAITLIST_ROBOCALL: "confirmed-waitlist-robocall",
  CANCELLED_WAITLIST_ROBOCALL: "cancelled-waitlist-robocall",
  CONFIRMED_RESERVATION_ROBOCALL: "confirmed-reservation-robocall",
  CANCELLED_RESERVATION_ROBOCALL: "cancelled-reservation-robocall",
  WAITLIST_ROBO_CALL: "waitlist-robo-call",
  RESERVATION_ROBO_CALL: "reservation-robo-call",
  VISIT_PROCESSED: "visit-processed",
  SEAT_CUSTOMER: "seat-customer",
  UNSEAT_CUSTOMER: "unseat-customer",
  UNSEAT_WAITLIST: "unseat-waitlist",
  UNSEAT_WALKIN: "unseat-walkin",
  UNSEAT_RESERVATION: "unseat-reservation",
  UPDATE_SEATED_TABLE_RESERVATION: "update-seated-table-reservation",
  UPDATE_SEATED_TABLE_WAITLIST: "update-seated-table-waitlist",
  UPDATE_SEATED_TABLE_WALKIN: "update-seated-table-walkin",
  ADD_PAYMENT: "add-payment",
  ADD_PAYMENT_SUCCESS: "add-payment-success",
  SENT_PAYMENT_LINK: "sent-payment-link",
  REFUND: "refund",
};

export const PENDING_STATUSES = [ORDER_STATUS.CREATED, ORDER_STATUS.PENDING];
export const IN_PROCESS_STATUSES = [
  ORDER_STATUS.CONFIRMED,
  ORDER_STATUS.FIRED,
  ORDER_STATUS.PREPARED,
  ORDER_STATUS.DRIVER_REQUESTED,
  ORDER_STATUS.IN_TRANSIT,
];
export const FAILED_STATUSES = [ORDER_STATUS.REJECTED, ORDER_STATUS.ERROR];
export const COMPLETED_STATUSES = [ORDER_STATUS.DELIVERED];
export const PREPARING_STATUSES = [ORDER_STATUS.FIRED];
export const READY_STATUSES = [ORDER_STATUS.PREPARED];
export const DISPATCHED_STATUSES = [ORDER_STATUS.IN_TRANSIT];

export const ORDER_TAGS = [
  {
    uid: "food",
    text: "Food",
    positiveText: "Food",
    negativeText: "Food",
  },
  {
    uid: "drinks",
    text: "Drinks",
    positiveText: "Drinks",
    negativeText: "Drinks",
  },
  {
    uid: "dessert",
    text: "Dessert",
    positiveText: "Dessert",
    negativeText: "Dessert",
  },
  {
    uid: "value",
    text: "Value",
    positiveText: "Good value",
    negativeText: "Expensive",
  },
  {
    uid: "location",
    text: "Location",
    positiveText: "Location",
    negativeText: "Location",
  },
  {
    uid: "delivery",
    text: "Delivery",
    positiveText: "Fast delivery",
    negativeText: "Slow delivery",
  },
];

export const VISIT_TAGS = [
  {
    uid: "food",
    text: "Food",
    positiveText: "Food",
    negativeText: "Food",
  },
  {
    uid: "drinks",
    text: "Drinks",
    positiveText: "Drinks",
    negativeText: "Drinks",
  },
  {
    uid: "dessert",
    text: "Dessert",
    positiveText: "Dessert",
    negativeText: "Dessert",
  },
  {
    uid: "ambience",
    text: "Ambience",
    positiveText: "Good ambience",
    negativeText: "Bad ambience",
  },
  {
    uid: "staff",
    text: "Staff",
    positiveText: "Staff",
    negativeText: "Staff",
  },
  {
    uid: "facilities",
    text: "Facilities",
    positiveText: "Facilities",
    negativeText: "Facilities",
  },
  {
    uid: "location",
    text: "Location",
    positiveText: "Good location",
    negativeText: "Bad location",
  },
  {
    uid: "quietness",
    text: "Quietness",
    positiveText: "Quiet",
    negativeText: "Too loud",
  },
  {
    uid: "value",
    text: "Value",
    positiveText: "Good value",
    negativeText: "Expensive",
  },
  {
    uid: "cleanliness",
    text: "Cleanliness",
    positiveText: "Clean",
    negativeText: "Dirty",
  },
  {
    uid: "reservations",
    text: "Reservations",
    positiveText: "Reservations",
    negativeText: "Reservations",
  },
  {
    uid: "waitlist",
    text: "Waitlist",
    positiveText: "Waitlist",
    negativeText: "Waitlist",
  },
  {
    uid: "busyness",
    text: "Busyness",
    positiveText: "Not busy",
    negativeText: "Too busy",
  },
];
//
export const ALL_TAGS = uniqBy(
  [...ORDER_TAGS, ...VISIT_TAGS],
  (tag) => tag.uid
);

export const TAG_TYPE = {
  BOOKING: "booking",
  CUSTOMER: "customer",
  SEATING_PREFERENCE: "seating-preference",
  toString(status) {
    switch (status) {
      case this.BOOKING:
        return "Booking";
      case this.CUSTOMER:
        return "Customer";
      case this.SEATING_PREFERENCE:
        return "Seating Preference";
      default:
        return "";
    }
  },
};

export const SLOT_BLOCK_REASON = {
  CLOSED: "closedUid",
  BOOKING_DURATION_LIMIT: "bookingDurationLimitUid",
  PAX_LIMIT: "PaxLimitUid",
  toString(type) {
    switch (type) {
      case this.CLOSED:
        return "Restaurant is closed";
      case this.BOOKING_DURATION_LIMIT:
        return "This slot exceeds the default booking duration";
      case this.PAX_LIMIT:
        return "This slot reached the pax limit";
      default:
        return "";
    }
  },
};
export const VisitType = {
  ORDER: "order",
  RESERVATION: "reservation",
  WAITLIST: "waitlist",
  WALK_IN: "walk-in",
};
export function getVisitTypeIcon(visitType) {
  switch (visitType) {
    case VisitType.ORDER:
      return OrderIcon;
    case VisitType.RESERVATION:
      return ReservationIcon;
    case VisitType.WAITLIST:
      return WaitlistIcon;
    case VisitType.WALK_IN:
      return WalkinIcon;
    default:
      return () => null;
  }
}

const SORTS = ["Newest", "Oldest"];
const DATE_RANGES = [
  "Custom",
  "Yesterday",
  "Last 7 Days",
  "Last 30 Days",
  "Last Year",
];
export const DATE_RANGE = {
  LAST_24_HOURS: "last24hours",
  LAST_7_DAYS: "last7days",
  LAST_30_DAYS: "last30days",
  LAST_3_MONTHS: "last3months",
  LAST_6_MONTHS: "last6months",
  LAST_YEAR: "lastyear",
  ALL_TIME: "alltime",
  CUSTOM: "custom",
};
export const dateRangeToStr = (range) => {
  switch (range) {
    case DATE_RANGE.LAST_24_HOURS:
      return "Last 24 hours";
    case DATE_RANGE.LAST_7_DAYS:
      return "Last 7 days";
    case DATE_RANGE.LAST_30_DAYS:
      return "Last 30 days";
    case DATE_RANGE.LAST_3_MONTHS:
      return "Last 3 months";
    case DATE_RANGE.LAST_6_MONTHS:
      return "Last 6 months";
    case DATE_RANGE.LAST_YEAR:
      return "Last year";
    case DATE_RANGE.ALL_TIME:
      return "All-time";
    case DATE_RANGE.CUSTOM:
      return "Custom";
    default:
      return "Invalid";
  }
};
const SOURCES = [
  "Web",
  "Instagram",
  "Zomato",
  "Facebook",
  "Deliveroo",
  "Talabat",
];
export { DATE_RANGES, SORTS, SOURCES };

export const ALERT_TITLE = {
  ATTENTION: "Attention",
  WARNING: "Warning",
  FLAGGED_REVIEWS: "Flagged reviews",
  ONE_STAR_REVIEW: "1 Star review",
};

export const CHANNEL_ICON = {
  RESTOGURU:
    "https://firebasestorage.googleapis.com/v0/b/waitlist-app-dev.appspot.com/o/logo.png?alt=media&token=00161659-3d51-4a73-8e9e-dd7281ab2f73",
};

export const PERIOD = {
  LAST_7_DAYS: "last-7-days",
  LAST_30_DAYS: "last-30-days",
  LAST_3_MONTHS: "last-3-months",
  LAST_6_MONTHS: "last-6-months",
  LAST_YEAR: "last-year",
  ALL_TIME: "all-time",
};
export const periodToString = (period) => {
  switch (period) {
    case PERIOD.LAST_7_DAYS:
      return "Last 7 Days";
    case PERIOD.LAST_30_DAYS:
      return "Last 30 Days";
    case PERIOD.LAST_3_MONTHS:
      return "Last 3 Months";
    case PERIOD.LAST_6_MONTHS:
      return "Last 6 Months";
    case PERIOD.LAST_YEAR:
      return "Last Year";
    case PERIOD.ALL_TIME:
      return "All-Time";
    default:
      return "Invalid";
  }
};
export const previousPeriodString = (period) => {
  switch (period) {
    case PERIOD.LAST_7_DAYS:
      return "Prev 7 Days";
    case PERIOD.LAST_30_DAYS:
      return "Prev 30 Days";
    case PERIOD.LAST_3_MONTHS:
      return "Prev 3 Months";
    case PERIOD.LAST_6_MONTHS:
      return "Prev 6 Months";
    case PERIOD.LAST_YEAR:
      return "Prev Year";
    case PERIOD.ALL_TIME:
      return "All-Time";
    default:
      return "Invalid";
  }
};

export const SUBSCRIPTION = {
  RAFFLE: "raffle",
  DELIVERY_FREE: "deliveryFree",
  DELIVERY: "delivery",
  RESERVATIONS_FREE: "reservationsFree",
  RESERVATIONS: "reservations",
  ALL: "all",
};

export function subscriptionString(subscription) {
  switch (subscription) {
    case SUBSCRIPTION.RAFFLE:
      return "Raffle Guru";
    case SUBSCRIPTION.DELIVERY_FREE:
      return "Delivery Free";
    case SUBSCRIPTION.DELIVERY:
      return "Delivery";
    case SUBSCRIPTION.RESERVATIONS_FREE:
      return "Reservations Free";
    case SUBSCRIPTION.RESERVATIONS:
      return "Reservations";
    case SUBSCRIPTION.ALL:
      return "All";
    default:
      return "Invalid";
  }
}

export const INVOICE_STATUS = {
  UNPAID: "Unpaid",
  PAID: "Paid",
  VOID: "void",
  UNCOLLECTIBLE: "uncollectible",
  OPEN: "open",
  STRIPE_PAID: "paid",
  DRAFT: "draft",
};

export const CALL_STATUS = {
  STARTED: "started",
  RINGING: "ringing",
  ANSWERED: "answered",
  BUSY: "busy",
  CANCELLED: "cancelled",
  UNANSWERED: "unanswered",
  DISCONNECTED: "disconnected",
  REJECTED: "rejected",
  FAILED: "failed",
  TIMEOUT: "timeout",
  COMPLETED: "completed",
  RECORD: "record",
  INPUT: "input",
  TRANSFER: "transfer",
  toString(status) {
    switch (status) {
      case this.STARTED:
        return "Started";
      case this.RINGING:
        return "Ringing";
      case this.ANSWERED:
        return "Answered";
      case this.BUSY:
        return "Busy";
      case this.CANCELLED:
        return "Cancelled";
      case this.UNANSWERED:
        return "Unanswered";
      case this.DISCONNECTED:
        return "Disconnected";
      case this.REJECTED:
        return "Rejected";
      case this.FAILED:
        return "Failed";
      case this.TIMEOUT:
        return "Timeout";
      case this.COMPLETED:
        return "Completed";
      case this.RECORD:
        return "Record";
      case this.INPUT:
        return "Input";
      case this.TRANSFER:
        return "Transfer";
      default:
        return "";
    }
  },
  color(status) {
    switch (status) {
      case this.STARTED:
        return grey[700];
      case this.RINGING:
        return grey[700];
      case this.ANSWERED:
        return green[300];
      case this.BUSY:
        return grey[700];
      case this.CANCELLED:
        return red[300];
      case this.UNANSWERED:
        return grey[700];
      case this.DISCONNECTED:
        return red[300];
      case this.REJECTED:
        return red[300];
      case this.FAILED:
        return red[300];
      case this.TIMEOUT:
        return grey[700];
      case this.COMPLETED:
        return green[300];
      case this.RECORD:
        return grey[700];
      case this.INPUT:
        return grey[700];
      case this.TRANSFER:
        return grey[700];
      default:
        return "";
    }
  },
};
export const BILLING_FREQUENCY = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export const REVIEW_SOURCE = {
  RESTO_GURU: "resto-guru",
  ZOMATO: "zomato",
  TRIP_ADVISOR: "trip advisor",
  GOOGLE: "google",
  FACEBOOK: "facebook",
};

export function reviewSourceString(source) {
  switch (source) {
    default:
    case REVIEW_SOURCE.RESTO_GURU:
      return "Resto Guru";
    case REVIEW_SOURCE.ZOMATO:
      return "Zomato";
    case REVIEW_SOURCE.TRIP_ADVISOR:
      return "Tripadvisor";
    case REVIEW_SOURCE.GOOGLE:
      return "Google";
    case REVIEW_SOURCE.FACEBOOK:
      return "Facebook";
  }
}

export function getReviewSourceLogo(source) {
  switch (source) {
    default:
    case REVIEW_SOURCE.RESTO_GURU:
      return RestoGuruIcon;
    case REVIEW_SOURCE.ZOMATO:
      return ZomatoIcon;
    case REVIEW_SOURCE.TRIP_ADVISOR:
      return TripadvisorIcon;
    case REVIEW_SOURCE.GOOGLE:
      return GoogleIcon;
    case REVIEW_SOURCE.FACEBOOK:
      return FacebookIcon;
  }
}

export const TABLE_STATUS = {
  UPCOMING: "Upcoming",
  AVAILABLE: "Available",
  OCCUPIED: "Occupied",
  APPETIZER: "appetizerUid",
  ENTREE: "entreeUid",
  DESSERT: "dessertUid",
  CLEARED: "clearedUid",
  CHEQUE_DROPPED: "chequeDroppedUid",
  toString(status) {
    switch (status) {
      case this.APPETIZER:
        return "Appetizer";
      case this.ENTREE:
        return "Entree";
      case this.DESSERT:
        return "Dessert";
      case this.CLEARED:
        return "Cleared";
      case this.CHEQUE_DROPPED:
        return "Cheque Dropped";
      default:
        return "";
    }
  },
  color(status) {
    switch (status) {
      case this.APPETIZER:
        return orange[300];
      case this.ENTREE:
        return orange[300];
      case this.DESSERT:
        return orange[700];
      case this.CLEARED:
        return grey[700];
      case this.CHEQUE_DROPPED:
        return green[300];
      default:
        return grey[700];
    }
  },
  icon(status) {
    switch (status) {
      case this.APPETIZER:
        return RestaurantIcon;
      case this.ENTREE:
        return RestaurantIcon;
      case this.DESSERT:
        return CakeIcon;
      case this.CLEARED:
        return PanToolIcon;
      case this.CHEQUE_DROPPED:
        return CreditCardIcon;
      default:
        return Help;
    }
  },
};

export const FLOORPLAN_BOOKING_LIST = {
  RESERVATIONS: "Reservations",
  WAITLIST: "Waitlist",
  WALK_IN: "Walk-In",
  UPCOMING: "Upcoming",
  DONE: "Done",
  SEATED: "Seated",
  ALL_BOOKINGS: "All Bookings",
};

export const SMS_TYPE = {
  PENDING: "pending",
  CONFIRMED: "confirmed",
  REMINDER: "reminder",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  PAYMENT_LINK: "payment-link",
  RESERVATION_PAYMENT_REFUND: "reservation-payment-refund",
  ORDER_PAYMENT_REFUND: "order-payment-refund",
  toString(status) {
    switch (status) {
      case this.PENDING:
        return "Pending";
      case this.CONFIRMED:
        return "Confirmed";
      case this.REMINDER:
        return "Reminder";
      case this.REJECTED:
        return "Rejected";
      case this.CANCELLED:
        return "Cancelled";
      case this.PAYMENT_LINK:
        return "Payment Link";
      case this.RESERVATION_PAYMENT_REFUND:
        return "Payment Refund";
      case this.ORDER_PAYMENT_REFUND:
        return "Payment Refund";
      default:
        return "";
    }
  },
};

const nexusAppBarHeight = 50;

export const SHIFT_TIME = {
  NOW: "now",
  BREAKFAST: "breakfast",
  LUNCH: "lunch",
  DINNER: "dinner",
  CUSTOM: "custom",
};

export const BOOKING_TYPE = {
  RESERVATION: "reservation",
  WAITLIST: "waitlist",
  WALK_IN: "walk-in",
  WALK_IN_HISTORY: "walk-in-history",
  WAITLIST_HISTORY: "waitlist-history",
};

export const BOOKING_REMOVE_REASON = {
  NO_SHOW: "No-Show",
  CUSTOMER_CANCELLED: "Customer cancelled",
  FALSE_ENTRY: "Booking added by mistake",
  REJECTED: "Booking Rejected",
};

export const BOOKING_STATUSES = [
  "pendingUid",
  "unconfirmedUid",
  "delayedUid",
  "confirmedUid",
  "partialllySeatedUid",
  "cancelledUid",
  "seatedUid",
  "completedUid",
  "removedUid",
  // "deletedUid",
];
