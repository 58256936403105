import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import range from "lodash/range";
import Skeleton from "@material-ui/lab/Skeleton";
import { useTheme } from "@material-ui/core/styles";

import { TwoTable } from "assets/Glyphs";

import AppBar from "./AppBar";

const Root = styled.div({
  display: "grid",
  gridTemplateRows: "auto auto 1fr",
  padding: "10px 10px 10px 2px",
});
const TabsContainer = styled.div((props) => ({
  display: "flex",
  height: 40,
  minHeight: 40,
  backgroundColor: props.theme.palette.background[6],
  marginTop: 4,
}));
const Tab = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px 40px",
  borderRight: `1px solid ${props.theme.palette.divider}`,
}));

function Tables({ containerStyle, condensed, ...props }) {
  const theme = useTheme();

  return (
    <Root {...props}>
      <AppBar />
      <TabsContainer>
        <Tab>
          <Skeleton animation="wave" style={{ width: 50, height: 22 }} />
        </Tab>
        <Tab>
          <Skeleton animation="wave" style={{ width: 60, height: 22 }} />
        </Tab>
        <Tab>
          <Skeleton animation="wave" style={{ width: 40, height: 22 }} />
        </Tab>
      </TabsContainer>
      <div
        className="centered-container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 56px)",
          gridTemplateRows: "repeat(auto-fit, 45px)",
          gridGap: 20,
          paddingTop: 16,
        }}
      >
        {range(0, 40).map((idx) => (
          <TwoTable
            key={idx}
            fill={theme.palette.background[4]}
            height={45}
            width={56}
          />
        ))}
      </div>
    </Root>
  );
}

export default React.memo(Tables);
