import React from 'react';

import Snackbar from './Snackbar';

const SnackbarContext = React.createContext();

export class SnackbarProvider extends React.Component {

  state = {
    isOpen: false,
    message: ''
  }

  openSnackbar = (message) => {
    this.setState({
      isOpen: true,
      message,
    });
  }

  closeSnackbar = () => {
    this.setState({
      isOpen: false,
      message: '',
    });
  }
  
  render() {
    const { message, isOpen } = this.state;
    const { children } = this.props;

    return (
      <SnackbarContext.Provider
        value={{
          openSnackbar: this.openSnackbar,
          closeSnackbar: this.closeSnackbar,
          message,
          isOpen
        }}
      >
        <Snackbar />
        {children}
      </SnackbarContext.Provider>
    );
  }
}

export const SnackbarConsumer = SnackbarContext.Consumer;