import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import Loader from "pages/LoaderPage";
import LandingPageRoutes from "pages/new-landing/AppRoutes";
import {
  NewLandingPage,
  LearnMorePage,
  SubscriptionsPage,
} from "pages/new-landing/routes";

import {
  AppPage,
  AdminPages,
  BusinessPage,
  BusinessAdminLoginPage,
  CheckInPage,
  CustomerPageApp,
  DriverAppPage,
  EditReservationPage,
  FourOhFourPage,
  HqPage,
  KioskPage,
  LoyaltyCheckInPage,
  OrderFeedbackPage,
  RedeemPromoPage,
  ReservationConfirmPage,
  ReservationCancelPage,
  RestaurantPage,
  RestaurantsMain,
  SurveyCommentPage,
  VisitFeedbackPage,
  WaitlistConfirmCustomerPage,
  WaitlistCancelCustomerPage,
  WaitlistPage,
} from "./routes";
import Route from "./Route";
const createHistory = require("history").createBrowserHistory;

const history = createHistory();

const landingPaths = [
  "/",
  "/sign-up",
  "/web-delivery",
  "/queue-management",
  "/reservations-overview",
  "/privacy",
  "/terms-of-service",
  "/contact-us",
  "/about-us",
];

export default function AppRoutes() {
  const authedUserLoading = useSelector((state) => state.authedUser.loading);

  if (authedUserLoading) return <Loader />;

  return (
    <Router history={history}>
      <React.Fragment>
        <CssBaseline />

        <Route exact path="/learn-more">
          <NewLandingPage />
          <LearnMorePage />
        </Route>

        <Switch>
          <Route exact path="/admin/login" component={BusinessAdminLoginPage} />
          <Route private path="/admin" component={AdminPages} />
          <Route
            exact
            path="/waitlist/:id/confirm"
            component={WaitlistConfirmCustomerPage}
          />
          <Route
            exact
            path="/waitlist/:id/cancel"
            component={WaitlistCancelCustomerPage}
          />
          <Route exact path="/waitlist/:id" component={WaitlistPage} />
          <Route
            path="/:businessUid/driver-app/:id"
            component={DriverAppPage}
          />
          <Route private path="/hq" component={HqPage} />
          <Route path="/restaurants" component={RestaurantsMain} />
          <Route path="/my" component={CustomerPageApp} />
          <Route exact path="/app" component={AppPage} />
          <Route
            path="/business/:id/reservation/:reservationUid"
            component={EditReservationPage}
          />
          <Route private path="/business/:id">
            <BusinessPage />
          </Route>
          <Route exact private path="/kiosk/:id">
            <KioskPage />
          </Route>
          <Route path="/checkIn/:id" component={CheckInPage} />
          <Redirect from="/booking/:id" to="/:id/book" />
          <Route path="/survey/:id" component={SurveyCommentPage} />
          <Route exact path="/:id/feedback" component={VisitFeedbackPage} />
          <Route
            exact
            path="/:id/orders/:orderId/feedback"
            component={OrderFeedbackPage}
          />
          <Route exact path="/:id/loyalty" component={LoyaltyCheckInPage} />
          <Route
            exact
            path="/:businessUid/p/:promoUid"
            component={RedeemPromoPage}
          />
          <Redirect
            exact
            from="/reservations/:id/:reservationUid"
            to="/:id/book/:reservationUid"
          />
          <Route
            exact
            path="/reservations/:businessUid/:reservationUid/confirm"
            component={ReservationConfirmPage}
          />
          <Route
            exact
            path="/reservations/:businessUid/:reservationUid/cancel"
            component={ReservationCancelPage}
          />
          <Route exact path="/app/profile" component={AppPage} />
          <Route exact path="/app/business/:id" component={AppPage} />
          <Route
            exact
            path="/app/business/:id/joinWaitlist"
            component={AppPage}
          />

          <Route exact path={landingPaths} component={NewLandingPage} />
          <Route path="/subscriptions" component={SubscriptionsPage} />

          <Route path="/:id">
            <RestaurantPage />
          </Route>
          <Route path="*" component={FourOhFourPage} />
        </Switch>
      </React.Fragment>
    </Router>
  );
}
