import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { FLOORPLAN_BOOKING_LIST, SHIFT_TIME } from "util/constants";

const INITIAL_STATE = {
  dialog: { id: null, data: {} },
  dragData: null,
  hideTableBookings: false,
  listType: "layout",
  ordersSelectedTab: "pending",
  scrollToTime: null,
  selectedBusiness: null,
  selectedDate: new Date(),
  selectedTime: {
    id: SHIFT_TIME.NOW,
    start: null,
    end: null,
  },
  showCapacityTimeline: false,
  showWaitlist: true,
  showFloorplan: true,
  floorplanSize: 4,
  floorplanBookingLists: [FLOORPLAN_BOOKING_LIST.ALL_BOOKINGS],
};

export function uiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_DRAG_DATA":
      return { ...state, dragData: action.data };
    case "SET_SHOW_CAPACITY_TIMELINE":
      return { ...state, showCapacityTimeline: action.showCapacityTimeline };
    case "SET_SHOW_FLOORPLAN":
      return { ...state, showFloorplan: action.showFloorplan };
    case "SET_SHOW_WAITLIST":
      return { ...state, showWaitlist: action.showWaitlist };
    case "SET_HIDE_TABLE_BOOKINGS":
      return { ...state, hideTableBookings: action.value };
    case "SET_FLOORPLAN_SIZE":
      return { ...state, floorplanSize: action.zoom };
    case "SET_SCROLL_TO_TIME":
      return { ...state, scrollToTime: action.data };
    case "SET_SELECTED_BUSINESS":
      return { ...state, selectedBusiness: action.data };
    case "SET_SELECTED_DATE":
      return { ...state, selectedDate: action.date };
    case "SET_SELECTED_TIME":
      return {
        ...state,
        selectedTime: { ...state.selectedTime, ...action.data },
      };
    case "SET_ORDERS_TAB":
      return { ...state, ordersSelectedTab: action.tab };
    case "SET_FLOORPLAN_LIST_PERIOD":
      return { ...state, floorplanListPeriod: action.value };
    case "SET_FLOORPLAN_LIST_SEARCH":
      return { ...state, floorplanListSearch: action.value };
    case "SET_BOOKING_LIST":
      return { ...state, floorplanBookingLists: action.lists };
    case "SET_LIST_TYPE":
      return { ...state, listType: action.data };
    case "OPEN_DIALOG":
      return {
        ...state,
        dialog: { id: action.payload.id, data: action.payload.data },
      };
    case "CLOSE_DIALOG":
      return { ...state, dialog: INITIAL_STATE.dialog };

    default:
      return state;
  }
}

// For some reason, selectedDate has been coming as undefined
export function getSelectedDate(state) {
  const date = state.ui.selectedDate;
  if (!date) return new Date();
  if (typeof date === "string") return new Date(date);
  return date;
}

export function useSelectedDate() {
  return useSelector(getSelectedDate, isEqual);
}

export function selectSelectedTime(state) {
  const data = state.ui.selectedTime;
  if (!data) {
    return {
      id: SHIFT_TIME.NOW,
      start: null,
      end: null,
    };
  }
  return data;
}

export function selectIsToday(state) {
  const date = getSelectedDate(state);
  const today =
    DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) ===
    DateTime.local().toLocaleString(DateTime.DATE_MED);
  return today;
}

export function selectSelectedBusiness(state) {
  return state.ui.selectedBusiness;
}
