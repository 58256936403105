import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import "static/index.scss";
import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";

const getTheme = (themeType) => {
  const ourTheme = themeType === "light" ? lightTheme : darkTheme;
  themeType === "light"
    ? require("static/buttons-light.scss")
    : require("static/buttons-dark.scss");

  return createMuiTheme({
    dark: darkTheme,
    light: lightTheme,
    palette: {
      type: themeType,
      ...ourTheme.palette,
      // error: will use the default color
    },
    constants: ourTheme?.constants ? ourTheme.constants : {},
    typography: {
      useNextVariants: true,
      fontFamily:
        ourTheme?.typography?.fontFamily ?? ["Roboto", "sans-serif"].join(","),
      h1: {
        color: ourTheme.palette.text.primary,
        fontSize: "2.2rem",
        lineHeight: "2rem",
        fontWeight: 600,
      },
      h2: {
        color: ourTheme.palette.text.primary,
        fontWeight: 600,
        fontSize: "1.4rem",
        marginBottom: 20,
      },
      h3: {
        color: ourTheme.palette.text.primary,
        fontWeight: 500,
        fontSize: "1.2rem",
        marginBottom: 15,
      },
      h4: {
        color: ourTheme.palette.text.primary,
        fontWeight: 500,
        fontSize: "1.1rem",
      },
      h6: {
        color: ourTheme.palette.text.primary,
        fontWeight: 500,
        fontSize: "1rem",
      },
      body1: {
        color: ourTheme.palette.text.primary,
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "0.875rem",
      },
      body2: {
        color: ourTheme.palette.text.secondary,
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "0.875rem",
      },
      button: {
        fontSize: "0.875rem",
        lineHeight: "0.875rem",
      },
      ...ourTheme?.typography,
    },
    overrides: {
      ...ourTheme.overrides,
      //this override is related to the ios15 upgrade bug
      MuiInputBase: {
        inputTypeSearch: {
          WebkitAppearance: "none",
        },
      },
      MuiTabs: {
        scroller: {
          overflowX: "scroll !important",
          // marginBottom: "-15px !important",
          // paddingBottom: "15px !important",
          scrollbarWidth: "none" /* Firefox */,
          "-ms-overflow-style": "none" /* IE 10+ */,
        },
      },
      MuiTab: {
        root: {
          fontWeight: 400,
        },
      },
      MuiSelect: {
        root: {
          "& $notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
      MuiButton: {
        root: {
          fontWeight: 500,
          whiteSpace: "nowrap",
          padding: "8px 16px",
        },
        outlined: {
          padding: "9px 15px",
        },
        outlinedPrimary: {
          borderColor: "rgba(255,255,255,0.23)",
          backgroundColor: fade(ourTheme.palette.primary.main, 0.07),
        },
        outlinedSecondary: {
          borderColor: "rgba(255,255,255,0.23)",
          backgroundColor: fade(ourTheme.palette.secondary.main, 0.07),
        },
        containedPrimary: {
          color: "black",
          boxShadow: "none",
        },
        containedSecondary: {
          color: "black",
          boxShadow: "none",
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid ${ourTheme.palette.divider}`,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: ourTheme.palette.primary.main,
        },
        current: {
          color: ourTheme.palette.text.primary,
          border: `1px solid ${ourTheme.palette.secondary.main}`,
        },
      },
      MuiPickersToolbarText: { toolbarTxt: { margin: 0 } },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          backgroundColor: ourTheme.palette.primary.main,
        },
      },
      MuiCssBaseline: {
        "@global": {
          ...ourTheme.baseline,
          "*::-webkit-scrollbar": {
            width: 3,
            height: 3,
          },
          "*::-webkit-scrollbar-track": {
            background: "transparent",
          },

          "*::-webkit-scrollbar-thumb": {
            backgroundColor: fade(ourTheme.palette?.text.disabled, 0.5),
            borderRadius: 10,
            border: "1px solid transparent",
          },
          "*": {
            scrollbarWidth: "thin",
            scrollbarColor: `${fade(
              ourTheme.palette?.text.disabled,
              0.5
            )} transparent`,
          },
        },
      },
    },
  });
};

export default getTheme;
