import buildReducer, { chainReducers } from "./buildReducer";

// const INITIAL_STATE = { error: false, loading: false, data: {} };

export const storageAreaReducer = buildReducer("STORAGE_AREA");

// function secondReducer(state, action) {
//   switch (action.type) {
//     case "SIGN_OUT_USER":
//       return INITIAL_STATE;
//     default:
//       return state;
//   }
// }

// export const checksReducer = chainReducers([baseReducer, secondReducer]);
