import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

const StyledMuiDialog = styled(MuiDialog)({
  minWidth: 300,
  zIndex: "20000!important",
  ".MuiDialog-paper": { minWidth: 300 },
});
const Title = styled(Typography)((props) => ({
  ...props.theme.typography.h3,
  marginBottom: 0,
}));
const StyledText = styled(DialogContentText)((props) => ({
  ...props.theme.typography.body1,
  lineHeight: "1.15rem",
}));

const Loader = () => (
  <div style={{ padding: 50 }}>
    <CircularProgress size={50} />
  </div>
);

function Dialog({
  open,
  loading = false,
  handleCancel = () => {},
  handleConfirm = () => {},
  onClose,
  title,
  text,
}) {
  const handleAgree = () => {
    handleConfirm();
    onClose();
  };

  const handleDisagree = () => {
    handleCancel();
    onClose();
  };

  const renderContent = () => {
    if (loading) return <Loader />;

    return (
      <React.Fragment>
        <DialogTitle>
          <Title>{title}</Title>
        </DialogTitle>
        <DialogContent>
          <StyledText>{text}</StyledText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDisagree}>
            Disagree
          </Button>
          <Button color="primary" onClick={handleAgree}>
            Agree
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  return (
    <StyledMuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {renderContent()}
    </StyledMuiDialog>
  );
}

const INITIAL_STATE = {
  title: "",
  content: "",
  open: false,
  onConfirm: null,
  onCancel: null,
};
const ModalContext = React.createContext(INITIAL_STATE);

export default function ModalProvider({ children }) {
  const [state, setState] = React.useState(INITIAL_STATE);
  const { title, content, open, onConfirm, onCancel, onClose } = state;

  const openModal = ({ title, content, onConfirm, onCancel, onClose }) => {
    setState({
      content,
      onConfirm,
      onCancel,
      onClose,
      open: true,
      title,
    });
  };

  const closeModal = () => {
    setState({ open: false });
    setState(INITIAL_STATE);
    onClose?.();
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {open && (
        <Dialog
          title={title}
          text={content}
          open={open}
          handleConfirm={onConfirm}
          handleCancel={onCancel}
          onClose={closeModal}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => React.useContext(ModalContext);
