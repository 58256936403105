import React from "react";

export default function CustomerConfirmedIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.45726 6.66781C4.45726 4.32283 6.35659 2.4235 8.70157 2.4235C11.0466 2.4235 12.9459 4.32283 12.9459 6.66781C12.9459 6.89665 12.9278 7.12124 12.893 7.34023C11.1262 8.05884 9.63674 9.31761 8.62957 10.9115C6.31783 10.8731 4.45726 8.98875 4.45726 6.66781Z"
        fill="currentColor"
      />
      <path
        d="M8.04357 11.9956C5.07758 12.1869 0.212952 13.5998 0.212952 16.2175V19.4007H7.99176C7.50242 18.2852 7.23086 17.0524 7.23086 15.7561C7.23086 14.4149 7.52163 13.1415 8.04357 11.9956Z"
        fill="currentColor"
      />
      <path
        d="M16.3122 7.85578C11.9514 7.85578 8.41223 11.395 8.41223 15.7558C8.41223 20.1166 11.9514 23.6558 16.3122 23.6558C20.673 23.6558 24.2122 20.1166 24.2122 15.7558C24.2122 11.395 20.673 7.85578 16.3122 7.85578ZM14.7322 19.7058L10.7822 15.7558L11.8961 14.6419L14.7322 17.4701L20.7283 11.474L21.8422 12.5958L14.7322 19.7058Z"
        fill="currentColor"
      />
    </svg>
  );
}
