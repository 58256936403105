import { combineReducers } from "redux";
import { responsiveStateReducer } from "redux-responsive";

import { appUserReducer } from "reducers/appUserReducer";
import { audiencesReducer } from "reducers/audiencesReducer";
import { authedUserReducer } from "reducers/authedUserReducer";
import bookingsPage from "reducers/bookingsPage";
import businessReducer from "reducers/business";
import businessesReducer from "reducers/businesses";
import { campaignsReducer } from "reducers/campaignsReducer";
import checksReducer from "reducers/pos/checks";
import coursesReducer from "reducers/pos/courses";
import { customerListSearchReducer } from "reducers/customerListSearchReducer";
import customersReducer from "reducers/customers";
import discountsReducer from "reducers/pos/discounts";
import feedbackReducer from "reducers/feedback";
import floorplansReducer from "reducers/floorplans";
import { invoicesReducer } from "reducers/invoicesReducer";
import { marketingReducer } from "reducers/marketingReducer";
import menuItemsReducer from "reducers/pos/menuItems";
import menusReducer from "reducers/pos/menus";
import { menuReducer } from "reducers/menuReducer";
import modifiersReducer from "reducers/pos/modifiers";
import nexusBookings from "reducers/nexusBookings";
import { newItemReducer } from "reducers/pos/newItemReducer";
import ordersReducer from "reducers/pos/orders";
import posUiReducer from "reducers/pos/ui";
import productsReducer from "reducers/products";
import { rafflesReducer } from "reducers/rafflesReducer";
import reportsReducer from "reducers/reports";
import reviewHqReducer from "reducers/reviewHq";
import reservationsReducer from "reducers/reservations";
import { restoUserReducer } from "reducers/restoUserReducer";
import { routerReducer } from "reducers/routerReducer";
import restaurantPaymentsReducer from "reducers/restaurantPayments";
import restaurantSettlementsReducer from "reducers/restaurantSettlements";
import sectionsReducer from "reducers/sections";
import smsReducer from "./sms";
import surchargesReducer from "reducers/pos/surcharges";
import tabsReducer from "reducers/pos/tabs";
import tagsReducer from "reducers/tags";
import taxesReducer from "reducers/pos/taxes";
import { themeReducer } from "reducers/themeReducer";
import transactionReducer from "reducers/transaction";
import { uiReducer } from "reducers/uiReducer";
import visitsReducer from "reducers/visits";
import waitlistReducer from "reducers/waitlist";
import searchReducer from "reducers/search";
import staffReducer from "reducers/pos/staff";
import subscriptionReducer from "reducers/subscription";
import restaurantsReducers from "reducers/restaurantsReducers";
import { billingsReducer } from "reducers/billingsReducer";
import permissionsReducer from "reducers/permissions";
import { userPermissionsReducer } from "reducers/userPermissionsReducer";
import usersReducer from "reducers/users";
import { businessPermissionReducer } from "reducers/businessPermissionReducer";
import { restaurantsStatsReducer } from "reducers/restaurantsStatsReducer";
import walkinReducer from "reducers/walkins";
import { storageAreaReducer } from "reducers/storageAreaReducer";
import { stockTakeReducer } from "reducers/stockTakeReducer";
import { inventoryItemReducer } from "reducers/inventoryItemReducer";
import callReducer from "./call";
import { notificationTemplatesReducer } from "reducers/notificationTemplatesReducer";
import blockTableReducer from "reducers/blockTables";
import { incomingCallReducer } from "reducers/incomingCallReducer";
import { waitlistHistoryReducer } from "./waitlistHistoryReducer";
import walkinsHistoryReducer from "./walkinsHistory";

const appReducer = combineReducers({
  appUser: appUserReducer,
  authedUser: authedUserReducer,
  audiences: audiencesReducer,
  billings: billingsReducer,
  blockTable: blockTableReducer,
  bookingsPage,
  browser: responsiveStateReducer,
  business: businessReducer,
  businesses: businessesReducer,
  businessPermission: businessPermissionReducer,
  // calls: callsReducer,
  call: callReducer,
  campaigns: campaignsReducer,
  checks: checksReducer,
  courses: coursesReducer,
  customers: customersReducer,
  customerListSearch: customerListSearchReducer,
  discounts: discountsReducer,
  feedback: feedbackReducer,
  floorplans: floorplansReducer,
  incomingCall: incomingCallReducer,
  invoices: invoicesReducer,
  inventoryItems: inventoryItemReducer,
  marketing: marketingReducer,
  menu: menuReducer,
  menuItems: menuItemsReducer,
  menus: menusReducer,
  modifiers: modifiersReducer,
  nexusBookings,
  newItem: newItemReducer,
  notificationTemplates: notificationTemplatesReducer,
  orders: ordersReducer,
  permissions: permissionsReducer,
  posUi: posUiReducer,
  products: productsReducer,
  raffles: rafflesReducer,
  reports: reportsReducer,
  reviewHq: reviewHqReducer,
  reservations: reservationsReducer,
  restaurantsStats: restaurantsStatsReducer,
  router: routerReducer,
  restaurants: restaurantsReducers,
  restaurantPayments: restaurantPaymentsReducer,
  restaurantSettlements: restaurantSettlementsReducer,
  restoUser: restoUserReducer,
  search: searchReducer,
  sections: sectionsReducer,
  staff: staffReducer,
  storageAreas: storageAreaReducer,
  stockTake: stockTakeReducer,
  sms: smsReducer,
  subscription: subscriptionReducer,
  surcharges: surchargesReducer,
  tabs: tabsReducer,
  tags: tagsReducer,
  taxes: taxesReducer,
  theme: themeReducer,
  transaction: transactionReducer,
  ui: uiReducer,
  userPermissions: userPermissionsReducer,
  users: usersReducer,
  visits: visitsReducer,
  waitlist: waitlistReducer,
  walkins: walkinReducer,
  walkinsHistory: walkinsHistoryReducer,
  waitlistHistory: waitlistHistoryReducer,
});

// Intercept the CLEAR_ALL action and reset the state
const rootReducer = (state, action) => {
  if (action.type === "CLEAR_ALL") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
